import { Injectable } from "@angular/core";
import { SessionService } from "./session.service";
import { MatDrawerMode } from "@angular/material/sidenav";

@Injectable({
    providedIn: 'root'
})
export class UserPreferencesService {

    constructor(private sessionService: SessionService) {
    }

    getUserRecordManagerColumnList(columnDefSetting: string): string[] | null {
        if (columnDefSetting) {
            const configurationItem = 'RecordManager' + columnDefSetting;
            return this.sessionService.getUserConfigurationItem(configurationItem, null);
        } else {
            return null;
        }
    }

    saveUserRecordManagerColumnList(columnDefSetting: string, columnList: string[] | null): Promise<any> {
        if (columnDefSetting) {
            const configurationItem = 'RecordManager' + columnDefSetting;
            this.sessionService.setUserConfigurationItem(configurationItem, columnList);
            return this.sessionService.saveUserPreference();
        } else {
            return Promise.resolve();
        }
    }

    getToolbarPreferences(): Promise<any> {
        return this.sessionService.getUserConfigurationItemAsync(TOOLBAR_PREFERENCES, null);
    }

    saveToolbarPreferences(toolbarPreferences: any): Promise<any> {
        this.sessionService.setUserConfigurationItem(TOOLBAR_PREFERENCES, toolbarPreferences);
        return this.sessionService.saveUserPreference();
    }
}

const PORTAL_BASE_KEY = 'PORTAL4_';
const TOOLBAR_PREFERENCES = PORTAL_BASE_KEY + 'TOOLBAR_PREFERENCES';
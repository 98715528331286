import * as i0 from '@angular/core';
import { EventEmitter, Directive, Input, Output, HostListener, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
class Ng2FittextDirective {
  constructor(el) {
    this.el = el;
    this.activateOnResize = true;
    this.container = null;
    this.activateOnInputEvents = false;
    this.minFontSize = 7;
    this.maxFontSize = 1000;
    /* Deprecated */
    this.useMaxFontSize = true;
    this.fontSizeChanged = new EventEmitter();
    this.fontSize = 1000;
    this.speed = 1.05;
    this.done = false;
  }
  setFontSize(fontSize) {
    if (this.isVisible() && !this.isDone()) {
      if (fontSize < this.minFontSize) {
        fontSize = this.minFontSize;
      }
      if (fontSize > this.maxFontSize) {
        fontSize = this.maxFontSize;
      }
      this.fontSize = fontSize;
      this.fontSizeChanged.emit(fontSize);
      this.el.nativeElement.style.setProperty('font-size', fontSize.toString() + 'px');
    }
  }
  getFontSize() {
    return this.fontSize;
  }
  calculateFontSize(fontSize, speed) {
    return Math.floor(fontSize / speed);
  }
  checkOverflow(parent, children) {
    return this.hasXAxisOverflow(parent, children) || this.hasYAxisOverflow(parent, children);
  }
  hasXAxisOverflow(parent, children) {
    return children.scrollWidth - parent.clientWidth > 0;
  }
  hasYAxisOverflow(parent, children) {
    return children.clientHeight - parent.clientHeight > 0;
  }
  onResize(event) {
    this.done = false;
    if (this.activateOnResize && this.fittext) {
      if (this.activateOnInputEvents && this.fittext) {
        this.setFontSize(this.getStartFontSizeFromHeight());
      } else {
        this.setFontSize(this.getStartFontSizeFromWeight());
      }
      this.ngAfterViewInit();
    }
  }
  onInputEvents(event) {
    this.done = false;
    if (this.activateOnInputEvents && this.fittext) {
      this.setFontSize(this.getStartFontSizeFromHeight());
      this.ngAfterViewInit();
    }
  }
  ngOnInit() {
    this.done = false;
    this.el.nativeElement.style.setProperty('will-change', 'content');
    this.ngAfterViewInit();
  }
  ngAfterViewInit() {
    if (this.isVisible() && !this.isDone()) {
      if (this.fittext) {
        if (this.hasOverflow()) {
          if (this.fontSize > this.minFontSize) {
            // iterate only until font size is bigger than minimal value
            this.setFontSize(this.calculateFontSize(this.fontSize, this.speed));
            this.ngAfterViewInit();
          }
        } else {
          this.done = true;
        }
      }
    }
  }
  ngOnChanges(changes) {
    if (changes.modelToWatch) {
      // change of model to watch - call ngAfterViewInit where is implemented logic to change size
      setTimeout(() => {
        this.done = false;
        this.setFontSize(this.maxFontSize);
        this.ngAfterViewInit();
      });
    }
  }
  ngAfterViewChecked() {
    if (this.fontSize > this.minFontSize) {
      this.setFontSize(this.getStartFontSizeFromHeight());
      this.ngAfterViewInit();
    }
  }
  getStartFontSizeFromHeight() {
    return this.container ? this.container.clientHeight : this.el.nativeElement.parentElement.clientHeight;
  }
  getStartFontSizeFromWeight() {
    return this.container ? this.container.clientWidth : this.el.nativeElement.parentElement.clientWidth;
  }
  isDone() {
    return this.done;
  }
  isVisible() {
    return this.getStartFontSizeFromHeight() > 0;
  }
  hasOverflow() {
    return this.container ? this.checkOverflow(this.container, this.el.nativeElement) : this.checkOverflow(this.el.nativeElement.parentElement, this.el.nativeElement);
  }
  static {
    this.ɵfac = function Ng2FittextDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || Ng2FittextDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: Ng2FittextDirective,
      selectors: [["", "fittext", ""]],
      hostBindings: function Ng2FittextDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("resize", function Ng2FittextDirective_resize_HostBindingHandler($event) {
            return ctx.onResize($event);
          }, false, i0.ɵɵresolveWindow)("input", function Ng2FittextDirective_input_HostBindingHandler($event) {
            return ctx.onInputEvents($event);
          });
        }
      },
      inputs: {
        fittext: "fittext",
        activateOnResize: "activateOnResize",
        container: "container",
        activateOnInputEvents: "activateOnInputEvents",
        minFontSize: "minFontSize",
        maxFontSize: "maxFontSize",
        useMaxFontSize: "useMaxFontSize",
        modelToWatch: "modelToWatch"
      },
      outputs: {
        fontSizeChanged: "fontSizeChanged"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Ng2FittextDirective, [{
    type: Directive,
    args: [{
      selector: '[fittext]'
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    fittext: [{
      type: Input,
      args: ['fittext']
    }],
    activateOnResize: [{
      type: Input,
      args: ['activateOnResize']
    }],
    container: [{
      type: Input,
      args: ['container']
    }],
    activateOnInputEvents: [{
      type: Input,
      args: ['activateOnInputEvents']
    }],
    minFontSize: [{
      type: Input,
      args: ['minFontSize']
    }],
    maxFontSize: [{
      type: Input,
      args: ['maxFontSize']
    }],
    useMaxFontSize: [{
      type: Input,
      args: ['useMaxFontSize']
    }],
    modelToWatch: [{
      type: Input,
      args: ['modelToWatch']
    }],
    fontSizeChanged: [{
      type: Output
    }],
    onResize: [{
      type: HostListener,
      args: ['window:resize', ['$event']]
    }],
    onInputEvents: [{
      type: HostListener,
      args: ['input', ['$event']]
    }]
  });
})();
class Ng2FittextModule {
  static forRoot() {
    return {
      ngModule: Ng2FittextModule,
      providers: []
    };
  }
  static {
    this.ɵfac = function Ng2FittextModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || Ng2FittextModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: Ng2FittextModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Ng2FittextModule, [{
    type: NgModule,
    args: [{
      declarations: [Ng2FittextDirective],
      exports: [Ng2FittextDirective],
      imports: [CommonModule]
    }]
  }], null, null);
})();

/*
 * Public API Surface of lib
 */

/**
 * Generated bundle index. Do not edit.
 */

export { Ng2FittextDirective, Ng2FittextModule };

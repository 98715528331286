import { Component } from '@angular/core';

@Component({
    selector: 'zs-environment-configuration',
    templateUrl: 'environment-configuration.component.html',
    styleUrls: ['environment-configuration.component.scss']
})
export class EnvironmentConfigurationComponent {

}

{
    "name": "zappsmith-portal4",
    "version": "18.0.0",
    "scripts": {
        "ng": "ng",
        "start": "ng serve",
        "startLocal": "ng serve --configuration=local --port=8181 --host=127.0.0.1",
        "watch": "ng build --watch --configuration=local --port=8181 --host=127.0.0.1",
        "build": "ng build",
        "test": "ng test --configuration=test --watch=false"
    },
    "private": true,
    "dependencies": {
        "@angular/animations": "^18.2.3",
        "@angular/cdk": "^18.2.3",
        "@angular/common": "^18.2.3",
        "@angular/compiler": "^18.2.3",
        "@angular/core": "^18.2.3",
        "@angular/forms": "^18.2.3",
        "@angular/material": "^18.2.3",
        "@angular/platform-browser": "^18.2.3",
        "@angular/platform-browser-dynamic": "^18.2.3",
        "@angular/router": "^18.2.3",
        "@anvilor/ngx-theming": "^0.18.0",
        "@auth0/auth0-angular": "^2.2.0",
        "@dicorp/html-ffe": "^18.0.7",
        "@dicorp/zappsmith-ngx-auth": "^18.0.2",
        "@dicorp/zappsmith-ngx-core": "^18.0.2",
        "@ngrx/component-store": "^18.0.2",
        "@ngrx/operators": "^18.0.0",
        "ang-jsoneditor": "^3.1.1",
        "jsoneditor": "^9.10.5",
        "moment": "^2.29.4",
        "ngx-cookie-service": "^18.0.0",
        "rxjs": "~7.5.0",
        "zone.js": "~0.14.10"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "^18.2.3",
        "@angular/cli": "~18.2.3",
        "@angular/compiler-cli": "^18.2.3",
        "@types/jasmine": "~4.0.0",
        "jasmine-core": "~4.1.0",
        "karma": "~6.3.0",
        "karma-chrome-launcher": "~3.1.0",
        "karma-coverage": "~2.2.0",
        "karma-jasmine": "~5.0.0",
        "karma-jasmine-html-reporter": "~1.7.0",
        "karma-junit-reporter": "^2.0.1",
        "tslib": "^2.4.0",
        "typescript": "~5.5.4"
    }
}

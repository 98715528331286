import { Injectable } from "@angular/core";

import { ZappsmithWebService, ZappsmithBaseParmDict } from "@dicorp/zappsmith-ngx-core";

@Injectable({
    providedIn: 'root'
})
export class SystemUpdateService {

    constructor(private zappsmithWebService: ZappsmithWebService) {
    }

    release(base: string, doc_id: string): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'doc_id': doc_id };
        return this.zappsmithWebService.delete('/autosave' + base, params);
    };

    load_tailoring(customer: string, revision: string): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'customer': customer };
        if (!!(revision) && revision && revision != '') {
            params['revision'] = revision;
        }
        return this.zappsmithWebService.post('/load_tailoring', params);
    };

    reset_service(): Promise<any> {
        return this.zappsmithWebService.get('/reset_service', {});
    };

    clear_cache(): Promise<any> {
        return this.zappsmithWebService.post('/clear_cache', {});
    };

    setup_scheduler(): Promise<any> {
        return this.zappsmithWebService.post('/setup_scheduler', {});
    };

    update_build(kind, release, customer, revision, branch, clear_configuration): Promise<any> {
        const params: ZappsmithBaseParmDict = {};
        if (!!(kind) && kind && kind != '') {
            params['kind'] = kind;
        }
        if (!!(clear_configuration) && clear_configuration) {
            params['clear_configuration'] = clear_configuration;
        }

        if (!!(release) && release && release != '') {
            params['release'] = release;
        }
        if (!!(customer) && customer && customer != '') {
            params['customer'] = customer;
        }
        if (!!(revision) && revision && revision != '') {
            params['revision'] = revision;
        }
        if (!!(branch) && branch && branch != '') {
            params['branch'] = branch;
        }
        return this.zappsmithWebService.get('/update_build', params);
    };

    migrate_collection(collection: string, updated_within?: string): Promise<any> {
        const params: ZappsmithBaseParmDict = {};
        params['collection'] = collection;
        // set drop target to true if moving more then the last day
        if (!!(updated_within) && updated_within && updated_within != '') {
            params['updated_within'] = updated_within;
        }
        else {
            params['drop_target'] = true;
        }
        return this.zappsmithWebService.get('/migrate_collection', params);
    };
}
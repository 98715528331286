import { Injectable } from "@angular/core";

import { AppAuthService } from "@dicorp/zappsmith-ngx-auth";
import { MdlBaseObject, MdlKeyObject, UserCredentials, UserStore, ZappsmithBaseParmDict, ZappsmithWebService } from "@dicorp/zappsmith-ngx-core";

import { CacheService } from "./cache.service";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    model: SessionModel;
    isLoggedIn: boolean;

    get userPreferencesLoaded(): boolean {
        return this.model?.userPreferences;
    };

    private userPreferencesLoadedSubject = new Subject<void>();
    userPreferencesLoadedSubject$ = this.userPreferencesLoadedSubject.asObservable();

    constructor(private appAuthService: AppAuthService,
        private userStore: UserStore,
        private zappsmithWebService: ZappsmithWebService,
        private cacheService: CacheService) {
        appAuthService?.isLoggedIn?.subscribe(isLoggedIn => {
            this.isLoggedIn = isLoggedIn;
        });

        this.model = { credentials: this.userStore.activeUserCredentials };
        this.updateCredentials();
        this.refreshUserPreferences();
    }

    updateCredentials() {
        if (this.isLoggedIn) {
            this.model.changingPasswordMode = false;
        }
        this.model.userName = this.isLoggedIn ? this.userStore.activeUser?.PrimaryUserName : null;
        this.model.userKey = this.isLoggedIn ? this.userStore.activeUser?._id : null;
        // TODO Update UserStore in ngx-core
        this.model.userId = this.isLoggedIn ? this.userStore.activeUser?.user?.id : null;
        this.model.facilityKey = this.isLoggedIn ? this.userStore.activeUser?.facility_key : null;
        this.model.facilityOrigKey = this.isLoggedIn ? (this.userStore.activeUser?.facility as any)?.orig_key : null;
        this.model.facilityId = this.isLoggedIn ? this.userStore.activeUser?.facilityLink['@id'] : null;
        this.model.facilityName = this.isLoggedIn ? this.userStore.activeUser?.facilityLink['@name'] : null;
        this.model.childFacilities = this.isLoggedIn ? this.userStore.activeUser.child_facilities : null;
        this.model.tenantFacilities = this.isLoggedIn ? this.userStore.activeUser.tenant_facilities : null;
        this.model.accountFacilities = this.isLoggedIn ? this.userStore.activeUser.account_facilities : null;
        this.model.isAdmin = this.isLoggedIn ? this.userStore.activeUser.isAdmin : null;
        // TODO Update UserStore in ngx-core
        this.model.tenant = this.isLoggedIn ? this.userStore.activeUser?._rawObject?.tenant : null;
        // TODO Update UserStore in ngx-core
        this.model.tenantDetails = this.isLoggedIn ? this.userStore.activeUser?._rawObject?.tenant_details : null;
        // TODO Update UserStore in ngx-core
        this.model.personInfo = this.isLoggedIn ? this.userStore.activeUser?._rawObject?.person_info : null;
    };

    // refreshCredentials(): Promise<SessionModel> {
    //     return new Promise<SessionModel>((resolve, reject) => {
    //         this.zappsmithWebService.get('/credentials?unused=' + new Date().getTime(), {}).then(
    //             result => {
    //                 this.model = { credentials: result };

    //                 return this.zappsmithWebService.get('/user_preferences?unused=' + new Date().getTime(), {}).then(
    //                     result => {
    //                         delete result['_version']; // strip off the _version since we want to ignore as we update the changes to version number
    //                         this.model.userPreferences = result;
    //                         this.updateCredentials();
    //                         resolve(this.model);
    //                     },
    //                     result => {
    //                         console.error(result);
    //                         reject();
    //                     });
    //             },
    //             result => {
    //                 console.error(result);
    //                 reject();
    //             });
    //     });
    // };

    isChangingPassword(): boolean {
        return this.model.changingPasswordMode;
    };

    isGuest(): boolean {
        return this.model.userId && this.model.userName.startsWith("_guest_");
    };

    forceChangePassword(): void {
        this.model.changingPasswordMode = true;
        this.updateCredentials();
    };

    getCredentials(): Promise<SessionModel> {
        return Promise.resolve(this.model);
        // if (this.isLoggedIn) {
        //     return Promise.resolve(this.model);
        // } else {
        //     return this.refreshCredentials();
        // }
    };

    // User AppAuthService for this now
    // change_account(facility: any) {
    //     this.model.credentials = null; // lose the local info
    //     this.cacheService.clearCache();
    //     this.updateCredentials();

    //     const params: ZappsmithBaseParmDict = {}
    //     if (facility) {
    //         params['facility_id'] = facility;
    //     }
    //     return this.zappsmithWebService.post('/change_account', params);
    // };

    logout() {
        this.cacheService.clearCache();
        this.appAuthService.logout();
    };

    hasFacilityAsChild(key: string, includeBase: boolean = true): boolean {
        let retval: boolean = false;
        if (includeBase && (this.model.facilityKey == key || this.model.facilityOrigKey == key)) {
            retval = true;
        } else if (this.model.childFacilities) {
            this.model.childFacilities.forEach(v => {
                if (v.key == key || v.orig_key == key) {
                    retval = true;
                }
            })
        }
        return retval;
    };

    hasFacilityAsTenant(key: string): boolean {
        let retval: boolean = false;
        if (this.model.tenantFacilities) {
            this.model.tenantFacilities.forEach(v => {
                if (v.key == key || v.orig_key == key) {
                    retval = true;
                }
            })
        }
        return retval;
    };

    resolvePermissionName(name: string): string {
        const perm = (this.model && this.model.credentials) ? this.userStore.activeUser.permissions : null;
        if (perm && name in perm) {
            return name;
        }
        if (name.indexOf(':') > -1) {
            const nameParts = name.split(':');
            const tail = nameParts.pop(); // This is the level or ability tag
            nameParts.pop(); // drop the section
            nameParts.push(tail); // add the tail back on
            // console.log('Falling back '+ name + ' to try '+ nameParts.join(':'));
            return this.resolvePermissionName(nameParts.join(':'));
        }
        return name;
    };

    getPermission(name: string): number {
        let value = "0";
        name = this.resolvePermissionName(name);
        const perm = (this.model && this.model.credentials) ? this.userStore.activeUser.permissions : null;
        if (!!(perm) && perm != null) {
            if (!(name in perm)) {
                value = "0"
            } else {
                const new_value = perm[name];
                if (!!(new_value)) {
                    value = new_value?.toString();
                }
                if (value === null || value == 'null') {
                    value = "0"
                }
            }
        }
        //$log.info("Security of " + name + " = " + value);
        return parseInt(value);
    };

    isPermissionPresent(name: string): boolean {
        const perm = (this.model && this.model.credentials) ? this.userStore.activeUser.permissions : null;
        name = this.resolvePermissionName(name);
        const retval = (!!(perm) && perm != null && (name in perm));
        return retval;
    };

    hasFacilityAccess(facilityKey: string, permissionName: string): boolean {
        let retval: boolean = false;
        if (facilityKey == null) {
            retval = true;
        } else {
            facilityKey = facilityKey.toString(); // cast to string just in case
            const perm = this.getPermission(permissionName);
            if (this.model.isAdmin || perm == 9) {
                retval = true;
            }
            // else if (perm == 0)
            // {
            //     retval = false;
            // }
            else if (perm == 1 || perm == 2) {
                retval = this.model.facilityKey == facilityKey || this.model.facilityOrigKey == facilityKey;
            } else if (perm == 3 || perm == 4) {
                retval = this.hasFacilityAsChild(facilityKey, perm == 3); // include base on 3 but not 4
            } else if (perm == 8) {
                retval = this.hasFacilityAsTenant(facilityKey); // include base on 3 but not 4
            }

        }
        return retval;

    };

    isAdmin(): boolean {
        return this.model.isAdmin == true;
    };

    hasPermission(name: string, minLevel: number = 1): boolean {
        return this.isAdmin() || this.getPermission(name) >= minLevel;
    };

    hasAnyPermission(permissions: [string] | string, minLevel: number = 1): boolean {
        const self = this;
        let retval = false;
        if (typeof permissions == "string") {
            permissions = [permissions]; // turn it into list
        }
        if (self.isAdmin()) {
            retval = true;
        } else {

            permissions.forEach(value => {
                const level = self.getPermission(value);
                if (level >= minLevel) {
                    retval = true;
                    // return retval; // since break isn't doing it ;-) - this won't do anything either
                }
            });
        }
        return retval;
    };

    change_password(username: string, facilityId: string, old_password: string, new_password: string) {
        const params: ZappsmithBaseParmDict = {
            'username': username,
            'facility_id': facilityId,
            'old_password': old_password,
            'new_password': new_password
        };
        return this.zappsmithWebService.post('/change_password', params);
    };

    forgot_password(username: string, email_address: string): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'username': username, 'email_address': email_address };
        return this.zappsmithWebService.post('/forgot_password', params);
    };

    get_password_settings(facilityId: string): Promise<any> {
        return this.zappsmithWebService.get('/get_password_settings?facility_id=' + facilityId, {});
    };

    fixPermissionName(s: string): string {
        const pattern = /^([a-z\u00E0-\u00FC])|[\s_]+([a-z\u00E0-\u00FC])/g;
        let new_str = (s + '').replace(pattern, function ($1) {
            return $1.toUpperCase()
        });
        new_str = new_str.replace(/_/g, '');
        // special case for dodtr
        new_str = new_str.replace('Dodtr', 'DodTr');
        return new_str;
    };

    getUserPreferences(refresh?: boolean): Promise<any> {
        if (!refresh && this.model?.userPreferences) {
            return Promise.resolve(this.model.userPreferences);
        } else {
            return this.refreshUserPreferences();
        }
    }

    refreshUserPreferences(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.zappsmithWebService.get('/user_preferences?unused=' + new Date().getTime(), {}).then(
                result => {
                    delete result['_version']; // strip off the _version since we want to ignore as we update the changes to version number
                    this.model.userPreferences = result;

                    this.userPreferencesLoadedSubject.next();

                    resolve(this.model.userPreferences);
                },
                result => {
                    console.error(result);
                    resolve(this.model.userPreferences);
                });
        })
    }

    getUserConfigurationItem(item: string, default_value: any): any {
        return (this.model.userPreferences && !!(this.model.userPreferences[item])) ?
            this.model.userPreferences[item] : default_value;
    };

    getUserConfigurationItemAsync(item: string, default_value: any): Promise<any> {
        if (this.userPreferencesLoaded) {
            return Promise.resolve(this.getUserConfigurationItem(item, default_value));
        } else {
            return new Promise<any>(resolve => {
                const sub = this.userPreferencesLoadedSubject$.subscribe(routesLoaded => {
                    sub?.unsubscribe();
                    resolve(this.getUserConfigurationItem(item, default_value));
                });
            })
        }
    };

    setUserConfigurationItem(item: string, value: any): void {
        this.model.userPreferences[item] = value;
    };

    saveUserPreference(): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'document': JSON.stringify(this.model.userPreferences) };
        return this.zappsmithWebService.post('/user_preferences', params);
    }
}

export interface SessionModel {
    credentials: UserCredentials,

    isAdmin?: boolean,
    changingPasswordMode?: boolean,

    userName?: string,
    userId?: string,
    userKey?: string,

    userPreferences?: any,

    personInfo?: any,

    facilityId?: string,
    facilityKey?: string,
    facilityOrigKey?: string,
    facilityName?: string,

    tenant?: any,
    tenantDetails?: any,
    tenantFacilities?: SessionFacility[],

    childFacilities?: SessionFacility[],
    accountFacilities?: MdlKeyObject[],
}

export interface SessionFacility {
    id: string,
    name: string,
    key: string,
    orig_key?: string
}
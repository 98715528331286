import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanMatchFn, Route, RouterStateSnapshot, Routes } from "@angular/router";

import { RoutingService, SessionService } from "src/services";

import { PortalRoutingService } from "../../models";
import { UserStore } from "@dicorp/zappsmith-ngx-core";

const DRILLER_REDIRECT_URL = '/driller/index_ravs.html#/reporting?config_id=Standard';

@Injectable({
    providedIn: 'root'
})
export class ReportingRoutingService implements PortalRoutingService {
    getRouterRoute(): Promise<Route> {
        return Promise.resolve(reportingRoute);
    }

    getToolbarRoute(): Promise<Route> {
        return Promise.resolve(reportingToolbarRoute);
    }
}

@Injectable({
    providedIn: 'root'
})
class CanActivateDrillerModuleAbility implements CanActivate {
    constructor(private sessionService: SessionService) { }

    canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.sessionService.hasPermission('DrillerModuleAbility');
    }
}

function canMatchDrillerModuleAbility(): CanMatchFn {
    return () => {
        return inject(CanActivateDrillerModuleAbility).canActivate(null, null);
    }
};

@Injectable({
    providedIn: 'root'
})
class CanActivateReportingModule implements CanActivate {
    constructor(
        private userStore: UserStore,
        private routingService: RoutingService) { }

    canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.userStore?.activeUser?.isAdmin) {
            return true;
        } else {
            return this.routingService.canActivateAnyRoute(reportingRoutes, routeSnapshot, state);
        }
    }
}

export const reportingRoutes: Routes = [
    {
        title: 'Driller',
        path: 'Driller',
        redirectTo: DRILLER_REDIRECT_URL,
        canMatch: [canMatchDrillerModuleAbility],
        data: {
            openPathInNewWindow: true,
            openPathFromOrigin: true,
        }
    }
];

const reportingRoute: Route =
{
    title: 'Reporting',
    path: 'Reporting',
    children: reportingRoutes,
    canActivate: [CanActivateReportingModule]
};

const reportingToolbarRoute: Route =
{
    title: 'Reporting',
    path: 'Reporting',
    children: reportingRoutes.filter(route => { return !route.data || !route.data['hideToolbar'] }),
    canActivate: [CanActivateReportingModule]
};
import { Injectable } from "@angular/core";

import { ZappsmithWebService } from "@dicorp/zappsmith-ngx-core";

@Injectable({
    providedIn: 'root'
})
export class MessageOfTheDayService {
    message_of_day: string;
    raw_message_of_day: string;

    constructor(private zappsmithWebService: ZappsmithWebService) {
    }

    refresh(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.zappsmithWebService.get('/message_of_day', {}).then(
                result => {
                    this.message_of_day = result?.data;
                    resolve(this.message_of_day);
                },
                result => {
                    if (result.status == 403) {
                        // just ignore the 403 case since we aren't logged in
                        resolve(this.message_of_day);
                    } else {
                        console.error(result);
                        reject();
                    }
                });
        });
    }

    refresh_raw(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.zappsmithWebService.get('/message_of_day', { is_raw: true }).then(
                result => {
                    this.raw_message_of_day = result?.data;
                    resolve(this.raw_message_of_day);
                },
                result => {
                    if (result.status == 403) {
                        // just ignore the 403 case since we aren't logged in
                        resolve(this.raw_message_of_day);
                    } else {
                        console.error(result);
                        reject();
                    }
                });
        });
    }

    get(): Promise<string> {
        if (this.message_of_day) {
            return Promise.resolve(this.message_of_day);
        } else {
            return this.refresh();
        }
    };

    get_raw(): Promise<string> {
        if (this.raw_message_of_day) {
            return Promise.resolve(this.raw_message_of_day);
        } else {
            return this.refresh();
        }
    };

    save(new_msg: string): Promise<any> {
        return this.zappsmithWebService.post('/message_of_day', { 'data': new_msg });
    }
}
<mat-card>
    <mat-card-header>
        <mat-card-title>Configuration Editor</mat-card-title>

        <mat-card-actions align="end">
            <button mat-button (click)="save()" [disabled]="!updatedData">Save</button>
            <button mat-button (click)="cancel()">Cancel</button>
        </mat-card-actions>
    </mat-card-header>
    <mat-card-content>
        <form>
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <input matInput [(ngModel)]="stringToEncrypt" [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="encryptString()" tabindex="0" placeholder="Enter value to encrypt" />
            </mat-form-field>

            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="disabled-form-field">
                <input matInput [ngModel]="encryptedString" [ngModelOptions]="{standalone: true}" tabindex="1"
                    disabled />
            </mat-form-field>
        </form>

        <json-editor [options]="editorOptions" [data]="data" (change)="dataChanged()"></json-editor>
    </mat-card-content>
</mat-card>
import { Component } from '@angular/core';

@Component({
    selector: 'zs-raw-report-query',
    templateUrl: 'raw-report-query.component.html',
    styleUrls: ['raw-report-query.component.scss']
})
export class RawReportQueryComponent {

}

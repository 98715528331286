import { Injectable } from "@angular/core";

import { ZappsmithWebService, ZappsmithBaseParmDict } from "@dicorp/zappsmith-ngx-core";

@Injectable({
    providedIn: 'root'
})
export class ZappService {

    constructor(private zappsmithWebService: ZappsmithWebService) {
    }

    publish(boardIds: string[], appIds: string[], target: string = null): Promise<any> {
        const params: ZappsmithBaseParmDict = {
            'board_ids': JSON.stringify(boardIds),
            'app_ids': JSON.stringify(appIds)
        };
        if (target) {
            params['target'] = target;
        }

        return this.zappsmithWebService.post('/zapp_publish', params);
    };

    load(customer: string, revision: string): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'customer': customer };
        if (!!(revision) && revision && revision != '') {
            params['revision'] = revision;
        }

        return this.zappsmithWebService.post('/zapp_load', params);
    };

    transfer_server(url: string, username: string, facility_id: string, password: string, zapp_filter: any = null): Promise<any> {
        const params: ZappsmithBaseParmDict = { url, username, facility_id, password };
        if (zapp_filter) {
            params['zapp_filter'] = zapp_filter;
        }
        return this.zappsmithWebService.post('/zapp_transfer_server', params);
    };
}
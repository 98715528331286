export class PortalFunctions {
    static deep_value(obj: any, path: string): any {
        const spath = path.split('.');
        let i = 0;
        const len = spath.length;
        for (; i < len; i++) {
            if ((typeof obj === 'undefined') || obj === null) {
                obj = null;
                break;
            }
            obj = obj[spath[i]];
        }
        return obj;
    };

    static set_deep_value(obj: any, path: string, value: any) {
        let cobj = obj;
        const spath = path.split('.');
        let i = 0;
        const len = spath.length;
        for (; i < (len - 1); i++) { //iterate up to the end
            const next_child = spath[i];
            if (!cobj[next_child]) {
                cobj[next_child] = {};
            }
            cobj = cobj[next_child];
        }
        cobj[spath[spath.length - 1]] = value; // make the last one the value
        return obj;
    };

    static convertCurrentIndexes(rawValue: string, currentIndexReplacement: number[] | null, offset: number = 0): any {
        if (rawValue && currentIndexReplacement?.length > 0) {
            for (let cir = 0; cir < currentIndexReplacement.length; cir++) {
                const cirIdxStr = (cir).toString();
                const posReplacementStr = (currentIndexReplacement[cir] + offset).toString();
                rawValue = rawValue.split('$CURRENT' + cirIdxStr).join(posReplacementStr);
                rawValue = rawValue.split('CURRENT' + cirIdxStr).join(posReplacementStr);
            }
            //if (currentIndexReplacement.length > 1) {
            // if multiple only replace one at a time
            const convertInner = function (items: string[]) {
                let retval = items[0];
                const ilength = items.length;
                if (ilength > 1) {
                    for (let i = 1; i < ilength; i++) {
                        retval += (currentIndexReplacement[(i <= currentIndexReplacement.length) ? i - 1 : 0] + offset).toString() + items[i];
                    }
                }
                return retval;
            };

            rawValue = convertInner(rawValue.split('$CURRENT'));
            rawValue = convertInner(rawValue.split('CURRENT'));

            // }
            // // replace anything left over
            // rawValue = rawValue.split('$CURRENT').join(currentIndexReplacement[0].toString());
            // rawValue = rawValue.split('CURRENT').join(currentIndexReplacement[0].toString());

        }
        return rawValue;
    };

    static copyObject(value: any): any {
        const copy: any = {};
        Object.assign(copy, value);
        return copy;
    };

    static isTrueValue(x: any): boolean {
        return (x === 1 || x === "True" || x === "true" || x === true);
    };

    static isDateObj(cval: any): boolean {
        return cval && typeof cval.getTime === 'function';
    };
}
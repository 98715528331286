import { Injectable } from "@angular/core";

import { ZappsmithWebService, ZappsmithBaseParmDict } from "@dicorp/zappsmith-ngx-core";

@Injectable({
    providedIn: 'root'
})
export class AutoSaveService {

    constructor(private zappsmithWebService: ZappsmithWebService) {
    }

    // autosave only works for current session Id, should know base
    save(base: string, document: any): Promise<any> {
        // strip the tracking_info
        if (document.hasOwnProperty('tracking_info')) {
            delete document.tracking_info;
        }
        // strip the version
        if (document.hasOwnProperty('_version')) {
            delete document._version;
        }
        const params: ZappsmithBaseParmDict = { 'document': JSON.stringify(document) };
        return this.zappsmithWebService.post('/autosave' + base, params);
    };

    load(base: string, doc_id: string): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'doc_id': doc_id };
        return this.zappsmithWebService.get('/autosave' + base, params);
    };

    release(base: string, doc_id: string): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'doc_id': doc_id };
        return this.zappsmithWebService.delete('/autosave' + base, params);
    };
}
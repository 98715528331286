import { Component } from '@angular/core';

@Component({
    selector: 'zs-data-transform',
    templateUrl: 'data-transform.component.html',
    styleUrls: ['data-transform.component.scss']
})
export class DataTransformComponent {

}

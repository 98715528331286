<!-- Loading Spinner -->
<mat-card class="board-data-pass-loading" [hidden]="!showLoadingSpinner">
    <mat-spinner mode="indeterminate" diameter="200">
    </mat-spinner>
</mat-card>

<!-- Board Data Pass -->
<mat-card appearance="outlined" [hidden]="showLoadingSpinner">
    <mat-card-header>
        <mat-card-title>Board Data Pass</mat-card-title>
    </mat-card-header>
    <mat-card-content>

        <section>
            <mat-slide-toggle [(ngModel)]="reviewOnly" [ngModelOptions]="{standalone: true}">
                Review Only (do not modify)
            </mat-slide-toggle>
        </section>

        <section style="margin-bottom: 10px;">
            <div>
                <input #fileInput hidden="true" type="file" onclick="this.value=null"
                    (change)="fileInputChange($event)" />
                <button mat-flat-button (click)="fileInput.click()">Select File to Load</button>
            </div>
        </section>

        <!-- Counts HFE Editor -->
        <zs-hfe-portal-editor [editorOptions]="countsEditorOptions"
            [hidden]="!countsEditorOptions?.html_ffe_api?.HtmlFFEService?.html_ffe_editor_ready">
        </zs-hfe-portal-editor>

        <!-- Changes HFE Editor -->
        <zs-hfe-portal-editor [editorOptions]="changesEditorOptions"
            [hidden]="!changesEditorOptions?.html_ffe_api?.HtmlFFEService?.html_ffe_editor_ready">
        </zs-hfe-portal-editor>

    </mat-card-content>
</mat-card>
<!-- Migrate Collection -->
<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Migrate Collection</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <!-- Collection -->
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Collection</mat-label>
                <input matInput [(ngModel)]="collection" [ngModelOptions]="{standalone: true}" maxlength="40"
                    size="40" />
            </mat-form-field>
        </section>

        <!-- Updated Within -->
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Updated Within x days</mat-label>
                <input matInput [(ngModel)]="updatedWithin" [ngModelOptions]="{standalone: true}" maxlength="40"
                    size="40" type="number" placeholder="All" />
            </mat-form-field>
        </section>

        <!-- Migrate Collection -->
        <section>
            <button mat-flat-button (click)="migrateCollection()" [disabled]="!collection">Migrate Collection</button>
        </section>
    </mat-card-content>
</mat-card>
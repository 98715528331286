import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog } from "@angular/material/dialog";

import { filter } from "rxjs";

import { AlertData, AlertType, AlertDialogData, AlertDialogType } from './alert-models';
import { AlertListComponent } from "./alert-list/alert-list.component";
import { AlertDialogComponent } from "./alert-dialog/alert-dialog.component";

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    public alerts: AlertData[] = [];

    constructor(public router: Router, private _snackBar: MatSnackBar, private dialog: MatDialog) {
        //Clears all alerts when route is changed
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            // this.alerts = [];
            // this._snackBar.dismiss();
        });
    }

    addAlert(alertData: AlertData): void {
        let exists = false;
        this.alerts.forEach(alert => {
            if (alert.message == alertData.message) {
                exists = true;
                return;
            }
        });
        if (!exists) {
            this._pushAlert(alertData);
        }

    };

    openOkAlert(alertData: AlertData): Promise<void> {
        return this._okAlert(alertData)
    }

    removeAlert(alertData: AlertData): void {
        const index = this.alerts.indexOf(alertData);
        this.alerts.splice(index, 1)
    };

    clearAllAlerts(): void {
        this.alerts = [];
    };

    openOkDialog(alertDialogData: AlertDialogData): Promise<boolean> {
        alertDialogData.type = AlertDialogType.ok;
        return this._openDialog(alertDialogData);
    }

    openOkCancelDialog(alertDialogData: AlertDialogData): Promise<boolean> {
        alertDialogData.type = AlertDialogType.okCancel;
        return this._openDialog(alertDialogData);
    }

    private _pushAlert(alertData: AlertData, config?: MatSnackBarConfig): void {
        this.alerts.push(alertData);

        config = config ? config : {};

        // Add a limited duration if all messages are success
        if (!config.duration && !alertData.require_confirm) {
            const allSuccess = this.alerts.filter(alertData => {
                return alertData.type !== AlertType.success;
            }).length === 0;

            if (allSuccess) {
                config.duration = alertData.duration ? alertData.duration : DEFAULT_ALERT_DURATION;
            }
        }

        config.horizontalPosition = config.horizontalPosition ? config.horizontalPosition : 'center';
        config.verticalPosition = config.verticalPosition ? config.verticalPosition : 'top';

        const snackBarRef = this._snackBar.openFromComponent(AlertListComponent, config);

        snackBarRef.afterDismissed().subscribe(result => {
            this.alerts.splice(this.alerts.indexOf(alertData))
        });
    };

    private _okAlert(alertData: AlertData, config?: MatSnackBarConfig): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.alerts.push(alertData);

            config = config ? config : {};

            config.horizontalPosition = config.horizontalPosition ? config.horizontalPosition : 'center';
            config.verticalPosition = config.verticalPosition ? config.verticalPosition : 'top';

            const snackBarRef = this._snackBar.openFromComponent(AlertListComponent, config);

            snackBarRef.afterDismissed().subscribe(result => {
                this.alerts.splice(this.alerts.indexOf(alertData));
                resolve();
            });
        });
    };

    private _openDialog(alertDialogData: AlertDialogData): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const dialogRef = this.dialog.open(AlertDialogComponent, {
                data: alertDialogData,
                disableClose: true,
                closeOnNavigation: true,
                hasBackdrop: true
            });

            dialogRef.afterClosed().subscribe((result: boolean) => {
                resolve(result)
            });
        });
    }
}

const DEFAULT_ALERT_DURATION = 5000;
import * as i0 from '@angular/core';
import { NgModule, InjectionToken, Injectable, Inject } from '@angular/core';
import * as i1 from '@angular/common/http';
import { provideHttpClient, withInterceptorsFromDi, HttpContextToken, HttpParams } from '@angular/common/http';
import * as i2 from '@dicorp/zappsmith-ngx-auth';
import { ZappsmithNgxAuthModule } from '@dicorp/zappsmith-ngx-auth';
import { formatDate, APP_BASE_HREF } from '@angular/common';
import * as i1$1 from '@angular/cdk/layout';
import { Breakpoints } from '@angular/cdk/layout';
import * as i1$2 from '@anvilor/ngx-theming';
import { ComponentStore } from '@ngrx/component-store';
class ZappsmithNgxCoreModule {
  static ɵfac = function ZappsmithNgxCoreModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ZappsmithNgxCoreModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ZappsmithNgxCoreModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [provideHttpClient(withInterceptorsFromDi())],
    imports: [ZappsmithNgxAuthModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ZappsmithNgxCoreModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      exports: [],
      imports: [ZappsmithNgxAuthModule],
      providers: [provideHttpClient(withInterceptorsFromDi())]
    }]
  }], null, null);
})();
class CommonFunctions {
  static resolvePath(stringPath, baseObject) {
    return stringPath.split('.').reduce((p, q) => {
      return p ? p[q] : null;
    }, baseObject || self);
  }
  static resolvePathId(stringPath, baseObject) {
    return CommonFunctions.resolvePath(stringPath + '.@id', baseObject);
  }
  static resolvePathName(stringPath, baseObject) {
    return CommonFunctions.resolvePath(stringPath + '.@name', baseObject);
  }
  static resolvePathValue(stringPath, baseObject) {
    return CommonFunctions.resolvePath(stringPath + '.#value', baseObject);
  }
  static resolvePathNumberValue(stringPath, baseObject) {
    const numberString = CommonFunctions.resolvePath(stringPath + '.#value', baseObject);
    return numberString ? parseFloat(numberString) : undefined;
  }
  static resolvePathDateValue(stringPath, baseObject) {
    const isoString = CommonFunctions.resolvePath(stringPath + '.#value', baseObject);
    return isoString ? new Date(isoString) : undefined;
  }
  static getMdlObjectValue(baseObject) {
    return baseObject?.["#value"];
  }
  static getMdlObjectId(baseObject) {
    return baseObject?.["@id"];
  }
  static getMdlObjectName(baseObject) {
    return baseObject?.["@name"];
  }
  static valueObject(value) {
    return {
      '#value': value
    };
  }
  static baseObject(v, id, name) {
    return {
      '#value': v,
      '@id': id,
      '@name': name
    };
  }
  static minimizeBaseObject(baseObject) {
    return CommonFunctions.baseObject(CommonFunctions.getMdlObjectValue(baseObject), CommonFunctions.getMdlObjectId(baseObject), CommonFunctions.getMdlObjectName(baseObject));
  }
  static stringValue(s) {
    return s + '.#value';
  }
  static sortByStringProperty(values, property) {
    return values.sort((a, b) => {
      const aProperty = CommonFunctions.resolvePath(property, a);
      const bProperty = CommonFunctions.resolvePath(property, b);
      if (aProperty) {
        return aProperty.toString().localeCompare(bProperty);
      } else if (bProperty) {
        return bProperty.toString().localeCompare(aProperty);
      } else {
        return 0;
      }
    });
  }
  static sortByStringPropertyValue(values, property) {
    return CommonFunctions.sortByStringProperty(values, property + '.#value');
  }
  static sortByNumericProperty(values, property) {
    return values.sort((a, b) => {
      const aProperty = CommonFunctions.resolvePath(property, a);
      const bProperty = CommonFunctions.resolvePath(property, b);
      return parseFloat(aProperty) - parseFloat(bProperty);
    });
  }
  static sortByNumericPropertyValue(values, property) {
    return CommonFunctions.sortByNumericProperty(values, property + '.#value');
  }
  static formatArrayPathName(stringPath, baseObject) {
    let names = '';
    const values = CommonFunctions.resolvePath(stringPath, baseObject);
    if (values) {
      for (let i = 0; i < values.length; i++) {
        names += CommonFunctions.getMdlObjectName(values[i]);
        names += i < values.length - 1 ? '<br/>' : '';
      }
    }
    return names;
  }
  static formatPath(stringPath, baseObject) {
    const pathValue = CommonFunctions.resolvePath(stringPath, baseObject);
    if (pathValue) {
      return pathValue;
    } else {
      return '';
    }
  }
  static formatPathValue(stringPath, baseObject) {
    return CommonFunctions.formatPath(stringPath + '.#value', baseObject);
  }
  static getMenuOption(value, options) {
    const valueMap = CommonFunctions.getMdlObjectValue(value);
    return options.find(option => {
      return valueMap === CommonFunctions.getMdlObjectValue(option);
    });
  }
  static getMenuOptions(values, options) {
    const valuesMap = values.map(value => {
      return CommonFunctions.getMdlObjectValue(value);
    });
    return options.filter(option => {
      return valuesMap.indexOf(CommonFunctions.getMdlObjectValue(option)) >= 0;
    });
  }
  static hasMenuOption(options, values) {
    const optionsArray = Array.isArray(options) ? options : [options];
    const optionsMap = optionsArray?.map(option => {
      return CommonFunctions.getMdlObjectValue(option);
    });
    const valuesMap = values?.map(value => {
      return CommonFunctions.getMdlObjectValue(value);
    });
    return valuesMap?.find(value => {
      return optionsMap.indexOf(value) >= 0;
    }) !== undefined;
  }
}
class CommonDateFunctions {
  static MILLISECONDS_IN_A_DAY = 86400000;
  static dateValueObject(value) {
    return {
      '#value': CommonDateFunctions.getDateOnlyISOString(value)
    };
  }
  static dateTimeValueObject(value) {
    return {
      '#value': value.toISOString()
    };
  }
  static formatDateTime(dateString, dateFormat) {
    if (dateString) {
      const date = new Date(dateString);
      return formatDate(date, dateFormat ? dateFormat : 'shortDate', 'en-US');
    } else {
      return '';
    }
  }
  static formatDateTimeAsTime(dateString, timeFormat) {
    if (dateString) {
      const date = new Date(dateString);
      return formatDate(date, timeFormat ? timeFormat : 'shortTime', 'en-US');
    } else {
      return '';
    }
  }
  static formatDateEndTime(dateString) {
    if (dateString) {
      return '11:59 PM';
    } else {
      return '';
    }
  }
  static formateDateTimeForLast24Hours(datetime) {
    if (CommonDateFunctions.dateTimeIsWithin24HoursOfToday(datetime)) {
      return CommonDateFunctions.formatDateTimeAsTime(datetime);
    } else {
      return CommonDateFunctions.formatDateTime(datetime);
    }
  }
  static formateDateForLast24Hours(datetime) {
    if (CommonDateFunctions.dateTimeIsWithin24HoursOfToday(datetime)) {
      return CommonDateFunctions.formatDateEndTime(datetime);
    } else {
      return CommonDateFunctions.formatDateTime(datetime);
    }
  }
  static resolveDateTime(datePath, baseObject, dateFormat) {
    const pathValue = CommonFunctions.resolvePath(datePath, baseObject);
    return CommonDateFunctions.formatDateTime(pathValue, dateFormat);
  }
  static resolveDateTimeValue(datePath, baseObject, dateFormat) {
    const pathValue = CommonFunctions.resolvePathValue(datePath, baseObject);
    return CommonDateFunctions.formatDateTime(pathValue, dateFormat ? dateFormat : 'short');
  }
  static resolveDatePath(datePath, baseObject, dateFormat) {
    const pathValue = CommonFunctions.resolvePath(datePath, baseObject);
    if (pathValue) {
      const dateUtc = new Date(pathValue);
      const localDate = CommonDateFunctions.getLocalDate(dateUtc);
      return formatDate(localDate, dateFormat ? dateFormat : 'longDate', 'en-US');
    } else {
      return '';
    }
  }
  static resolveDatePathValue(datePath, baseObject, dateFormat) {
    return CommonDateFunctions.resolveDatePath(datePath + '.#value', baseObject);
  }
  static getTodayDateIsoString() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() >= 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1);
    const day = today.getDate() >= 10 ? today.getDate() : '0' + today.getDate();
    const todayISOString = year + '-' + month + '-' + day + 'T00:00:00.000Z';
    return todayISOString;
  }
  static getLocalDate(dateUtc) {
    const dateOnlyUtc = new Date(CommonDateFunctions.getDateOnlyISOString(dateUtc));
    return new Date(dateOnlyUtc.getTime() + dateOnlyUtc.getTimezoneOffset() * 60000);
  }
  static getLocalDateTime(dateUtc) {
    return new Date(CommonDateFunctions.getDateTimeISOString(dateUtc));
  }
  static getDateOnlyISOString(value) {
    const dateUtc = new Date(value);
    dateUtc.setUTCHours(0, 0, 0);
    return dateUtc.toISOString();
  }
  static getNextDayDateISOString(value) {
    const nextDay = new Date(value);
    nextDay.setDate(nextDay.getDate() + 1);
    return CommonDateFunctions.getDateOnlyISOString(nextDay);
  }
  static getPreviousDayDateISOString(value) {
    const previousDay = new Date(value);
    previousDay.setDate(previousDay.getDate() - 1);
    return CommonDateFunctions.getDateOnlyISOString(previousDay);
  }
  static getDateTimeISOString(value) {
    return value.toISOString();
  }
  static getNextDayDateTimeISOString(value) {
    const nextDay = new Date(value);
    nextDay.setDate(nextDay.getDate() + 1);
    return CommonDateFunctions.getDateTimeISOString(nextDay);
  }
  static getPreviousDayDateTimeISOString(value) {
    const previousDay = new Date(value);
    previousDay.setDate(previousDay.getDate() - 1);
    return CommonDateFunctions.getDateTimeISOString(previousDay);
  }
  static getDateTimeDiff(date1, date2) {
    const d1 = new Date(date1);
    const d2 = date2 ? new Date(date2) : new Date();
    return Math.abs(d1.getTime() - d2.getTime());
  }
  // allowedDiff is the allowed difference variation between the two date times to allow them to still be equal
  static dateTimesAreEqual(date1, date2, allowedDiff) {
    allowedDiff = allowedDiff >= 0 ? allowedDiff : 0;
    const dateTimeDiff = CommonDateFunctions.getDateTimeDiff(date1, date2);
    return dateTimeDiff < allowedDiff;
  }
  static datesAreEqual(date1, date2) {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDay() === date2.getDay();
  }
  static dateTimeIsWithin24HoursOfToday(date) {
    return CommonDateFunctions.getDateTimeDiff(date) < CommonDateFunctions.MILLISECONDS_IN_A_DAY;
  }
  static dateIsToday(date) {
    if (!date) {
      return false;
    }
    return CommonDateFunctions.getDateOnlyISOString(date) === CommonDateFunctions.getTodayDateIsoString();
  }
  // Date sorting
  static sortByDateProperty(values, property, invert) {
    const sorted = values.sort((a, b) => {
      const aProperty = CommonFunctions.resolvePath(property, a);
      const bProperty = CommonFunctions.resolvePath(property, b);
      if (aProperty && bProperty) {
        const aDate = new Date(aProperty);
        const bDate = new Date(bProperty);
        return aDate?.getTime() - bDate?.getTime();
      } else if (aProperty) {
        return -1;
      } else if (bProperty) {
        return 1;
      } else {
        return 0;
      }
    });
    return !invert ? sorted : sorted.reverse();
  }
  static sortByDatePropertyValue(values, property, invert) {
    return CommonDateFunctions.sortByDateProperty(values, property + '.#value', invert);
  }
}
class CommonRichTextFunctions {
  static EMPTY_PARAGRAPH = '<p></p>';
  static EMPTY_PARAGRAPH_NEW_LINE = '<p>&nbsp;</p>';
  static EMPTY_PARAGRAPH_UNDEFINED = '<p>undefined</p>';
  static touchUpRichText(richText) {
    return richText?.replace(/\n/g, '<br>')?.replace(CommonRichTextFunctions.EMPTY_PARAGRAPH, CommonRichTextFunctions.EMPTY_PARAGRAPH_NEW_LINE);
  }
  static richTextNotEmpty(richText) {
    return richText !== CommonRichTextFunctions.EMPTY_PARAGRAPH && richText != CommonRichTextFunctions.EMPTY_PARAGRAPH_NEW_LINE && richText != CommonRichTextFunctions.EMPTY_PARAGRAPH_UNDEFINED;
  }
}
class CommonColorFunctions {
  static standardizeColor(colorString) {
    var ctx = document.createElement('canvas').getContext('2d');
    ctx.fillStyle = colorString;
    return ctx.fillStyle;
  }
  static hexToRGB(hexValue) {
    const scInt = parseInt(hexValue.substring(1), 16);
    var r = scInt >> 16 & 0xff; // extract red
    var g = scInt >> 8 & 0xff; // extract green
    var b = scInt >> 0 & 0xff; // extract blue
    return {
      r,
      g,
      b
    };
  }
  static rgbCssStyle(rgb, opacity) {
    if (opacity) {
      return 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + opacity + ')';
    } else {
      return 'rgb(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ')';
    }
  }
  static hexLuma(hexValue) {
    const rgb = CommonColorFunctions.hexToRGB(hexValue);
    return 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b; // per ITU-R BT.709
  }
  static hexLumaInvert(hexValue) {
    return 255 - this.hexLuma(hexValue);
  }
  static colorStringLuma(colorString) {
    const sc = CommonColorFunctions.standardizeColor(colorString);
    return CommonColorFunctions.hexLumaInvert(sc);
  }
  static scaleRGB(value, xMin, xMax) {
    const yMax = 255;
    const yMin = 0;
    const percent = (value - yMin) / (yMax - yMin);
    const scaled = percent * (xMax - xMin) + xMin;
    return parseFloat(scaled.toString());
  }
  static getColorContrast(colorString) {
    const scLuma = CommonColorFunctions.colorStringLuma(colorString);
    return CommonColorFunctions.scaleRGB(scLuma, 0, 100);
  }
  static getColorInvert(colorString) {
    const scLuma = CommonColorFunctions.colorStringLuma(colorString);
    const scale = CommonColorFunctions.scaleRGB(scLuma, 0, 10);
    return scale < 5 ? 0 : 1;
  }
}
var FilterOperationType;
(function (FilterOperationType) {
  FilterOperationType["oid"] = "oid";
  FilterOperationType["string"] = "string";
  FilterOperationType["text"] = "text";
  FilterOperationType["number"] = "number";
  FilterOperationType["date"] = "date";
  FilterOperationType["time"] = "time";
  FilterOperationType["datetime"] = "datetime";
  FilterOperationType["bool"] = "bool";
  FilterOperationType["set"] = "set";
  FilterOperationType["menuset"] = "menuset";
})(FilterOperationType || (FilterOperationType = {}));
var FilterOperationValue;
(function (FilterOperationValue) {
  FilterOperationValue["eq"] = "eq";
  FilterOperationValue["neq"] = "neq";
  FilterOperationValue["contains"] = "contains";
  FilterOperationValue["notcontains"] = "notcontains";
  FilterOperationValue["starts_with"] = "starts_with";
  FilterOperationValue["ends_with"] = "ends_with";
  FilterOperationValue["lt"] = "lt";
  FilterOperationValue["lte"] = "lte";
  FilterOperationValue["lte_null"] = "lte_null";
  FilterOperationValue["gt"] = "gt";
  FilterOperationValue["gte"] = "gte";
  FilterOperationValue["gte_null"] = "gte_null";
  FilterOperationValue["range"] = "range";
  FilterOperationValue["o_range"] = "o_range";
  FilterOperationValue["regex"] = "regex";
  FilterOperationValue["in"] = "in";
  FilterOperationValue["nin"] = "nin";
})(FilterOperationValue || (FilterOperationValue = {}));
class FilterOperation {
  label;
  description;
  short_label;
  value;
}
class FilterOperations {
  static eq = {
    label: 'Equal To',
    description: 'Equal To',
    short_label: '=',
    value: FilterOperationValue.eq
  };
  static neq = {
    label: 'Not Equal To',
    description: 'Not Equal To',
    short_label: '!=',
    value: FilterOperationValue.neq
  };
  static contains = {
    label: 'Contains',
    description: 'Contains',
    short_label: 'contains',
    value: FilterOperationValue.contains
  };
  static notcontains = {
    label: 'Does Not Contain',
    description: 'Does Not Contain',
    short_label: '!contains',
    value: FilterOperationValue.notcontains
  };
  static starts_with = {
    label: 'Starts With',
    description: 'Starts With',
    short_label: 'starts',
    value: FilterOperationValue.starts_with
  };
  static ends_with = {
    label: 'Ends With',
    description: 'Ends With',
    short_label: 'ends',
    value: FilterOperationValue.ends_with
  };
  static lt = {
    label: 'Less Than',
    description: 'Less Than',
    short_label: '<',
    value: FilterOperationValue.lt
  };
  static lte = {
    label: 'Less Than or Equal To',
    description: 'Less Than or Equal To',
    short_label: '<=',
    value: FilterOperationValue.lte
  };
  static gt = {
    label: 'Greater Than',
    description: 'Greater Than',
    short_label: '>',
    value: FilterOperationValue.gt
  };
  static gte = {
    label: 'Greater Than or Equal To',
    description: 'Greater Than or Equal To',
    short_label: '>=',
    value: FilterOperationValue.gte
  };
  static range = {
    label: 'Between (Inclusive)',
    description: 'Between (Inclusive)',
    short_label: '=><=',
    value: FilterOperationValue.range
  };
  static o_range = {
    label: 'Between (Exclusive)',
    description: 'Between (Exclusive)',
    short_label: '><',
    value: FilterOperationValue.o_range
  };
  static defaultOperationsDict = [FilterOperations.eq, FilterOperations.neq];
  static textOperationsDict = [FilterOperations.eq, FilterOperations.neq, FilterOperations.contains, FilterOperations.notcontains, FilterOperations.starts_with, FilterOperations.ends_with];
  static numberOperationsDict = [FilterOperations.eq, FilterOperations.neq, FilterOperations.lt, FilterOperations.lte, FilterOperations.gt, FilterOperations.gte, FilterOperations.range, FilterOperations.o_range];
  static dateOperationsDict = [FilterOperations.eq,
  // FilterOperations.neq,
  FilterOperations.lt, FilterOperations.lte, FilterOperations.gt, FilterOperations.gte, FilterOperations.range, FilterOperations.o_range];
  static dateTimeOperationsDict = [FilterOperations.eq,
  // FilterOperations.neq,
  FilterOperations.lt, FilterOperations.lte, FilterOperations.gt, FilterOperations.gte, FilterOperations.range];
  static timeOperationsDict = [FilterOperations.eq,
  // FilterOperations.neq,
  FilterOperations.lt, FilterOperations.lte, FilterOperations.gt, FilterOperations.gte, FilterOperations.range
  // FilterOperations.o_range
  ];
  static boolOperationsDict = [FilterOperations.eq, FilterOperations.neq];
  static setOperationsDict = [FilterOperations.eq, FilterOperations.neq];
  static getOperationsForType(type) {
    switch (type) {
      case FilterOperationType.text:
        return this.textOperationsDict;
      case FilterOperationType.number:
        return this.numberOperationsDict;
      case FilterOperationType.date:
        return this.dateOperationsDict;
      case FilterOperationType.time:
        return this.timeOperationsDict;
      case FilterOperationType.datetime:
        return this.dateTimeOperationsDict;
      case FilterOperationType.bool:
        return this.boolOperationsDict;
      case FilterOperationType.set:
        return this.setOperationsDict;
      case FilterOperationType.menuset:
        return this.defaultOperationsDict;
      default:
        return this.defaultOperationsDict;
    }
  }
  static isRangeFilterOperationValue(value) {
    return value === FilterOperationValue.range || value === FilterOperationValue.o_range;
  }
}
class FilterModelValue {
  value;
  label;
  constructor(value, label) {
    this.value = value;
    this.label = label;
  }
}
class FilterModel {
  type;
  operation;
  queryType;
  values;
  constructor(type, operation, queryType, values) {
    this.type = type;
    this.operation = operation;
    this.queryType = queryType;
    this.values = values;
    this.values = values ? values : [];
  }
  clear() {
    this.values = [];
  }
  getModelValues() {
    return this.values?.map(v => {
      return v.value;
    });
  }
}
class FilterConstraint {
  params = {
    param_dict: {}
  };
  isEmpty() {
    return Object.keys(this.params?.param_dict).length === 0;
  }
  makeConstraint(name, operation, values, type = FilterOperationType.string) {
    this.params.param_dict[name] = {
      operation,
      values,
      type
    };
  }
  makeNumConstraint(name, model) {
    const values = model.getModelValues();
    this.makeConstraint(name, model.operation.value, values, FilterOperationType.number);
  }
  makeTextConstraint(name, model) {
    let values = model.getModelValues()?.map(val => {
      // Clean up the val if needed:
      const cleanVal = String(val).replace(/&/g, '%26').replace(/</g, '%3C').replace(/>/g, '%3E').replace(/'/g, '%27').replace(/;/g, '%3B');
      // CONTAINS, EQUALS , NEQ, Starts With, Ends With
      // 'contains', 'equals', 'notEquals', 'startsWith' and 'endsWith'.
      switch (model.operation.value) {
        case FilterOperationValue.contains:
          return '.*' + cleanVal + '.*';
        // case FilterOperationValue.notcontains:
        //     return '^((?!' + cleanVal + ').)*$';
        case FilterOperationValue.starts_with:
          return '^' + cleanVal + '.*';
        case FilterOperationValue.ends_with:
          return '.*' + cleanVal + '$';
      }
      return cleanVal;
    });
    let operation = model.operation.value;
    switch (model.operation.value) {
      case FilterOperationValue.contains:
      case FilterOperationValue.starts_with:
      case FilterOperationValue.ends_with:
        operation = FilterOperationValue.regex;
        values = [values.join('|')];
        break;
      case FilterOperationValue.notcontains:
        operation = FilterOperationValue.regex;
        values = ['^((?!' + values.join('|') + ').)*$'];
        // values = '/^(?:(?!'+val+').)*$/;'
        break;
    }
    this.makeConstraint(name, operation, values, FilterOperationType.string);
  }
  makeBoolConstraint(name, model) {
    const values = model.getModelValues();
    this.makeConstraint(name, model.operation.value, values, FilterOperationType.bool);
  }
  makeSetConstraint(name, model) {
    const values = model.getModelValues();
    this.makeConstraint(name, model.operation.value, values, FilterOperationType.set);
  }
  makeMenuSetConstraint(name, model) {
    const values = model.getModelValues();
    this.makeConstraint(name, model.operation.value, values, FilterOperationType.string);
  }
  makeDateConstraint(name, model) {
    const values = model.getModelValues();
    const beginDate = values.length > 0 ? new Date(values[0]) : null;
    const endDate = values.length > 1 ? new Date(values[1]) : null;
    let bDateString;
    let eDateString;
    switch (model.operation.value) {
      case FilterOperationValue.eq:
        bDateString = this.getISODateTime(beginDate, 0, 0, 0);
        eDateString = this.getISODateTime(beginDate, 23, 59, 59);
        this.makeConstraint(name, FilterOperationValue.range, [bDateString, eDateString], FilterOperationType.date);
        break;
      case FilterOperationValue.neq:
        bDateString = this.getISODateTime(beginDate, 0, 0, 0);
        eDateString = this.getISODateTime(beginDate, 23, 59, 59);
        this.makeConstraint(name, FilterOperationValue.lte, [bDateString], FilterOperationType.date);
        this.makeConstraint(name, FilterOperationValue.gte, [eDateString], FilterOperationType.date);
        break;
      case FilterOperationValue.lt:
        bDateString = this.getISODateTime(beginDate, 0, 0, 0);
        this.makeConstraint(name, FilterOperationValue.lt, [bDateString], FilterOperationType.date);
        break;
      case FilterOperationValue.lte:
        bDateString = this.getISODateTime(beginDate, 23, 59, 59);
        this.makeConstraint(name, FilterOperationValue.lte, [bDateString], FilterOperationType.date);
        break;
      case FilterOperationValue.gt:
        bDateString = this.getISODateTime(beginDate, 23, 59, 59);
        this.makeConstraint(name, FilterOperationValue.gt, [bDateString], FilterOperationType.date);
        break;
      case FilterOperationValue.gte:
        bDateString = this.getISODateTime(beginDate, 0, 0, 0);
        this.makeConstraint(name, FilterOperationValue.gte, [bDateString], FilterOperationType.date);
        break;
      case FilterOperationValue.range:
        bDateString = this.getISODateTime(beginDate, 0, 0, 0);
        eDateString = this.getISODateTime(endDate, 23, 59, 59);
        this.makeConstraint(name, FilterOperationValue.range, [bDateString, eDateString], FilterOperationType.date);
        break;
      case FilterOperationValue.o_range:
        beginDate.setDate(beginDate.getDate() + 1);
        endDate.setDate(endDate.getDate() - 1);
        bDateString = this.getISODateTime(beginDate, 0, 0, 0);
        eDateString = this.getISODateTime(endDate, 23, 59, 59);
        this.makeConstraint(name, FilterOperationValue.range, [bDateString, eDateString], FilterOperationType.date);
        break;
    }
  }
  makeTimeConstraint(name, model) {
    const values = model.getModelValues();
    this.makeConstraint(name, model.operation.value, values, FilterOperationType.string);
  }
  makeDateTimeConstraint(name, model) {
    const values = model.getModelValues();
    this.makeConstraint(name, model.operation.value, values, FilterOperationType.datetime);
  }
  getISODateTime(date, hours, min, sec) {
    if (date === undefined || date === null) {
      return null;
    }
    date.setUTCHours(hours, min, sec);
    return date.toISOString();
  }
}
class FilterModelDict {}
function isMdlValueObject(object) {
  return Object.prototype.hasOwnProperty.call(object, "#value");
}
function isMdlBaseObject(object) {
  return object !== undefined && object !== null && Object.prototype.hasOwnProperty.call(object, "#value") && Object.prototype.hasOwnProperty.call(object, "@id") && Object.prototype.hasOwnProperty.call(object, "@name");
}
var ZappsmithAttachmentType;
(function (ZappsmithAttachmentType) {
  ZappsmithAttachmentType["document"] = "document";
  ZappsmithAttachmentType["image"] = "image";
  ZappsmithAttachmentType["video"] = "video";
  ZappsmithAttachmentType["youtube"] = "youtube";
})(ZappsmithAttachmentType || (ZappsmithAttachmentType = {}));
class BaseZappsmithObject {
  _rawObject;
  _id;
  _version;
  primary_facility;
  primary_facility_key;
  primary_user_key;
  created_when;
  updated_when;
  updated_when_date;
  PrimaryUserName;
  constructor(rawObject) {
    this._rawObject = rawObject;
    this._id = rawObject._id;
    this._version = rawObject._version;
    this.primary_facility = CommonFunctions.baseObject(rawObject.primary_facility?.key, rawObject.primary_facility?.id, rawObject.primary_facility?.name);
    this.primary_facility_key = rawObject.primary_facility_key;
    this.primary_user_key = rawObject.primary_user_key;
    this.created_when = rawObject.created_when;
    this.updated_when = rawObject.updated_when;
    this.updated_when_date = new Date(this.updated_when);
    const tracking_info = rawObject.tracking_info;
    const trackingInfoPrimaryUser = tracking_info?.find(tracking_info => {
      return tracking_info?.user?.key === this.primary_user_key;
    });
    this.PrimaryUserName = trackingInfoPrimaryUser?.user?.name;
  }
  static reduceRawObjects(type, rawObjects, additionalParams) {
    return rawObjects?.map(rawObject => {
      return new type(rawObject, additionalParams);
    });
  }
}
var AppEnvironment;
(function (AppEnvironment) {
  AppEnvironment["local"] = "local";
  AppEnvironment["test"] = "test";
  AppEnvironment["dev"] = "dev";
  AppEnvironment["uat"] = "uat";
  AppEnvironment["prod"] = "prod";
})(AppEnvironment || (AppEnvironment = {}));
const APP_ENVIRONMENT_OPTIONS = new InjectionToken('APP_ENVIRONMENT_OPTIONS');
class AttachmentLink {
  AttachmentLink;
}
;
class ExternalUrl {
  Url;
  Title;
}
;
const SelectedCommunityGroupRecipientList = CommonFunctions.baseObject(9, '9', 'Select Community Groups');
const UNDEFINED_FILTER_STRING = '__NONE__';
const ATTACHMENT_API = 'attachment';
class ZappsmithWebServiceBaseParams {
  _postObject = {};
  get postObject() {
    return this._postObject;
  }
  _query = {};
  get query() {
    return this._query;
  }
  get parmDict() {
    return this.query.param_dict;
  }
  get orParmDict() {
    return this.query.param_dict;
  }
  constructor(query) {
    if (query) {
      this.setQuery(query);
    }
  }
  setQuery(query) {
    this._query = query;
  }
  setParamDictValue(key, filter) {
    if (!this.query.param_dict) {
      this.query.param_dict = {};
    }
    this.parmDict[key] = filter;
  }
  removeParamDictValue(key) {
    delete this.parmDict[key];
  }
  setParamDictOrValue(key, filter) {
    if (!this.query.param_dict) {
      this.query.param_dict = {
        $or: []
      };
    }
    if (!this.orParmDict?.$or) {
      this.orParmDict.$or = [];
    }
    const parmDictFilter = {};
    parmDictFilter[key] = filter;
    this.orParmDict.$or.push(parmDictFilter);
  }
  setOrderBy(orderBy) {
    OrderByOperations.setOrderBy(this.query, orderBy);
  }
  setPostObjectValue(key, postObject, stringify) {
    if (stringify) {
      this._postObject[key] = JSON.stringify(postObject);
    } else {
      this._postObject[key] = postObject;
    }
  }
  setPostDocument(postObject) {
    this.setPostObjectValue('document', postObject, true);
  }
}
class ZappsmithWebServiceBoardParams extends ZappsmithWebServiceBaseParams {
  _boardName;
  get boardName() {
    return this._boardName;
  }
  _zappName;
  get zappName() {
    return this._zappName;
  }
  constructor(zappName, boardName, query) {
    super(query);
    this._boardName = boardName;
    this._zappName = zappName;
  }
}
class ZappsmithWebServiceApiParams extends ZappsmithWebServiceBaseParams {
  _api;
  get api() {
    return this._api;
  }
  constructor(api, query) {
    super(query);
    this._api = api;
  }
}
class QueryParamsOperations {
  static baseUrl;
  static apiPath = '/api/';
  static apiBoardPath = this.apiPath + 'zapp/';
  static getFileUrl(attachment, params, primary_facility_key) {
    const apiBoardPath = this.apiBoardPath + params.zappName + '.' + params.boardName;
    const attachmentKey = CommonFunctions.getMdlObjectValue(attachment);
    const attachmentName = CommonFunctions.getMdlObjectName(attachment);
    const doc = {
      _id: attachmentKey,
      primary_facility_key
    };
    let attachmentUrl = this.baseUrl + apiBoardPath;
    attachmentUrl += '/attachment?key=' + attachmentKey;
    attachmentUrl += '&document=' + JSON.stringify(doc);
    attachmentUrl += '&filename=' + attachmentName;
    return attachmentUrl;
  }
}
class OrderByOperations {
  static getOrderByValue(sortDirection) {
    switch (sortDirection) {
      case 'asc':
        return 1;
      case 'desc':
        return -1;
      default:
        return null;
    }
  }
  static setOrderBy(queryParams, orderBy) {
    if (!queryParams.order_by) {
      queryParams.order_by = {};
    }
    Object.entries(orderBy).forEach(([key, value]) => {
      const orderByValue = OrderByOperations.getOrderByValue(value);
      orderByValue !== null ? queryParams.order_by[key] = orderByValue : null;
    });
  }
  static deleteOrderBy(queryParams) {
    delete queryParams.order_by;
  }
}
class ZappsmithWebServiceFilterOperations {
  static removeFilter(params, path) {
    const typeKey = CommonFunctions.stringValue(path);
    params.removeParamDictValue(typeKey);
  }
  static addFilter(params, filter_key, filter, orFilter) {
    if (!orFilter) {
      params.setParamDictValue(filter_key, filter);
    } else {
      params.setParamDictOrValue(filter_key, filter);
    }
  }
  static addValueFilter(params, path, filter, orFilter) {
    const filter_key = CommonFunctions.stringValue(path);
    this.addFilter(params, filter_key, filter, orFilter);
  }
  static getParamDictFilter(paramDict, key) {
    return paramDict[key];
  }
  static getParamDictValueFilter(paramDict, path) {
    const valueKey = CommonFunctions.stringValue(path);
    return this.getParamDictFilter(paramDict, valueKey);
  }
  static setParamDictFilter(paramDict, key, filter) {
    paramDict[key] = filter;
  }
  static setParamDictValueFilter(paramDict, path, filter) {
    const valueKey = CommonFunctions.stringValue(path);
    this.setParamDictFilter(paramDict, valueKey, filter);
  }
  static setParamDictOrFilter(paramDict, path, filter) {
    const childParamDict = {};
    this.setParamDictFilter(childParamDict, path, filter);
    paramDict.$or.push(childParamDict);
  }
  static setParamDictOrValueFilter(paramDict, path, filter) {
    const valueKey = CommonFunctions.stringValue(path);
    const childParamDict = {};
    this.setParamDictFilter(childParamDict, valueKey, filter);
    paramDict.$or.push(childParamDict);
  }
  static setParamDictKeyFilter(paramDict, key, notEqual) {
    let operation;
    if (notEqual) {
      operation = Array.isArray(key) ? FilterOperationValue.nin : FilterOperationValue.neq;
    } else {
      operation = Array.isArray(key) ? FilterOperationValue.in : FilterOperationValue.eq;
    }
    const keyFilter = {
      type: FilterOperationType.oid,
      operation,
      values: Array.isArray(key) ? key : [key]
    };
    this.setParamDictFilter(paramDict, '_id', keyFilter);
  }
  static removeParamDictFilter(paramDict, key) {
    delete paramDict[key];
  }
  static removeParamDictFilterValue(paramDict, path) {
    const valueKey = CommonFunctions.stringValue(path);
    this.removeParamDictFilter(paramDict, valueKey);
  }
  static removeParamDictKeyFilter(paramDict) {
    this.removeParamDictFilter(paramDict, '_id');
  }
}
const API_INTERCEPTOR_IGNORE = new HttpContextToken(() => false);
class APIInterceptor {
  baseUrl;
  constructor(injector) {
    const appEnvironmentOptions = injector.get(APP_ENVIRONMENT_OPTIONS, null, {
      optional: true
    });
    this.baseUrl = appEnvironmentOptions?.baseUrl ? appEnvironmentOptions?.baseUrl : '';
    QueryParamsOperations.baseUrl = this.baseUrl;
  }
  intercept(req, next) {
    if (req.method !== 'HEAD' && req.context.get(API_INTERCEPTOR_IGNORE) !== true) {
      const apiReq = req.clone({
        url: `${this.baseUrl}${req.url}`
      });
      return next.handle(apiReq);
    } else {
      return next.handle(req);
    }
  }
  static ɵfac = function APIInterceptor_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || APIInterceptor)(i0.ɵɵinject(i0.Injector));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: APIInterceptor,
    factory: APIInterceptor.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(APIInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.Injector
  }], null);
})();
class ZappsmithWebService {
  http;
  constructor(http) {
    this.http = http;
  }
  get(getPath, params) {
    return this.httpGet(getPath, params);
  }
  getBoard(params) {
    const apiBoardPath = QueryParamsOperations.apiBoardPath + params.zappName + '.' + params.boardName;
    return this.httpGet(apiBoardPath, params);
  }
  getBoardByFacilityKey(params, primary_facility_key) {
    const facilityFilter = {
      operation: FilterOperationValue.eq,
      type: FilterOperationType.string,
      values: [primary_facility_key]
    };
    ZappsmithWebServiceFilterOperations.addFilter(params, 'primary_facility_key', facilityFilter);
    return this.getBoard(params);
  }
  getBoardByUserKey(params, primary_user_key) {
    const userFilter = {
      operation: FilterOperationValue.eq,
      type: FilterOperationType.string,
      values: [primary_user_key]
    };
    ZappsmithWebServiceFilterOperations.addFilter(params, 'primary_user_key', userFilter);
    return this.getBoard(params);
  }
  getApi(params) {
    const apiPath = QueryParamsOperations.apiPath + params.api;
    return this.httpGet(apiPath, params);
  }
  httpGet(path, params) {
    const getOptions = {
      params: this.getHttpParams(params)
    };
    return new Promise((resolve, reject) => {
      this.http.get(path, getOptions).subscribe({
        next: result => {
          result ? resolve(result) : reject(result);
        },
        error: result => {
          reject(result);
        }
      });
    });
  }
  getHttpParams(params) {
    let httpParams = new HttpParams();
    if (params instanceof ZappsmithWebServiceBaseParams) {
      if (params.query) {
        Object.entries(params.query).forEach(([key, value]) => {
          if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
            httpParams = httpParams.append(key, value);
          } else {
            const stringParams = JSON.stringify(value);
            httpParams = httpParams.append(key, stringParams);
          }
        });
      } else {
        return undefined;
      }
    } else {
      Object.entries(params).forEach(([key, value]) => {
        if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
          httpParams = httpParams.append(key, value);
        } else {
          const stringParams = JSON.stringify(value);
          httpParams = httpParams.append(key, stringParams);
        }
      });
    }
    return httpParams;
  }
  postBoard(params) {
    const apiBoardPath = QueryParamsOperations.apiBoardPath + params.zappName + '.' + params.boardName;
    return this.httpPost(apiBoardPath, params);
  }
  postBoardAttachment(params) {
    const apiBoardPath = QueryParamsOperations.apiBoardPath + params.zappName + '.' + params.boardName + '/' + ATTACHMENT_API;
    return this.httpPost(apiBoardPath, params);
  }
  post(postPath, params) {
    return this.httpPost(postPath, params);
  }
  postApi(params) {
    const apiPath = QueryParamsOperations.apiPath + params.api;
    return this.httpPost(apiPath, params);
  }
  httpPost(path, params) {
    const body = this.getHttpFormData(params);
    return new Promise((resolve, reject) => {
      this.http.post(path, body).subscribe({
        next: result => {
          result ? resolve(result) : reject(result);
        },
        error: result => {
          reject(result);
        }
      });
    });
  }
  delete(deletePath, params) {
    return this.httpDelete(deletePath, params);
  }
  deleteBoard(params) {
    const apiBoardPath = QueryParamsOperations.apiBoardPath + params.zappName + '.' + params.boardName;
    return this.httpDelete(apiBoardPath, params);
  }
  deleteBoardAttachment(params) {
    const apiBoardPath = QueryParamsOperations.apiBoardPath + params.zappName + '.' + params.boardName + '/' + ATTACHMENT_API;
    return this.httpDelete(apiBoardPath, params);
  }
  httpDelete(path, params) {
    const deleteOptions = {
      body: this.getHttpFormData(params)
    };
    return new Promise((resolve, reject) => {
      this.http.delete(path, deleteOptions).subscribe({
        next: result => {
          result ? resolve(result) : reject(result);
        },
        error: result => {
          reject(result);
        }
      });
    });
  }
  getHttpFormData(params) {
    const formData = new FormData();
    if (params instanceof ZappsmithWebServiceBaseParams) {
      Object.entries(params.postObject).forEach(([k, v]) => formData.append(k, v));
    } else {
      Object.entries(params).forEach(([k, v]) => formData.append(k, v));
    }
    return formData;
  }
  static ɵfac = function ZappsmithWebService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ZappsmithWebService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ZappsmithWebService,
    factory: ZappsmithWebService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ZappsmithWebService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }], null);
})();

// TODO Possible update in the future to only refresh certain stores
const CHANGE_ACCOUNT_API = 'change_account';
class ZappsmithStoresService {
  zappsmithWebService;
  appAuthService;
  appBaseHref;
  constructor(zappsmithWebService, appAuthService, appBaseHref) {
    this.zappsmithWebService = zappsmithWebService;
    this.appAuthService = appAuthService;
    this.appBaseHref = appBaseHref;
  }
  switchCommunity(facility_id, redirectUrl) {
    const postParams = new ZappsmithWebServiceApiParams(CHANGE_ACCOUNT_API);
    postParams.setPostObjectValue('facility_id', facility_id);
    this.zappsmithWebService.postApi(postParams).then(() => {
      this.reloadComponentStores(redirectUrl);
    }, response => {
      alert('You don\'t have access to this community');
      this.appAuthService.logout();
    });
  }
  reloadComponentStores(redirectUrl) {
    window.location.replace(redirectUrl ? redirectUrl : this.appBaseHref);
  }
  static ɵfac = function ZappsmithStoresService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ZappsmithStoresService)(i0.ɵɵinject(ZappsmithWebService), i0.ɵɵinject(i2.AppAuthService), i0.ɵɵinject(APP_BASE_HREF));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ZappsmithStoresService,
    factory: ZappsmithStoresService.ɵfac,
    providedIn: ZappsmithNgxCoreModule
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ZappsmithStoresService, [{
    type: Injectable,
    args: [{
      providedIn: ZappsmithNgxCoreModule
    }]
  }], () => [{
    type: ZappsmithWebService
  }, {
    type: i2.AppAuthService
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [APP_BASE_HREF]
    }]
  }], null);
})();
class FileUploadService {
  zappsmithWebService;
  constructor(zappsmithWebService) {
    this.zappsmithWebService = zappsmithWebService;
  }
  uploadFiles(userCredentials, boardParams, files) {
    return new Promise((resolve, reject) => {
      const uploadedFiles = [];
      const filesStack = files.concat();
      const postNextFile = () => {
        const file = filesStack.pop();
        const postFileParams = new ZappsmithWebServiceBoardParams(boardParams.zappName, boardParams.boardName);
        const fileDocument = {
          primary_user_key: userCredentials.userKey,
          primary_facility_key: userCredentials.facilityKey
        };
        postFileParams.setPostDocument(fileDocument);
        let filename = file?.name?.replace(/\.heic$/i, '.png');
        postFileParams.setPostObjectValue('filename', filename);
        postFileParams.setPostObjectValue('file', file);
        this.zappsmithWebService.postBoardAttachment(postFileParams).then(result => {
          uploadedFiles.push(CommonFunctions.baseObject(result?.key, undefined, filename));
          filesStack.length > 0 ? postNextFile() : resolve(uploadedFiles);
        }, reject);
      };
      postNextFile();
    });
  }
  deleteFiles(userCredentials, boardParams, files) {
    return new Promise((resolve, reject) => {
      const filesStack = files.concat();
      const postNextFile = () => {
        const file = filesStack.pop();
        const deleteFileParams = new ZappsmithWebServiceBoardParams(boardParams.zappName, boardParams.boardName);
        deleteFileParams.setPostObjectValue('key', CommonFunctions.getMdlObjectValue(file));
        const fileDocument = {
          primary_user_key: userCredentials.userKey,
          primary_facility_key: userCredentials.facilityKey
        };
        deleteFileParams.setPostDocument(fileDocument);
        this.zappsmithWebService.deleteBoardAttachment(deleteFileParams).then(result => {
          filesStack.length > 0 ? postNextFile() : resolve();
        }, reject);
      };
      postNextFile();
    });
  }
  static ɵfac = function FileUploadService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || FileUploadService)(i0.ɵɵinject(ZappsmithWebService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: FileUploadService,
    factory: FileUploadService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileUploadService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ZappsmithWebService
  }], null);
})();
class MediaService {
  media;
  isMobile;
  constructor(media, breakpointObserver) {
    this.media = media;
    // Non Mobile Matches
    breakpointObserver.observe(nonMobileBreakpoints).subscribe(result => {
      if (result.matches) {
        this.isMobile = false;
      }
    });
    // Mobile Matches
    breakpointObserver.observe(mobileBreakpoints).subscribe(result => {
      if (result.matches) {
        this.isMobile = true;
      }
    });
  }
  static ɵfac = function MediaService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MediaService)(i0.ɵɵinject(i1$1.MediaMatcher), i0.ɵɵinject(i1$1.BreakpointObserver));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: MediaService,
    factory: MediaService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MediaService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$1.MediaMatcher
  }, {
    type: i1$1.BreakpointObserver
  }], null);
})();
const nonMobileBreakpoints = [Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge];
const mobileBreakpoints = [Breakpoints.XSmall];
class ThemeManagerService {
  themeService;
  PrimaryColor;
  AcccentColor;
  constructor(themeService) {
    this.themeService = themeService;
  }
  loadThemeProfile(themeProfile) {
    if (themeProfile) {
      this.PrimaryColor = themeProfile.PrimaryColor;
      this.AcccentColor = themeProfile.AccentColor;
      this.themeService.setColors({
        primary: themeProfile.PrimaryColor,
        accent: themeProfile.AccentColor
      }, {
        autoAccent: themeProfile.AccentColor ? false : true
      });
    }
  }
  static ɵfac = function ThemeManagerService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ThemeManagerService)(i0.ɵɵinject(i1$2.ThemeService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ThemeManagerService,
    factory: ThemeManagerService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeManagerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$2.ThemeService
  }], null);
})();
class FilterService {
  append_order_by_constraint(params, sortModel) {
    if (!!sortModel && sortModel.length > 0) {
      let has_any = false;
      const order_by = {};
      // tslint:disable-next-line:prefer-for-of
      for (let k = 0; k < sortModel.length; k++) {
        has_any = true;
        const col_name = sortModel[k].name;
        order_by[col_name] = OrderByOperations.getOrderByValue(sortModel[k].sort);
      }
      if (has_any) {
        params.order_by = order_by;
      }
    }
  }
  add_constraint(fs, fld_name, filterModel) {
    switch (filterModel.type) {
      case FilterOperationType.text:
        fs.makeTextConstraint(fld_name, filterModel);
        break;
      case FilterOperationType.number:
        fs.makeNumConstraint(fld_name, filterModel);
        break;
      case FilterOperationType.date:
        fs.makeDateConstraint(fld_name, filterModel);
        break;
      case FilterOperationType.time:
        fs.makeTimeConstraint(fld_name, filterModel);
        break;
      case FilterOperationType.datetime:
        fs.makeDateTimeConstraint(fld_name, filterModel);
        break;
      case FilterOperationType.bool:
        fs.makeBoolConstraint(fld_name, filterModel);
        break;
      case FilterOperationType.set:
        fs.makeSetConstraint(fld_name, filterModel);
        break;
      case FilterOperationType.menuset:
        fs.makeMenuSetConstraint(fld_name, filterModel);
        break;
      default:
      // invalid type ignore it
    }
  }
  get_filter_constraint(filterModel, fieldPath) {
    const fs = new FilterConstraint();
    this.add_constraint(fs, fieldPath, filterModel);
    return fs;
  }
  static ɵfac = function FilterService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || FilterService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: FilterService,
    factory: FilterService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilterService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class BaseZappsmithBoard extends BaseZappsmithObject {
  Id;
  Name;
  Link;
  constructor(rawObject, idPath, namePath) {
    super(rawObject);
    this.Id = CommonFunctions.resolvePathValue(idPath, rawObject);
    this.Name = CommonFunctions.resolvePathValue(namePath, rawObject);
    this.Link = CommonFunctions.baseObject(this._id, this.Id, this.Name);
  }
  convertKeyList(keyObjects) {
    let keys = keyObjects?.map(keyObject => {
      if (typeof keyObject === 'string') {
        return keyObject;
      } else {
        return CommonFunctions.getMdlObjectValue(keyObject);
      }
    });
    // Remove duplicate keys
    keys = [...new Set(keys)];
    return keys;
  }
}
class BaseZappsmithStoreState {
  errorState;
}
class BaseZappsmithStorePermissions {
  userCredentials;
  modulePermissionLevel;
  _viewPermissionLevel;
  get viewPermissionLevel() {
    return this._viewPermissionLevel;
  }
  set viewPermissionLevel(value) {
    this._viewPermissionLevel = value;
  }
  _addPermissionLevel;
  get addPermissionLevel() {
    return this._addPermissionLevel;
  }
  set addPermissionLevel(value) {
    this._addPermissionLevel = value;
  }
  _editPermissionLevel;
  get editPermissionLevel() {
    return this._editPermissionLevel;
  }
  set editPermissionLevel(value) {
    this._editPermissionLevel = value;
  }
  _deletePermissionLevel;
  get deletePermissionLevel() {
    return this._deletePermissionLevel;
  }
  set deletePermissionLevel(value) {
    this._deletePermissionLevel = value;
  }
  constructor(zappName, boardName, userCredentials) {
    this.userCredentials = userCredentials;
    const appPermissionString = zappName;
    const appBoardPermissionString = boardName ? appPermissionString + ':' + boardName : appPermissionString;
    this.modulePermissionLevel = this.getPermissionLevel(appPermissionString, appBoardPermissionString, 'ModuleAbility');
    this.viewPermissionLevel = this.getPermissionLevel(appPermissionString, appBoardPermissionString, 'SearchLevel');
    this.addPermissionLevel = this.getPermissionLevel(appPermissionString, appBoardPermissionString, 'AddLevel');
    this.editPermissionLevel = this.getPermissionLevel(appPermissionString, appBoardPermissionString, 'UpdateLevel');
    this.deletePermissionLevel = this.getPermissionLevel(appPermissionString, appBoardPermissionString, 'DeleteLevel');
  }
  getPermissionLevel(appPermissionString, appBoardPermissionString, permission) {
    const appBoardPermission = appBoardPermissionString + ':' + permission;
    const appPermission = appPermissionString + ':' + permission;
    const appBGHasNoStandardsFormatPermission = appPermissionString + permission;
    if (this?.userCredentials?.userPermissions[appBoardPermission]) {
      return this?.userCredentials?.userPermissions[appBoardPermission];
    } else if (this?.userCredentials?.userPermissions[appPermission]) {
      return this?.userCredentials?.userPermissions[appPermission];
    } else if (this?.userCredentials?.userPermissions[appBGHasNoStandardsFormatPermission]) {
      return this?.userCredentials?.userPermissions[appBGHasNoStandardsFormatPermission];
    } else {
      return undefined;
    }
  }
  canAccessModule(permissionLevelCheck) {
    return this.hasPermission(this.modulePermissionLevel, permissionLevelCheck);
  }
  canView(permissionLevelCheck) {
    return this.hasPermission(this.viewPermissionLevel, permissionLevelCheck);
  }
  canAdd(permissionLevelCheck) {
    return this.hasPermission(this.addPermissionLevel, permissionLevelCheck);
  }
  canEdit(permissionLevelCheck) {
    return this.hasPermission(this.editPermissionLevel, permissionLevelCheck);
  }
  canDelete(permissionLevelCheck) {
    return this.hasPermission(this.deletePermissionLevel, permissionLevelCheck);
  }
  hasOnePermission(permissionLevel) {
    return this.userCredentials?.isAdmin || this.canView(permissionLevel) || this.canAdd(permissionLevel) || this.canEdit(permissionLevel) || this.canDelete(permissionLevel);
  }
  hasPermission(permissionLevel, permissionLevelCheck) {
    return this.userCredentials?.isAdmin || (permissionLevelCheck > 0 ? permissionLevel >= permissionLevelCheck : permissionLevel > 0);
  }
}
class BaseZappsmithComponentStore extends ComponentStore {
  errorState$ = this.select(state => state.errorState);
  convertGetBoardResults(type, result, additionalParams) {
    const totalCount = result.count ? result.count : undefined;
    const rawZappsmithObjects = Array.isArray(result) ? result : result?.records;
    const reducedResults = BaseZappsmithObject.reduceRawObjects(type, rawZappsmithObjects, additionalParams);
    return {
      totalCount,
      data: reducedResults
    };
  }
  convertKeyList(keyObjects) {
    let keys = keyObjects?.map(keyObject => {
      if (typeof keyObject === 'string') {
        return keyObject;
      } else {
        return CommonFunctions.getMdlObjectValue(keyObject);
      }
    });
    // Remove duplicate keys
    keys = [...new Set(keys)];
    return keys;
  }
  convertBoardObjectsToKeyList(baseZappsmithObjects) {
    return this.convertKeyList(baseZappsmithObjects?.map(bso => {
      return bso?._id;
    }));
  }
}
class BaseZappsmithBoardComponentStore extends BaseZappsmithComponentStore {
  boardParams;
  permissions;
  userCredentials;
  get zappName() {
    return this.boardParams.zappName;
  }
  get boardName() {
    return this.boardParams.boardName;
  }
  constructor(boardParams, userCredentials) {
    super();
    this.boardParams = boardParams;
    this.userCredentials = userCredentials;
    this.permissions = new BaseZappsmithStorePermissions(this.zappName, this.boardName, userCredentials);
  }
  canView(boardObject) {
    return this.hasFacilityAccess(boardObject.primary_facility_key, this.permissions.viewPermissionLevel);
  }
  canEdit(boardObject) {
    return this.hasFacilityAccess(boardObject.primary_facility_key, this.permissions.editPermissionLevel);
  }
  canDelete(boardObject) {
    return this.hasFacilityAccess(boardObject.primary_facility_key, this.permissions.deletePermissionLevel);
  }
  hasFacilityAccess(facilityKey, permissionLevel) {
    if (this.userCredentials?.isAdmin || permissionLevel == 9) {
      return true;
    } else if (permissionLevel == 1 || permissionLevel == 2) {
      return this.userCredentials?.facilityKey == facilityKey;
    } else if (permissionLevel == 3 || permissionLevel == 4) {
      return this.hasFacilityAsChild(facilityKey, permissionLevel == 3); // include base on 3 but not 4
    } else if (permissionLevel == 8) {
      return this.hasFacilityAsTenant(facilityKey);
    } else {
      return false;
    }
  }
  hasFacilityAsChild(facilityKey, includeBase) {
    let retVal = false;
    if (includeBase && this.userCredentials?.facilityKey == facilityKey) {
      retVal = true;
    } else {
      this.userCredentials?.childFacilityKeys?.forEach(childFacilityKey => {
        if (childFacilityKey === facilityKey) {
          retVal = true;
        }
      });
    }
    return retVal;
  }
  hasFacilityAsTenant(facilityKey) {
    let retVal = false;
    this.userCredentials?.tenantFacilitiesKeys?.forEach(tenantFacilitiesKey => {
      if (tenantFacilitiesKey === facilityKey) {
        retVal = true;
      }
    });
    return retVal;
  }
}
class User extends BaseZappsmithObject {
  user;
  isAdmin;
  permissions;
  facility;
  facility_key;
  facilityLink;
  account_facilities;
  accountFacilityKeys;
  child_facilities;
  childFacilityKeys;
  tenant_facilities;
  tenantFacilityKeys;
  activeCommunityId;
  activeCommunityIds;
  constructor(rawUser) {
    super(rawUser);
    this.user = rawUser.user;
    this._id = this.user?.key;
    this.isAdmin = CommonFunctions.resolvePath('is_admin', rawUser);
    this.permissions = CommonFunctions.resolvePath('permissions', rawUser);
    this.facility = rawUser.facility;
    this.facility_key = CommonFunctions.resolvePath('facility.key', rawUser);
    this.facilityLink = CommonFunctions.baseObject(this.facility?.key, this.facility?.id, this.facility?.name);
    this.account_facilities = rawUser.account_facilities;
    this.accountFacilityKeys = this.account_facilities?.map(accountFacility => {
      return accountFacility?.key;
    });
    this.child_facilities = rawUser.child_facilities;
    this.childFacilityKeys = this.child_facilities?.map(child_facility => {
      return child_facility.key;
    });
    this.tenant_facilities = rawUser.tenant_facilities;
    this.tenantFacilityKeys = this.tenant_facilities?.map(tenant_facility => {
      return tenant_facility.key;
    });
    this.activeCommunityIds = [];
    if (rawUser?.facility?.id) {
      this.activeCommunityId = rawUser?.facility?.id;
      this.activeCommunityIds.push(rawUser?.facility?.id);
    }
    if (rawUser?.account_facilities?.length > 0) {
      this.activeCommunityIds = this.activeCommunityIds.concat(this.account_facilities.map(account_facility => {
        return account_facility.id;
      }));
    }
  }
}
class UserStore extends BaseZappsmithComponentStore {
  appAuthService;
  _activeUser;
  get activeUser() {
    return this._activeUser;
  }
  _activeUserCredentials;
  get activeUserCredentials() {
    return this._activeUserCredentials;
  }
  activeCommunityIds$ = this.select(state => state?.activeCommunityIds);
  constructor(appAuthService) {
    super();
    this.appAuthService = appAuthService;
    this.appAuthService.activeUser$.subscribe(activeUser => {
      if (activeUser) {
        this.updateActiveUser(activeUser);
      }
    });
  }
  updateActiveUser(rawUser) {
    this._activeUser = new User(rawUser);
    this._activeUserCredentials = {
      userKey: this._activeUser._id,
      isAdmin: this._activeUser.isAdmin,
      facilityKey: this._activeUser.facility_key,
      childFacilityKeys: this._activeUser.childFacilityKeys,
      tenantFacilitiesKeys: this._activeUser.tenantFacilityKeys,
      userPermissions: this._activeUser.permissions
    };
    this.setState({
      activeCommunityIds: this._activeUser.activeCommunityIds
    });
  }
  getActiveUserFilter() {
    const activeUserFilter = {
      operation: FilterOperationValue.eq,
      type: FilterOperationType.string,
      values: [this.activeUser?._id]
    };
    return activeUserFilter;
  }
  static ɵfac = function UserStore_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || UserStore)(i0.ɵɵinject(i2.AppAuthService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: UserStore,
    factory: UserStore.ɵfac,
    providedIn: ZappsmithNgxCoreModule
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserStore, [{
    type: Injectable,
    args: [{
      providedIn: ZappsmithNgxCoreModule
    }]
  }], () => [{
    type: i2.AppAuthService
  }], null);
})();
class CanActivateContinualCommunity {
  ccPermissions;
  constructor(userStore) {
    this.ccPermissions = new BaseZappsmithStorePermissions('ContinualCommunityAccess', '', userStore.activeUserCredentials);
  }
  canActivate(route, state) {
    return this.ccPermissions?.canAccessModule();
  }
  static ɵfac = function CanActivateContinualCommunity_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CanActivateContinualCommunity)(i0.ɵɵinject(UserStore));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: CanActivateContinualCommunity,
    factory: CanActivateContinualCommunity.ɵfac,
    providedIn: ZappsmithNgxCoreModule
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CanActivateContinualCommunity, [{
    type: Injectable,
    args: [{
      providedIn: ZappsmithNgxCoreModule
    }]
  }], () => [{
    type: UserStore
  }], null);
})();

/*
 * Public API Surface of zappsmith-ngx-core
 */

/**
 * Generated bundle index. Do not edit.
 */

export { APIInterceptor, API_INTERCEPTOR_IGNORE, APP_ENVIRONMENT_OPTIONS, ATTACHMENT_API, AppEnvironment, AttachmentLink, BaseZappsmithBoard, BaseZappsmithBoardComponentStore, BaseZappsmithComponentStore, BaseZappsmithObject, BaseZappsmithStorePermissions, BaseZappsmithStoreState, CanActivateContinualCommunity, CommonColorFunctions, CommonDateFunctions, CommonFunctions, CommonRichTextFunctions, ExternalUrl, FileUploadService, FilterConstraint, FilterModel, FilterModelDict, FilterModelValue, FilterOperation, FilterOperationType, FilterOperationValue, FilterOperations, FilterService, MediaService, OrderByOperations, QueryParamsOperations, SelectedCommunityGroupRecipientList, ThemeManagerService, UNDEFINED_FILTER_STRING, User, UserStore, ZappsmithAttachmentType, ZappsmithNgxCoreModule, ZappsmithStoresService, ZappsmithWebService, ZappsmithWebServiceApiParams, ZappsmithWebServiceBaseParams, ZappsmithWebServiceBoardParams, ZappsmithWebServiceFilterOperations, isMdlBaseObject, isMdlValueObject };

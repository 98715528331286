import { Component, HostListener, Inject, Injectable, ViewChild, inject } from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Route, Router, Routes } from '@angular/router';
import { Color, ThemeStoreService, PRIMARY, ThemeService } from '@anvilor/ngx-theming';

import { AppAuthService, ClientConfiguration, ClientConfigurationStore } from '@dicorp/zappsmith-ngx-auth';

import { APP_ENVIRONMENT_OPTIONS, AppEnvironmentOptions, CanActivateContinualCommunity, MdlKeyObject, MediaService, UserStore, ZappsmithStoresService } from "@dicorp/zappsmith-ngx-core";
import { ZappsmithWebService } from '@dicorp/zappsmith-ngx-core';

import { ZappAppStore } from 'src/component-store';

import { PORTAL_MODULE_SERVICES, PortalRoutingServices } from "src/modules";
import { AppRoutingService } from 'src/app';
import { CookieService } from 'ngx-cookie-service';
import { CanActivateTraceRules, TraceRuleService, UserPreferencesService } from 'src/services';

@Component({
    selector: 'zs-toolbar',
    templateUrl: 'toolbar.component.html',
    styleUrls: ['toolbar.component.scss']
})
export class ToolbarComponent {
    @ViewChild('leftMatSideNav') leftMatSideNav: MatSidenav;
    @ViewChild('rightMatSideNav') rightMatSideNav: MatSidenav;

    public themePrimaryColor: Color;

    public clientConfiguration: ClientConfiguration;

    public applicationIconStyle: { [styleKey: string]: any; } = {};

    public activeFacilityId: string;
    public activeFacilityName: string;
    public accountFacilitiesSorted: MdlKeyObject[];

    public isRightSideNavOpen: boolean = false;

    public portalModuleRoutes: Routes = [];

    public routesLoaded: boolean;

    resizingEvent: ResizingEvent = {
        isResizing: false,
        startingCursorX: 0,
        startingWidth: 0,
    };

    public get canSelectNewFacility(): boolean {
        return this.accountFacilitiesSorted.length > 0;
    }

    constructor(
        public titleService: Title,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public mediaService: MediaService,
        private appAuthService: AppAuthService,
        private themeStoreService: ThemeStoreService,
        private themeService: ThemeService,
        private zappsmithStoresService: ZappsmithStoresService,
        public appRoutingService: AppRoutingService,
        public userStore: UserStore,
        public zappAppStore: ZappAppStore,
        clientConfigurationStore: ClientConfigurationStore,
        public canActivateContinualCommunity: CanActivateContinualCommunity,
        public sidenavService: SidenavService,
        public traceRuleService: TraceRuleService,
        public canActivateTraceRules: CanActivateTraceRules,
        private cookieService: CookieService,
        @Inject(APP_ENVIRONMENT_OPTIONS) private appEnvironmentOptions: AppEnvironmentOptions,
        @Inject(PORTAL_MODULE_SERVICES) public portalRoutingServices: PortalRoutingServices) {

        clientConfigurationStore.activeClientConfiguration$.subscribe(activeClientConfiguration => {
            this.clientConfiguration = activeClientConfiguration;
        });

        this.themeService.setColors(
            {
                primary: '#2E6DA4',
                accent: '#64A1D4',
                warn: "#FF1744"
            },
            {
                autoAccent: false
            }
        );

        this.themeStoreService.themeVars$.subscribe(theme => {
            this.themePrimaryColor = theme[PRIMARY].base;
        });

        appRoutingService.routesLoaded$.subscribe(routesLoaded => {
            this.routesLoaded = routesLoaded;
        });

        this.accountFacilitiesSorted = this.userStore?.activeUser?.account_facilities.sort(
            (a, b) => {
                return (a.name as string)?.localeCompare((b.name as string));
            });

        // Add Portal Module Routes
        const portalRoutingPromises: Promise<Route>[] = [];
        this.portalRoutingServices?.forEach(portalRoutingServiceType => {
            const portalRoutingService = inject(portalRoutingServiceType);
            portalRoutingPromises.push(portalRoutingService?.getToolbarRoute());
        });

        Promise.all(portalRoutingPromises).then(portalModuleRoutes => {
            this.portalModuleRoutes = this.portalModuleRoutes.concat(portalModuleRoutes);
        });

        this.traceRuleService.traceRulesActive = this.cookieService.get(TRACE_RULE_COOKIE) === 'true';
    }

    public logout(): void {
        this.appAuthService.logout(this.appEnvironmentOptions.baseUrl);
    }

    public toggleLeftSideNav(): void {
        this.leftMatSideNav?.toggle();
    }

    public toggleRightSideNav(): void {
        this.rightMatSideNav?.toggle();
    }

    public switchFacility(facility_id: string, redirectUrl?: string): void {
        if (facility_id !== this.userStore?.activeUser?.facility?.id) {
            this.zappsmithStoresService.switchCommunity(facility_id, redirectUrl);
        }
    }

    public openCommunicator(): void {
        window.open(window.location.origin + '/communicator', '_blank');
    }

    public navigate(routePath: string[], moduleRoute?: Route): void {
        // Check for openPathInNewWindow
        if (moduleRoute?.data && moduleRoute?.data['openPathInNewWindow']) {
            if (moduleRoute?.data['openPathFromOrigin']) {
                const path = moduleRoute.redirectTo ? moduleRoute.redirectTo : routePath.join('/');
                const origin = this.appEnvironmentOptions.baseUrl ? this.appEnvironmentOptions.baseUrl : window.location.origin;
                window.open(origin + path, '_blank');
            } else {
                this.openNewTab(routePath);
            }
        } else {
            // this.router.navigateByUrl(routePath);
            this.router.navigate(routePath, { queryParams: {} });

            if (this.sidenavService.isLeftSideNavOver) {
                this.leftMatSideNav.toggle(false);
            }
        }
    }

    public openNewTab(routePath: string[]): void {
        // Converts the route into a string that can be used 
        // with the window.open() function
        const url = this.router.serializeUrl(
            this.router.createUrlTree(routePath)
        );

        this.appRoutingService.openPathInNewWindow(url);
    }

    public toggleTraceRules(): void {
        this.traceRuleService.traceRulesActive = !this.traceRuleService.traceRulesActive;
        this.cookieService.set(TRACE_RULE_COOKIE, this.traceRuleService.traceRulesActive.toString());
        this.rightMatSideNav.toggle(false);
    }

    // SideNav resizing events
    startResizing(event: MouseEvent): void {
        this.resizingEvent = {
            isResizing: true,
            startingCursorX: event.clientX,
            startingWidth: this.sidenavService.sidenavWidth,
        };
    }

    /*
    * This method runs when the mouse is moved anywhere in the browser
    */
    @HostListener('window:mousemove', ['$event'])
    updateSidenavWidth(event: MouseEvent) {
        // No need to even continue if we're not resizing
        if (!this.resizingEvent.isResizing) {
            return;
        }

        // 1. Calculate how much mouse has moved on the x-axis
        const cursorDeltaX = event.clientX - this.resizingEvent.startingCursorX;

        // 2. Calculate the new width according to initial width and mouse movement
        const newWidth = this.resizingEvent.startingWidth + cursorDeltaX;

        // 3. Set the new width
        this.sidenavService.setSidenavWidth(newWidth);
    }

    @HostListener('window:mouseup')
    stopResizing() {
        if (this.resizingEvent.isResizing) {
            this.resizingEvent.isResizing = false;
            this.sidenavService.saveSidenavWidth();
        }
    }
}

interface ResizingEvent {
    isResizing: boolean,
    startingCursorX: number,
    startingWidth: number,
}

@Injectable({
    providedIn: 'root',
})
export class SidenavService {
    isLeftSideNavOpen: boolean;

    get leftSideNavMode(): MatDrawerMode {
        return this.toolbarPreferences?.leftSideNavMode;
    };

    get isLeftSidePinned(): boolean {
        return this.toolbarPreferences?.leftSideNavMode === 'side';
    };

    get isLeftSideNavOver(): boolean {
        return this.toolbarPreferences?.leftSideNavMode === 'over';
    };

    get sidenavWidth(): number {
        return parseInt(
            getComputedStyle(document.body).getPropertyValue('--sidenav-width'),
            10
        );
    }

    readonly sidenavMinWidth = 100;
    readonly sidenavMaxWidth = window.innerWidth - 300;

    private lastSetWidth: number;
    private toolbarPreferences: ToolbarPreferences = {};

    // Turn this on to save toolbar preferences in user preferences
    private useToolbarPreferences: boolean = false;

    constructor(private userPreferencesService: UserPreferencesService, private cookieService: CookieService) {

        if (this.useToolbarPreferences) {
            this.userPreferencesService.getToolbarPreferences().then(toolbarPreferences => {
                this.toolbarPreferences = toolbarPreferences ? toolbarPreferences : {};
                this.initializeSettings();
            });
        } else {
            const leftSideNavModeCookie = this.cookieService.get(LEFT_TOOLBAR_PINNED_COOKIE) as MatDrawerMode;
            this.toolbarPreferences.leftSideNavMode = leftSideNavModeCookie ? leftSideNavModeCookie : 'over';

            const sideNavWidthCookie = this.cookieService.get(SIDE_NAV_WIDTH_COOKIE);
            if (sideNavWidthCookie) {
                this.toolbarPreferences.sideNavWidth = parseInt(sideNavWidthCookie, 10);
            }

            this.initializeSettings();
        }
    }

    setSidenavWidth(width: number) {
        const clampedWidth = Math.min(
            Math.max(width, this.sidenavMinWidth),
            this.sidenavMaxWidth
        );

        document.body.style.setProperty('--sidenav-width', `${clampedWidth}px`);

        this.lastSetWidth = width;
    }

    saveSidenavWidth(): void {
        if (this.lastSetWidth) {
            if (this.toolbarPreferences.sideNavWidth !== this.lastSetWidth) {
                this.toolbarPreferences.sideNavWidth = this.lastSetWidth;
                this.saveToolbarPreferences(this.toolbarPreferences);
            }
        }
    }

    toggleLeftSideNavMode(): void {
        if (this.toolbarPreferences.leftSideNavMode === 'over') {
            this.toolbarPreferences.leftSideNavMode = 'side';
        } else {
            this.toolbarPreferences.leftSideNavMode = 'over';
        }

        this.saveToolbarPreferences(this.toolbarPreferences);
    }

    private initializeSettings(): void {
        this.toolbarPreferences.leftSideNavMode =
            this.toolbarPreferences?.leftSideNavMode ?
                this.toolbarPreferences?.leftSideNavMode :
                'over';
        this.isLeftSideNavOpen = this.leftSideNavMode === 'side';

        if (this.toolbarPreferences?.sideNavWidth) {
            this.setSidenavWidth(this.toolbarPreferences?.sideNavWidth);
        }
    }

    private saveToolbarPreferences(toolbarPreferences: ToolbarPreferences): void {
        if (this.useToolbarPreferences) {
            this.userPreferencesService.saveToolbarPreferences(toolbarPreferences);
        } else {
            this.cookieService.set(LEFT_TOOLBAR_PINNED_COOKIE, this.toolbarPreferences.leftSideNavMode);
            this.cookieService.set(SIDE_NAV_WIDTH_COOKIE, this.toolbarPreferences.sideNavWidth?.toString());
        }
    }
}

export interface ToolbarPreferences {
    leftSideNavMode?: MatDrawerMode;
    sideNavWidth?: number;
}

const LEFT_TOOLBAR_PINNED_COOKIE = 'LEFT_TOOLBAR_PINNED_COOKIE';
const SIDE_NAV_WIDTH_COOKIE = 'SIDE_NAV_WIDTH_COOKIE';

const TRACE_RULE_COOKIE = 'TRACE_RULE_COOKIE';
// Angular Imports
import { NgModule } from '@angular/core';

// Angular Material
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CdkMenuModule } from '@angular/cdk/menu'
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DialogModule } from '@angular/cdk/dialog';

@NgModule({
    imports: [
        // Angular Material
        CdkAccordionModule,
        CdkMenuModule,
        OverlayModule,
        FormsModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatButtonModule,
        MatSelectModule,
        MatCheckboxModule,
        MatIconModule,
        MatDatepickerModule,
        MatListModule,
        MatDividerModule,
        MatSidenavModule,
        MatMenuModule,
        MatToolbarModule,
        MatExpansionModule,
        MatInputModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatChipsModule,
        MatTableModule,
        MatTabsModule,
        MatSortModule,
        MatPaginatorModule,
        MatCardModule,
        MatSlideToggleModule,
        DialogModule,
        DragDropModule
    ],
    exports: [
        // Angular Material
        CdkAccordionModule,
        CdkMenuModule,
        OverlayModule,
        FormsModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatButtonModule,
        MatSelectModule,
        MatCheckboxModule,
        MatIconModule,
        MatDatepickerModule,
        MatListModule,
        MatDividerModule,
        MatSidenavModule,
        MatMenuModule,
        MatToolbarModule,
        MatExpansionModule,
        MatInputModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatChipsModule,
        MatTableModule,
        MatTabsModule,
        MatSortModule,
        MatPaginatorModule,
        MatCardModule,
        MatSlideToggleModule,
        DialogModule,
        DragDropModule
    ]
})
export class MaterialModule { }

import { Injectable } from "@angular/core";

import { ZappsmithWebService, ZappsmithBaseParmDict } from "@dicorp/zappsmith-ngx-core";

@Injectable({
    providedIn: 'root'
})
export class DatasetService {

    constructor(private zappsmithWebService: ZappsmithWebService) {
    }

    get(name: string): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'name': name };
        return this.zappsmithWebService.get('/dataset', params);
    }
}
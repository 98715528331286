import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanMatchFn, Route, RouterStateSnapshot, Routes, UrlSegment } from "@angular/router";

import { UserStore } from "@dicorp/zappsmith-ngx-core";

import { PortalRoutingService } from "../models";

import {
    SystemUpdateComponent, RawSystemConfigurationComponent,
    EnvironmentConfigurationComponent, SequenceAdminComponent,
    MigrateCollectionComponent, RavsMigrateComponent, RavsRestoreComponent,
    CsvDataLoadComponent, BoardDataLoadComponent, BoardDataPassComponent,
    DataTransformComponent, DatasetManagerComponent, MenuManagerComponent,
    NodeEditorProjectComponent, NodeEditorNodeKindComponent, NodeEditorTransferComponent,
    RawReportQueryComponent, ReportDomainComponent, SystemConfigurationComponent
} from "./components";
import { RoutingService, SessionService } from "src/services";

@Injectable({
    providedIn: 'root'
})
export class SystemRoutingService implements PortalRoutingService {
    constructor() {
    }

    getRouterRoute(): Promise<Route> {
        return Promise.resolve(systemRoute);

    }

    getToolbarRoute(): Promise<Route> {
        return Promise.resolve(systemToolbarRoute);
    }
}

@Injectable({
    providedIn: 'root'
})
export class CanActivateSystemModule implements CanActivate {
    constructor(
        private userStore: UserStore,
        private routingService: RoutingService) { }

    canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.userStore?.activeUser?.isAdmin) {
            return true;
        } else {
            return this.routingService.canActivateAnyRoute(systemRoutes, routeSnapshot, state);
        }
    }
}

@Injectable({
    providedIn: 'root'
})
class CanActivateSystemRoute implements CanActivate {
    constructor(private sessionService: SessionService) { }

    canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!routeSnapshot || !routeSnapshot?.data || !routeSnapshot?.data['permission']) {
            return true;
        } else {
            const permission = routeSnapshot.data['permission'];
            return this.sessionService.hasPermission(permission);
        }
    }
}

function canMatchSystemRoute(route: Route, segments: UrlSegment[]): CanMatchFn {
    return () => {
        return inject(CanActivateSystemRoute).canActivate(route as ActivatedRouteSnapshot, null);
    }
};

const systemRoutes: Routes = [
    {
        title: 'System Update',
        path: 'system_update',
        component: SystemUpdateComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'SystemUpdateAbility' }
    },
    {
        title: 'System Configuration',
        path: 'configuration',
        component: SystemConfigurationComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'SystemConfigurationAbility' }
    },
    {
        title: 'Raw System Configuration',
        path: 'raw_configuration',
        component: RawSystemConfigurationComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'SystemConfigurationAbility' }
    },
    {
        title: 'Environment Configuration',
        path: 'environment_configuration',
        component: EnvironmentConfigurationComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'AdminAccessOnlyAbility' }
    },
    {
        title: 'Sequence Admin',
        path: 'sequence',
        component: SequenceAdminComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'SequenceAdminAbility' }
    },
    {
        title: 'Migrate Collection (Rpt)',
        path: 'migrate_collection',
        component: MigrateCollectionComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'MigrateCollectionAbility' }
    },
    {
        title: 'RAVS Migrate',
        path: 'ravs_migrate',
        component: RavsMigrateComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'RavsMigrateModuleAbility' }
    },
    {
        title: 'RAVS Restore',
        path: 'ravs_restore',
        component: RavsRestoreComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'RavsRestoreModuleAbility' }
    },
    {
        title: 'CSV Data Load',
        path: 'csv_data_load',
        component: CsvDataLoadComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'CsvDataLoadAbility' }
    },
    {
        title: 'Board Data Load',
        path: 'board_data_load',
        component: BoardDataLoadComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'BoardDataLoadAbility' }
    },
    {
        title: 'Board Data Pass',
        path: 'board_data_pass',
        component: BoardDataPassComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'BoardDataPassAbility' }
    },
    {
        title: 'Data Transform',
        path: 'data_transform',
        component: DataTransformComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'DataTransformAbility' }
    },
    {
        title: 'Dataset Manager',
        path: 'dataset',
        component: DatasetManagerComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'DataSetModuleAbility' }
    },
    {
        title: 'Menu Manager',
        path: 'menu',
        component: MenuManagerComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'FfeMenuModuleAbility' }
    },
    // {
    //     title: 'FFE Setting',
    //     path: 'ffe_setting',
    //     component: FfeSettingComponent,
    // canActivate: [CanActivateSystemRoute],
    // canMatch: [canMatchSystemRoute],
    // data: { permission: 'FfeSettingModuleAbility' }
    // }
    {
        title: 'Node Editor Project',
        path: 'ne_project',
        component: NodeEditorProjectComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'NodeEditorModuleAbility' }
    },
    {
        title: 'Node Editor Node Kind',
        path: 'ne_node_kind',
        component: NodeEditorNodeKindComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'NodeEditorModuleAbility' }
    },
    {
        title: 'Node Editor Transfer',
        path: 'ne_transfer',
        component: NodeEditorTransferComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'NodeEditorModuleAbility' }
    },
    {
        title: 'Raw Report Query',
        path: 'raw_report_query',
        component: RawReportQueryComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'RawReportModuleAbility' }
    },
    {
        title: 'Report Domain',
        path: 'report_domain',
        component: ReportDomainComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'ReportDomainModuleAbility' }
    },

]

const systemRoute: Route =
{
    title: 'System',
    path: 'System',
    children: systemRoutes,
    canActivate: [CanActivateSystemModule]
};

const systemToolbarRoute: Route =
{
    title: 'System',
    path: 'System',
    children: systemRoutes.filter(route => { return !route.data || !route.data['hideToolbar'] }),
    canActivate: [CanActivateSystemModule]
};
<button mat-button (click)="refresh()">Refresh</button>
<button mat-button (click)="resetGridView()">Reset</button>

<!-- StandAlone Action Rules -->
<button mat-button *ngFor="let actionRule of standAloneActionRules"
    [disabled]="!actionRule.isEmptyRowsAllowed && currentKeyValues?.length <= 0" (click)="actionRuleClick(actionRule)">
    {{actionRule?.label}}
</button>

<!-- Dropdown Action Rules -->
<button *ngIf="dropdownActionRules?.length > 0" mat-button [matMenuTriggerFor]="actionRuleMenu" class="menu-btn">
    {{ zappAppBoard?.actionsLabel ? zappAppBoard?.actionsLabel : 'Actions' }}
    <mat-icon class="menu-btn-icon">expand_more</mat-icon>
</button>

<mat-menu #actionRuleMenu="matMenu">
    <button mat-menu-item *ngFor="let actionRule of dropdownActionRules"
        [disabled]="!actionRule.isEmptyRowsAllowed && currentKeyValues?.length <= 0"
        (click)="actionRuleClick(actionRule)">{{actionRule?.label}}</button>
</mat-menu>

<!-- Export -->
<button *ngIf="allowExport" mat-button [matMenuTriggerFor]="exportMenu" class="menu-btn">
    Export
    <mat-icon class="menu-btn-icon">expand_more</mat-icon>
</button>

<mat-menu #exportMenu="matMenu">
    <button mat-menu-item (click)="export('csv')">CSV</button>
    <button mat-menu-item (click)="export('excel')">Excel</button>
</mat-menu>

<!-- Columns -->
<button *ngIf="allowColumnEdit" mat-button [matMenuTriggerFor]="columnEditMenu" class="menu-btn">
    Columns
    <mat-icon class="menu-btn-icon">expand_more</mat-icon>
</button>

<mat-menu #columnEditMenu="matMenu">
    <button mat-menu-item (click)="editColumns()">Edit Columns</button>
    <button mat-menu-item (click)="resetColumns()">Reset Columns</button>
</mat-menu>

<!-- Views -->
<button *ngIf="allowViews" mat-button [matMenuTriggerFor]="viewsMenu" class="menu-btn">
    {{ activeGridView ? activeGridView.name : 'Views' }}
    <mat-icon class="menu-btn-icon">expand_more</mat-icon>
</button>

<mat-menu #viewsMenu="matMenu">
    <button mat-menu-item class="mat-menu-item-icon" *ngFor="let gridView of gridViews | async"
        (click)="gridViewClick(gridView)">
        {{gridView?.name}}
        <mat-icon *ngIf="gridView?._id === latestDefaultGridView?._id">bookmark</mat-icon>
    </button>

    <mat-divider></mat-divider>

    <button mat-menu-item *ngIf="allowViewSave" (click)="handleSaveView()">Save</button>
    <button mat-menu-item *ngIf="allowViewSaveAs" (click)="handleSaveAsView()">Save As</button>
    <button mat-menu-item *ngIf="allowViewSave" (click)="handleEditView()">Edit</button>
    <button mat-menu-item *ngIf="allowViewDelete" (click)="handleDeleteView()">Delete</button>
</mat-menu>
import { Component } from '@angular/core';

@Component({
    selector: 'zs-node-editor-project',
    templateUrl: 'node-editor-project.component.html',
    styleUrls: ['node-editor-project.component.scss']
})
export class NodeEditorProjectComponent {

}

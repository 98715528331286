import { Component } from '@angular/core';
import { EditorMode, EditorOptions, EvalRuleResult, LinkToEntity } from '@dicorp/html-ffe';
import { CommonFunctions } from '@dicorp/zappsmith-ngx-core';
import { GridType, HfeDatasetService } from 'src/components/hfe-components';

import { AlertService, AlertType, RavsMigrateService } from 'src/services';

const MIGRATE_INFO_FIELD = "MigrateInfo";
const MIGRATE_ENTITY = "MigrateEntity";
const CURRENT_INDEX_FIELD = "CurrentIndex";

const FACILITY_ID = "System";

@Component({
    selector: 'zs-ravs-migrate',
    templateUrl: 'ravs-migrate.component.html',
    styleUrls: ['ravs-migrate.component.scss']
})
export class RavsMigrateComponent {
    editorOptions: EditorOptions;
    currentKeyValue: string = '';
    selectedRows: any[];

    targetRavsUrl: string;
    targetUser: string;
    targetPassword: string;

    compareKinds: { [key: string]: CompareFields } = COMPARE_KINDS;
    compareKindSelect: CompareFields;
    activeCompare: CompareFields;

    RAW_BOARD = RAW_BOARD;
    boardName: string;
    boardPath: string;

    compareHtml: string;

    get canCopyItems(): boolean {
        return this.selectedRows?.length > 0;
    }

    constructor(private ravsMigrateService: RavsMigrateService,
        private hfeDatasetService: HfeDatasetService,
        private alertService: AlertService) {
        this.setupEditorOptions();
    }

    compareKindChange(): void {
        this.setupActiveCompare();
        if (this.compareKindSelect !== RAW_BOARD) {
            this.reset();
        } else {
            this.createDataset(this.activeCompare?.columns);
        }
    }

    refresh(): void {
        if (this.compareKindSelect === RAW_BOARD) {
            this.reset();
        } else {
            this.createRecord();
        }
    }

    reset(): void {
        this.editorOptions?.html_ffe_api?.reset_entities_cache();
        if (this.compareKindSelect === RAW_BOARD) {
            this.setupRawBoardActiveCompare();
        } else {
            this.createDataset(this.activeCompare?.columns);
            this.createRecord();
        }
    }

    pullItems(): void {
        this.copyItems(false);
    }

    pushItems(): void {
        this.copyItems(true);
    }

    private setupActiveCompare(): void {
        this.activeCompare = { collection_name: null, key_fields: [], comparison_path: null, columns: [] };
        this.activeCompare = JSON.parse(JSON.stringify(this.compareKindSelect));
    }

    private setupRawBoardActiveCompare(): void {
        this.setupActiveCompare();

        if (this.boardName) {
            this.activeCompare.collection_name = this.boardName;
            const boardFieldPath = this.fixupField(this.boardPath);
            this.activeCompare.comparison_path = boardFieldPath ? boardFieldPath.split('.')[0] : null;

            if (boardFieldPath) {
                this.activeCompare.columns.push({ heading: 'My Raw', field: 'left.' + boardFieldPath, kind: 'text', active: true });
                this.activeCompare.columns.push({ heading: 'Target Raw', field: 'right.' + boardFieldPath, kind: 'text', active: true });
            } else {
                this.activeCompare.columns.push({ heading: 'My Raw', field: 'left._id', kind: 'text', active: true });
                this.activeCompare.columns.push({ heading: 'Target Raw', field: 'right._id', kind: 'text', active: true });
            }

            this.createDataset(this.activeCompare?.columns);
            this.createRecord();
        }
    }

    private setupEditorOptions(): void {
        this.editorOptions = new EditorOptions();
        this.editorOptions.editorMode = EditorMode.View;

        this.editorOptions.diRunRule = (rule_name: string, record: any) => {
            return this.diRunRule(rule_name, record);
        };

        // this.editorOptions.diGetEntities = (entity_name: string) => {
        //     return new Promise<LinkToEntity[]>((resolve, reject) => {
        //         this.ravsMigrateService.compare(this.targetRavsUrl, this.targetUser, FACILITY_ID, this.targetPassword,
        //             this.activeCompare?.collection_name, this.activeCompare?.key_fields).then(
        //                 result => {
        //                     resolve(result);
        //                 },
        //                 result => {
        //                     this.alertService.addAlert({
        //                         title: 'Error',
        //                         message: "Could not get records",
        //                         type: AlertType.error
        //                     });
        //                     reject();
        //                 })
        //     })
        // }
    }

    private createRecord(): void {
        // const datasetRecord: any = {};
        // this.editorOptions?.html_ffe_api?.reload_record(datasetRecord);

        this.ravsMigrateService.compare(this.targetRavsUrl, this.targetUser, FACILITY_ID, this.targetPassword,
            this.activeCompare?.collection_name, this.activeCompare?.key_fields).then(
                result => {
                    const datasetRecord: any = {};
                    datasetRecord[MIGRATE_INFO_FIELD] = result;
                    this.editorOptions?.html_ffe_api?.reload_record(datasetRecord);
                },
                result => {
                    this.alertService.addAlert({
                        title: 'Error',
                        message: "Could not get records",
                        type: AlertType.error
                    });
                })
    }

    private createDataset(columnDefs: any[]): void {
        // Add the status column
        const newColumnDefs = columnDefs.concat([STATUS_COLUMN]);
        const datasetConfig = this.hfeDatasetService.getDatasetConfigUsingColumnDefs(MIGRATE_INFO_FIELD, MIGRATE_ENTITY, newColumnDefs, GridType.Local);

        // Add current index rule
        datasetConfig.rules.RUL_Eval = this.getEvalRules();

        const editorDataset: any = {
            sections: {
                "MainForm": {
                    "name": "MainForm",
                    "height": "100%",
                    "children": [
                        {
                            "dm_reference": MIGRATE_INFO_FIELD,
                            "type": "Grid",
                            "align_label": "Hidden",
                            "height": "100%",
                            "allow_multi_select": true,
                            "hide_grid_filter": true,
                            "current_index_field": CURRENT_INDEX_FIELD,
                            "children": datasetConfig.columns
                        }
                    ]
                }
            },
            field_configurations: datasetConfig.fieldConfigurations,
            rules: datasetConfig.rules,
            menus: datasetConfig.menus,
            default_date_format: "ISO8601",
            default_time_format: "string"
        };

        this.editorOptions?.html_ffe_api?.load_dataset(editorDataset);
    }

    private getEvalRules(): any[] {
        const evalRules: any[] = [];

        evalRules.push({
            "triggers": [
                {
                    "trigger_event": "Change",
                    "target": CURRENT_INDEX_FIELD
                }
            ],
            "clisp_module": CURRENT_INDEX_FIELD,
            "kind": "RUL_Eval",
            "name": CURRENT_INDEX_FIELD
        });

        return evalRules;
    }

    private diRunRule(rule_name: string, record: any): Promise<EvalRuleResult[]> {
        switch (rule_name) {
            case CURRENT_INDEX_FIELD: {
                this.currentKeyValue = CommonFunctions.resolvePathValue(CURRENT_INDEX_FIELD, record);
                const selectedRows = this.editorOptions?.html_ffe_api?.get_grid_selected_rows(MIGRATE_INFO_FIELD);
                if (JSON.stringify(selectedRows) !== JSON.stringify(this.selectedRows)) {
                    this.selectedRows = selectedRows;
                    this.selectedRowChanged();
                }
                break;
            }
            default: {
                break;
            }
        }

        return Promise.resolve([]);
    }

    private selectedRowChanged(): void {
        if (this.selectedRows?.length > 0) {
            const selectedRow = this.selectedRows[this.selectedRows.length - 1];
            const left_id = selectedRow?.left?._id;
            const right_id = selectedRow?.right?._id;

            if (left_id && right_id && this.activeCompare?.collection_name && this.activeCompare?.comparison_path) {
                this.ravsMigrateService.diff(this.targetRavsUrl, this.targetUser, FACILITY_ID, this.targetPassword,
                    this.activeCompare.collection_name, left_id, right_id, this.activeCompare.comparison_path).then(
                        result => {
                            this.compareHtml = result.join('\n');;
                        },
                        result => {
                            this.alertService.addAlert({
                                title: 'Error',
                                message: "Could not diff records",
                                type: AlertType.error
                            });
                        }
                    )
            } else {
                this.compareHtml = null;
            }
        }
    }

    private getSelectedIds(): any[] {
        const selectedIds = this.selectedRows?.map(value => {
            return {
                left_id: value?.left?._id,
                right_id: value?.right?._id
            };
        });

        if (!selectedIds || selectedIds?.length <= 0) {
            this.alertService.addAlert({
                title: 'Error',
                message: "Please select at least one record",
                type: AlertType.warning
            });
            return null;
        }

        return selectedIds;
    }

    private copyItems(left_to_right: boolean): void {
        const items = this.getSelectedIds();

        this.ravsMigrateService.copyItems(this.targetRavsUrl, this.targetUser, FACILITY_ID, this.targetPassword, this.activeCompare.collection_name, items, left_to_right).then(
            result => {
                this.alertService.addAlert({
                    title: 'Copied',
                    message: result?.message,
                    type: AlertType.success,
                    require_confirm: true
                });
            },
            result => {
                this.alertService.addAlert({
                    title: 'Error',
                    message: "Could not copy records",
                    type: AlertType.error
                });
            }
        )
    }

    private fixupField(field: string): string {
        if (field?.indexOf('#', field.length - '#'.length) !== -1) {
            field = field?.substring(0, field.length - 1);
        } else if (field?.indexOf("primary_facility") != 0) {
            field = field + ".#value";
        }
        return field;
    };
}

const STATUS_COLUMN = { heading: 'Status', field: 'status', kind: 'text', active: true };

interface CompareFields {
    collection_name: string;
    key_fields: string[];
    comparison_path: string;
    label?: string;
    columns: any[];
};

const FACILITY: CompareFields = {
    collection_name: 'facility',
    key_fields: ['Facility.Id'],
    comparison_path: 'Facility',
    label: 'Facility',
    columns: [
        { heading: 'My Facility Id', field: 'left.Facility.Id', kind: 'text', active: true },
        { heading: 'My Facility Name', field: 'left.Facility.Name', kind: 'text', active: true },
        { heading: 'Target Facility Id', field: 'right.Facility.Id', kind: 'text', active: true },
        { heading: 'Target Facility Name', field: 'right.Facility.Name', kind: 'text', active: true }
    ]
};

const USER: CompareFields = {
    collection_name: 'user',
    key_fields: ['User.Id'],
    comparison_path: 'User',
    label: 'User',
    columns: [
        { heading: 'My User Id', field: 'left.User.Id', kind: 'text', active: true },
        { heading: 'My User Name', field: 'left.User.Name', kind: 'text', active: true },
        { heading: 'Target User Id', field: 'right.User.Id', kind: 'text', active: true },
        { heading: 'Target User Name', field: 'right.User.Name', kind: 'text', active: true }
    ]
};

const ROLE: CompareFields = {
    collection_name: 'role',
    key_fields: ['Role.Id'],
    comparison_path: 'Role',
    label: 'Role',
    columns: [
        { heading: 'My Role Id', field: 'left.Role.Id', kind: 'text', active: true },
        { heading: 'My Role Name', field: 'left.Role.Name', kind: 'text', active: true },
        { heading: 'Target Role Id', field: 'right.Role.Id', kind: 'text', active: true },
        { heading: 'Target Role Name', field: 'right.Role.Name', kind: 'text', active: true }
    ]
};

const STAFF: CompareFields = {
    collection_name: 'staff',
    key_fields: ['Staff.Id'],
    comparison_path: 'Staff',
    label: 'Staff',
    columns: [
        { heading: 'My Staff Id', field: 'left.Staff.Id', kind: 'text', active: true },
        { heading: 'My Staff Name', field: 'left.Staff.Name', kind: 'text', active: true },
        { heading: 'Target Staff Id', field: 'right.Staff.Id', kind: 'text', active: true },
        { heading: 'Target Staff Name', field: 'right.Staff.Name', kind: 'text', active: true }
    ]
};

const CUSTOM_MENU_DEF: CompareFields = {
    collection_name: 'custom_menu_def',
    key_fields: ["CustomMenuDef.Id", "CustomMenuDef.DataSetName"],
    comparison_path: 'CustomMenuDef',
    label: 'Custom Menu Def',
    columns: [
        { heading: 'My CustomMenuDef Id', field: 'left.CustomMenuDef.Id', kind: 'text', active: true },
        { heading: 'My CustomMenuDef DatasetName', field: 'left.CustomMenuDef.DatasetName', kind: 'text', active: true },
        { heading: 'Target CustomMenuDef Id', field: 'right.CustomMenuDef.Id', kind: 'text', active: true },
        { heading: 'Target CustomMenuDef DatasetName', field: 'right.CustomMenuDef.DatasetName', kind: 'text', active: true }
    ]
};

const CUSTOM_FIELD_DEF: CompareFields = {
    collection_name: 'custom_field_def',
    key_fields: ['CustomFieldDef.Name'],
    comparison_path: 'CustomFieldDef',
    label: 'CustomFieldDef',
    columns: [
        { heading: 'My CustomFieldDef Name', field: 'left.CustomFieldDef.Name', kind: 'text', active: true },
        { heading: 'Target CustomFieldDef Name', field: 'right.CustomFieldDef.Name', kind: 'text', active: true }
    ]
};

const IMAGE: CompareFields = {
    collection_name: 'image',
    key_fields: ['Image.Id'],
    comparison_path: 'Image',
    label: 'Image',
    columns: [
        { heading: 'My Image Id', field: 'left.Image.Id', kind: 'text', active: true },
        { heading: 'My Image Uri', field: 'left.Image.ImageUri', kind: 'text', active: true },
        { heading: 'Target Image Id', field: 'right.Image.Id', kind: 'text', active: true },
        { heading: 'Target Image Uri', field: 'right.Image.ImageUri', kind: 'text', active: true }
    ]
};

const TEMPLATE_DOCUMENT: CompareFields = {
    collection_name: 'template_document',
    key_fields: ['TemplateDocument.Id'],
    comparison_path: 'TemplateDocument',
    label: 'Template Document',
    columns: [
        { heading: 'My TemplateDocument Id', field: 'left.TemplateDocument.Id', kind: 'text', active: true },
        { heading: 'My TemplateDocument Name', field: 'left.TemplateDocument.Name', kind: 'text', active: true },
        { heading: 'Target TemplateDocument Id', field: 'right.TemplateDocument.Id', kind: 'text', active: true },
        { heading: 'Target TemplateDocument Name', field: 'right.TemplateDocument.Name', kind: 'text', active: true }
    ]
};

const TEMPLATE_TAG: CompareFields = {
    collection_name: 'template_tag',
    key_fields: ['TemplateTag.Id'],
    comparison_path: 'TemplateTag',
    label: 'Template Tag',
    columns: [
        { heading: 'My TemplateTag Id', field: 'left.TemplateTag.Id', kind: 'text', active: true },
        { heading: 'My TemplateTag Name', field: 'left.TemplateTag.Name', kind: 'text', active: true },
        { heading: 'Target TemplateTag Id', field: 'right.TemplateTag.Id', kind: 'text', active: true },
        { heading: 'Target TemplateTag Name', field: 'right.TemplateTag.Name', kind: 'text', active: true }
    ]
};

const TEMPLATE_TAG_GROUP: CompareFields = {
    collection_name: 'template_tag_group',
    key_fields: ['TemplateTagGroup.Id'],
    comparison_path: 'TemplateTagGroup',
    label: 'Template Tag Group',
    columns: [
        { heading: 'My TemplateTagGroup Id', field: 'left.TemplateTagGroup.Id', kind: 'text', active: true },
        { heading: 'My TemplateTagGroup Name', field: 'left.TemplateTagGroup.Name', kind: 'text', active: true },
        { heading: 'Target TemplateTagGroup Id', field: 'right.TemplateTagGroup.Id', kind: 'text', active: true },
        { heading: 'Target TemplateTagGroup Name', field: 'right.TemplateTagGroup.Name', kind: 'text', active: true }
    ]
};

const ACCOUNT: CompareFields = {
    collection_name: 'account',
    key_fields: ["Account.UserLink.@id", "Account.RoleLink.@id", "Account.FacilityLink.@id"],
    comparison_path: 'Account',
    label: 'Account',
    columns: [
        { heading: 'My Account User', field: 'left.Account.User.@id', kind: 'text', active: true },
        { heading: 'My Account Role', field: 'left.Account.RoleLink.@id', kind: 'text', active: true },
        { heading: 'My Account Facility', field: 'left.Account.FacilityLink.@id', kind: 'text', active: true },
        { heading: 'Target Account User', field: 'right.Account.User.@id', kind: 'text', active: true },
        { heading: 'Target Account Role', field: 'right.Account.RoleLink.@id', kind: 'text', active: true },
        { heading: 'Target Account Facility', field: 'right.Account.FacilityLink.@id', kind: 'text', active: true }
    ]
};

const RAW_BOARD: CompareFields = {
    collection_name: null,
    key_fields: ['_id'],
    comparison_path: null,
    label: 'Raw Board',
    columns: [
        { heading: 'My _id', field: 'left._id', kind: 'text', active: true },
        { heading: 'Target _id', field: 'right._id', kind: 'text', active: true }
    ]
};

const COMPARE_KINDS: { [key: string]: CompareFields } = {
    'facility': FACILITY,
    'role': ROLE,
    'staff': STAFF,
    'user': USER,
    'account': ACCOUNT,
    'custom_menu_def': CUSTOM_MENU_DEF,
    'custom_field_def': CUSTOM_FIELD_DEF,
    'template_document': TEMPLATE_DOCUMENT,
    'template_tag': TEMPLATE_TAG,
    'template_tag_group': TEMPLATE_TAG_GROUP,
    'image': IMAGE,
    'raw_board': RAW_BOARD
}

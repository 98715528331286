import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { JsonEditorOptions, JsonEditorComponent } from 'ang-jsoneditor';

import { AlertService, AlertType, GeneralDatasetService } from 'src/services';

@Component({
    selector: 'zs-ne-document-viewer',
    templateUrl: 'ne-document-viewer.component.html',
    styleUrls: ['ne-document-viewer.component.scss']
})
export class NeDocumentViewerComponent {
    @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;
    public editorOptions: JsonEditorOptions;

    public data: any = {};

    doc_id: string;
    version: string;

    constructor(private activatedRoute: ActivatedRoute,
        private location: Location,
        private alertService: AlertService,
        private generalDatasetService: GeneralDatasetService) {
        this.setupJsonEditorOptions();
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(queryParams => {
            this.doc_id = queryParams['doc_id'];
            if (queryParams['version']) {
                this.version = queryParams['version'];
            }

            this.reload();
        });
    }

    private setupJsonEditorOptions(): void {
        this.editorOptions = new JsonEditorOptions();

        this.editorOptions.mode = 'view';
        this.editorOptions.modes = ['view'];
        this.editorOptions.indentation = 4;
        this.editorOptions.name = 'NeDocument';

        this.editorOptions.mainMenuBar = true;
        // this.editorOptions.navigationBar = true;
    }

    private reload(): void {
        this.generalDatasetService.get('/ne_document', this.doc_id, this.version, false).then(
            result => {
                this.data = result;
            },
            result => {
                this.alertService.addAlert({
                    title: 'Error',
                    message: "Could not get record",
                    type: AlertType.error
                });
            });
    }

    close(): void {
        this.location.back();
    }
}

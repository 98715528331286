import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { ViewportScroller } from '@angular/common';
import { Component, Directive, HostListener, Inject, OnInit } from '@angular/core';
import { AlertService, AlertType, GeneralDatasetService } from 'src/services';

@Component({
    selector: 'version-compare',
    templateUrl: 'version-compare.component.html',
    styleUrls: ['version-compare.component.scss']
})
export class VersionCompareComponent implements OnInit {
    public compareHtml: string;

    public versions: string[];
    public firstVersion: string;
    public secondVersion: string;

    public compareFormat: CompareFormat;

    public CompareFormat = CompareFormat;

    constructor(
        private alertService: AlertService,
        private generalDatasetService: GeneralDatasetService,
        public dialogRef: DialogRef<boolean>,
        @Inject(DIALOG_DATA) public dialogData: VersionCompareData) {
    }

    ngOnInit(): void {
        this.versions = this.dialogData?.versions?.sort((a, b) => {
            return parseInt(a) - parseInt(b)
        });
        this.firstVersion = this.dialogData?.startVersion;
        this.secondVersion = this.versions[this.versions?.length - 1];
        this.compareVersions(CompareFormat.compressed);
    }

    compareVersions(compareFormat: CompareFormat): void {
        this.compareFormat = compareFormat;
        this.generalDatasetService.diff_versions(this.dialogData?.baseObject, this.dialogData?.doc_id, this.firstVersion, this.secondVersion, compareFormat).then(
            result => {
                if (compareFormat === CompareFormat.text) {
                    this.compareHtml = result;
                } else {
                    this.compareHtml = JSON.parse(result).join('\n');
                }
            },
            result => {
                this.alertService.addAlert({
                    title: 'Error',
                    message: "Could not get diff",
                    type: AlertType.error
                });
            });
    }
}

export interface VersionCompareData {
    baseObject: string;
    doc_id: string;

    startVersion: string;

    versions: string[];
}

export enum CompareFormat {
    text = 'text',
    compressed = 'Compressed'
}

// A directive to handle link navigation inside the HTML compare returned by the server
@Directive({
    selector: '[zsVersionCompareHTML]'
})
export class VersionCompareHTMLDirective {
    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        if ((event.target as any)?.hash) {
            event.stopPropagation();
            event.preventDefault();
            const hash = (event.target as any)?.hash;
            const anchorElement = document.getElementById(hash?.replace('#', ''));
            anchorElement?.scrollIntoView({ block: 'start', behavior: 'auto' });
        }
    }
}

import { Injectable } from "@angular/core";

import { ZappsmithWebService, ZappsmithBaseParmDict } from "@dicorp/zappsmith-ngx-core";

@Injectable({
    providedIn: 'root'
})
export class RavsMigrateService {

    constructor(private zappsmithWebService: ZappsmithWebService) {
    }

    compare(url: string, username: string, facility_id: string, password: string, collection_name: string, key_fields: string[]): Promise<any> {
        const params: ZappsmithBaseParmDict = {
            url,
            username,
            facility_id,
            password,
            collection_name,
            key_fields: JSON.stringify(key_fields),
        };
        return this.zappsmithWebService.post('/ravs_migrate', params);
    };

    diff(url: string, username: string, facility_id: string, password: string, collection_name: string, left_id: string, right_id: string, comparison_path: string): Promise<any> {
        const params: ZappsmithBaseParmDict = {
            url,
            username,
            facility_id,
            password,
            collection_name,
            left_id,
            right_id,
            comparison_path
        };
        return this.zappsmithWebService.post('/ravs_migrate/diff_doc', params);
    };

    copyItems(url: string, username: string, facility_id: string, password: string, collection_name: string, items: any[], left_to_right): Promise<any> {
        const params: ZappsmithBaseParmDict = {
            url,
            username,
            facility_id,
            password,
            collection_name,
            items: JSON.stringify(items),
            left_to_right
        };
        return this.zappsmithWebService.post('/ravs_migrate/copy', params);
    };
}
import { Injectable } from "@angular/core";
import { QueryParamsOperations } from '@dicorp/zappsmith-ngx-core';
import { Subject } from "rxjs";

import {
    BindingRule, ICdict, IClientModuleRuleDict, IFfeOptions, IMenuItem, IRawBinding,
    IRawBindingMap, RuleBinding, RuleBindingMap, RuleDefinition, IHeader
} from "../common";
import { IHtmlFFEApi } from "@dicorp/html-ffe/core/framework";


const INT_TEXT_TAG = '#value';
const INT_BIU_TAG = '@biu';

@Injectable({
    providedIn: 'root'
})
export class ClientModuleRulesService {

    public clientModuleRules: IClientModuleRuleDict;

    loaded: boolean = false;
    loaded$ = new Subject<boolean>();

    constructor() {
        const script = document.createElement('script')
        script.src = QueryParamsOperations.baseUrl + '/portal/rules.js';
        script.onload = () => this.onJsLoad();
        document.body.appendChild(script);
    }

    private onJsLoad(): void {
        this.clientModuleRules = (window as any).clientModuleRules;
        this.loaded = true;
        this.loaded$.next(true);
    }

    public moduleExists(moduleName: string): boolean {
        return moduleName in this.clientModuleRules;
    }

    public postProcessRuleResults(cdict: ICdict, d: IRawBindingMap) {
        // TODO : Add later chaining?
        if (d.hasOwnProperty('_TargetValueField')) {
            const f: IRawBinding = d['_TargetValueField'];
            const orig_value = f.lookup_value;
            const field = f.raw_value;
            const new_value: any = {};
            new_value[INT_TEXT_TAG] = cdict.value;
            if (new_value === null) {
                cdict.changes[field] = { 'operation': 'remove' };
            } else {
                cdict.changes[field] = {
                    'value': new_value,
                    'operation': orig_value !== null ? 'update' : 'add'
                };
            }
        }
        if (d.hasOwnProperty('_SourcePathField')) {
            const f: IRawBinding = d['_SourcePathField'];
            const orig_value: string = f.raw_value;
            if (orig_value) {
                cdict.paths = [orig_value];
            }
        }
    };

    invokeClientRule(module_name: string, new_bindings: RuleBindingMap): any {
        const c = this.clientModuleRules[module_name];
        const r: BindingRule = c.hasOwnProperty('rule') ? (<RuleDefinition>c).rule : <BindingRule>c;
        return r(new_bindings)
    }

    runClientModuleRule(module_name: string, bindings: IRawBindingMap, rule: any, input_dict: object, ffe_options: IFfeOptions) {
        let cdict: ICdict = null;
        //console.log("Running " + module_name);
        if (module_name in this.clientModuleRules) {
            //let new_bindings: IRuleBindingMap = {};
            const new_bindings = new RuleBindingMap();
            let has_any = false;
            cdict = {
                "error": null,
                "value": true,
                "changes": {},
                "message": null,
                "paths": null,
                "rule": rule,
                "record": input_dict,
                ffe_options: ffe_options
            };
            Object.getOwnPropertyNames(bindings).forEach(function (key) {
                let value: IRawBinding = bindings[key];
                new_bindings[key] = new RuleBinding(value, cdict);
                has_any = true;
            });
            if (!has_any) {
                new_bindings["DEFAULT_EMPTY"] = new RuleBinding(null, cdict);
            }
            this.invokeClientRule(module_name, new_bindings);
            // const c = clientModuleRules[module_name];
            // const r: BindingRule = c.hasOwnProperty('rule') ? (<RuleDefinition>c).rule : <BindingRule>c;
            // // clientModuleRules[module_name](new_bindings);
            // r(new_bindings)
            this.postProcessRuleResults(cdict, bindings);
        }
        return cdict;
    }

    rulesToJsonData(): any {
        let outputJson = [];
        for (let moduleRule of Object.keys(this.clientModuleRules)) {
            if ("docs" in this.clientModuleRules[moduleRule]) {
                outputJson.push({
                    name: moduleRule,
                    docs: this.clientModuleRules[moduleRule].docs,
                    source: this.clientModuleRules[moduleRule].rule.toString()
                });
            }
        }
        console.log(JSON.stringify(outputJson, null, '\t'));
    }
}

export interface ClientModuleRulesContext {
    // Properties
    doc_id: string;
    version: string;

    orig_doc: any;
    model: any;

    // Functions
    getHtmlFfeApi(): IHtmlFFEApi;
    reload(): void;
    cancel(): void;
    lowLevelSave(record: any): void;

    calculateActiveStatus(defaultActiveStatus: boolean): boolean
    hasAnyPermission(permission: string): boolean;
    getPermission(permission: string): number;

    get_setting(name: string): any;
    getConfigurationItem(name: string, defaultValue: any): any;

    gotoState(newState: string, doc_id: string, confirmExit: boolean): void;

    get_standard_dynamic_menu(menu_name: string, record: any): Promise<any>;
    set_standard_dynamic_menu(menu_name: string, menu: IMenuItem[]): void;
    refreshDynamicMenu(menu_name: string): void;
    refreshFieldPath(field_path: string): void;

    viewEntity(entityName: string, key: string): void;
    updateEntity(entityName: string, key: string): void;
    resetEntitiesCache(): void;
    resetEntityCache(entityName: string): void;

    openCheckMode(): void;
    setHeaders(headers: IHeader[]): void;

    hasAnyLicense(licenses: string[]): boolean;

    lookupMenuValue(mdlPath: string, value: any): any;

    frm_rule_success(results: any[]): void;
    frm_rule_failure(results: any[]): void;
}
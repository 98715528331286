import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ZappsmithWebService, ZappsmithBaseParmDict } from "@dicorp/zappsmith-ngx-core";
import { FormDefaultDialogComponent } from "./form-default-dialog/form-default-dialog.component";
import { AlertService, AlertType } from "../alerts";

@Injectable({
    providedIn: 'root'
})
export class FormDefaultService {

    constructor(private zappsmithWebService: ZappsmithWebService,
        private alertService: AlertService,
        private dialog: MatDialog) {
    }

    open_copy_dialog(doc_id: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const dialogRef = this.dialog.open(FormDefaultDialogComponent, {
                disableClose: true,
                closeOnNavigation: true,
                hasBackdrop: true,
                data: "Form Default Copy"
            });

            dialogRef.afterClosed().subscribe((facility_id: boolean | string) => {
                if (typeof facility_id === "string") {
                    this.copy(doc_id, facility_id).then(
                        result => {
                            this.alertService.addAlert({
                                title: 'Copy',
                                message: "Form Default Copied",
                                type: AlertType.success
                            });
                            resolve(true);
                        },
                        result => {
                            let message = '<b>Could not copy Dataset</b>';
                            message += result.error?.message ? ': ' + result.error?.message : '';
                            this.alertService.addAlert({
                                title: 'Error',
                                message,
                                type: AlertType.error
                            });
                            resolve(false);
                        }
                    )
                } else {
                    resolve(false)
                }
            });
        });
    }

    open_reassign_dialog(doc_id: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const dialogRef = this.dialog.open(FormDefaultDialogComponent, {
                disableClose: true,
                closeOnNavigation: true,
                hasBackdrop: true,
                data: "Form Default Copy"
            });

            dialogRef.afterClosed().subscribe((facility_id: boolean | string) => {
                if (typeof facility_id === "string") {
                    this.reassign(doc_id, facility_id).then(
                        result => {
                            this.alertService.addAlert({
                                title: 'Reassign',
                                message: "Form Default Reassigned",
                                type: AlertType.success
                            });
                            resolve(true);
                        },
                        result => {
                            let message = '<b>Could not reassign Dataset Facility</b>';
                            message += result.error?.message ? ': ' + result.error?.message : '';
                            this.alertService.addAlert({
                                title: 'Error',
                                message,
                                type: AlertType.error
                            });
                            resolve(false);
                        }
                    )
                } else {
                    resolve(false)
                }
            });
        });
    }

    copy(doc_id: string, facility_id: string): Promise<void> {
        const params: ZappsmithBaseParmDict = { doc_id, facility_id };
        return this.zappsmithWebService.post('/form_default/copy', params);
    };

    reassign(doc_id: string, facility_id: string): Promise<void> {
        const params: ZappsmithBaseParmDict = { doc_id, facility_id };
        return this.zappsmithWebService.post('/form_default/reassign', params);
    }
}
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AlertDialogData, AlertDialogType } from '../alert-models';

@Component({
    selector: 'zs-alert-dialog',
    templateUrl: 'alert-dialog.component.html',
    styleUrls: ['alert-dialog.component.scss']
})
export class AlertDialogComponent {

    showOk: boolean;
    showCancel: boolean;

    constructor(
        public dialogRef: MatDialogRef<AlertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public alertDialogData: AlertDialogData,
    ) {
        switch (alertDialogData?.type) {
            case (AlertDialogType.ok): {
                this.showOk = true;
                break;
            }
            case (AlertDialogType.okCancel): {
                this.showOk = true;
                this.showCancel = true;
                break;
            }
        }
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'src/3rd-party';

import { HtmlFFEModule } from '@dicorp/html-ffe';

import { HfeEditorOptionsService, HfeDatasetService } from './hfe-services';
import { DialogComponentsModule } from '../dialog-components';
import { VersionCompareComponent, VersionCompareHTMLDirective } from './version-compare/version-compare.component';
import { ServicesModule } from 'src/services';

import { HfePortalEditorComponent } from './hfe-portal-editor/hfe-portal-editor.component';
import { HfeGridActionsComponent } from './hfe-grid-actions/hfe-grid-actions.component';
import { ColumnDefManagerComponent } from './column-def-manager/column-def-manager.component';
import { RecordManagerComponent } from './record-manager/record-manager.component';
import { FfeEditorComponent } from './ffe-editor/ffe-editor.component';
import { TrackingInfoComponent } from './tracking-info/tracking-info.component';
import { BenchTopComponent } from './bench-top/bench-top.component';
import { GridViewEditorComponent } from './grid-view-editor/grid-view-editor.component';
import { NeDocumentViewerComponent } from './ne-document-viewer/ne-document-viewer.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ServicesModule,
        HtmlFFEModule,
        DialogComponentsModule,
        NgJsonEditorModule,
    ],
    declarations: [
        HfePortalEditorComponent,
        HfeGridActionsComponent,
        ColumnDefManagerComponent,
        RecordManagerComponent,
        FfeEditorComponent,
        TrackingInfoComponent,
        VersionCompareComponent,
        VersionCompareHTMLDirective,
        BenchTopComponent,
        GridViewEditorComponent,
        NeDocumentViewerComponent
    ],
    exports: [
        HfePortalEditorComponent,
        HfeGridActionsComponent,
        ColumnDefManagerComponent,
        RecordManagerComponent,
        FfeEditorComponent,
        TrackingInfoComponent,
        VersionCompareComponent,
        VersionCompareHTMLDirective,
        BenchTopComponent,
        GridViewEditorComponent,
        NeDocumentViewerComponent
    ],
    providers: [
        HfeEditorOptionsService,
        HfeDatasetService
    ]
})
export class HfeComponentsModule {
}
import { Injectable } from "@angular/core";

import { ZappsmithWebService, ZappsmithBaseParmDict } from "@dicorp/zappsmith-ngx-core";

@Injectable({
    providedIn: 'root'
})
export class RecordLockService {

    constructor(private zappsmithWebService: ZappsmithWebService) {
    }

    lock(key: string): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'key': key };
        return this.zappsmithWebService.post('/record_lock', params);
    };

    unlock(key: string): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'key': key };
        return this.zappsmithWebService.post('/record_unlock', params);
    }
}
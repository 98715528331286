import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanMatchFn, Route, RouterStateSnapshot, Routes } from "@angular/router";

import { RoutingService, SessionService } from "src/services";

import { PortalRoutingService } from "../../models";
import { UserStore } from "@dicorp/zappsmith-ngx-core";
import { RecordManagerComponent } from "src/components/hfe-components";
import { AdminBoardsService } from "../admin-boards.service";
import { ZappAppBoard } from "src/component-store";

@Injectable({
    providedIn: 'root'
})
export class DataExchangeRoutingService implements PortalRoutingService {
    constructor(private adminBoardsService: AdminBoardsService,
        private sessionService: SessionService) {
    }

    getRouterRoute(): Promise<Route> {
        return new Promise<Route>(resolve => {
            this.adminBoardsService.getAdminBoards(DATA_EXCHANGE_PATH).then(adminBoardsDict => {
                submissionRoute.children.forEach(childRoute => {
                    const adminBoardKey = childRoute.data ? childRoute.data['adminBoardKey'] : undefined;
                    if (adminBoardKey && adminBoardsDict[adminBoardKey]) {
                        const zappAppBoard = adminBoardsDict[adminBoardKey];
                        childRoute.data = { zappAppBoard };
                        submissionRoute.children = submissionRoute.children.concat(this.createBoardRoutes(zappAppBoard));
                    }
                })

                resolve(submissionRoute);
            });
        });
    }

    getToolbarRoute(): Promise<Route> {
        return new Promise<Route>(resolve => {
            this.adminBoardsService.getAdminBoards(DATA_EXCHANGE_PATH).then(adminBoardsDict => {
                submissionToolbarRoute.children.forEach(childRoute => {
                    const adminBoardKey = childRoute.data ? childRoute.data['adminBoardKey'] : undefined;
                    if (adminBoardKey && adminBoardsDict[adminBoardKey]) {
                        const zappAppBoard = adminBoardsDict[adminBoardKey];
                        childRoute.data = { zappAppBoard };
                    }
                })

                resolve(submissionToolbarRoute);
            });
        });
    }
    private createBoardRoutes(zappAppBoard: ZappAppBoard): Routes {
        const boardRoutes: Routes = [];
        return boardRoutes;
    }
}

@Injectable({
    providedIn: 'root'
})
class CanActivateSubmissionModuleAbility implements CanActivate {
    constructor(private sessionService: SessionService) { }

    canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.sessionService.hasPermission('SubmissionModuleAbility');
    }
}

function canMatchSubmissionModuleAbility(): CanMatchFn {
    return () => {
        return inject(CanActivateSubmissionModuleAbility).canActivate(null, null);
    }
};

@Injectable({
    providedIn: 'root'
})
class CanActivateSubmissionModule implements CanActivate {
    constructor(
        private userStore: UserStore,
        private routingService: RoutingService) { }

    canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.userStore?.activeUser?.isAdmin) {
            return true;
        } else {
            return this.routingService.canActivateAnyRoute(submissionRoutes, routeSnapshot, state);
        }
    }
}

export const submissionRoutes: Routes = [
    {
        title: 'Processing Manager',
        path: 'ProcessingManager',
        data: {
            adminBoardKey: 'processing_manager'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateSubmissionModuleAbility],
        canMatch: [canMatchSubmissionModuleAbility]
    }
];

const submissionRoute: Route =
{
    title: 'Data Exchange',
    path: 'DataExchange',
    children: submissionRoutes,
    canActivate: [CanActivateSubmissionModule]
};

const submissionToolbarRoute: Route =
{
    title: 'Data Exchange',
    path: 'DataExchange',
    children: submissionRoutes.filter(route => { return !route.data || !route.data['hideToolbar'] }),
    canActivate: [CanActivateSubmissionModule]
};

export const DATA_EXCHANGE_PATH = 'DataExchange';

export const DATA_EXCHANGE_BOARDS_JSON: any = {
    'processing_manager': {
        "_id": "processing_manager",
        "object_name": "submission/query",
        "permission_base": "SubmissionModuleAbility::",
        "dataset": "submission",
        "columnDefSetting": "submission",
        "static_board": true,
        "disable_actions": true,
        "hideDetailsAction": true,
        "disableDefaultActionRules": true,
        "disableExport": true,
        "disableColumnsAndViews": true,
        "Board": {
            "ApplicationLink": {
                "@id": "Processing",
                "@name": "Processing"
            },
            "Id": {
                "#value": "Processing"
            },
            "Name": {
                "#value": "Processing"
            },
            "Label": {
                "#value": "Processing"
            },
            "PluralLabel": {
                "#value": "Processing"
            },
            "MainPackage": {
                "#value": "Processing_PKG"
            }
        }
    }
}
import { Component } from '@angular/core';

@Component({
    selector: 'dataset-manager',
    templateUrl: 'dataset-manager.component.html',
    styleUrls: ['dataset-manager.component.scss']
})
export class DatasetManagerComponent {

}

import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { ZappsmithBaseParmDict, ZappsmithWebService } from '@dicorp/zappsmith-ngx-core';
import { BaseZappsmithStoreState, BaseZappsmithComponentStore } from '@dicorp/zappsmith-ngx-core';

import { ColumnState } from 'ag-grid-enterprise';
import { AlertService, AlertType } from 'src/services';

export interface GridState {
    columnState?: ColumnState[],
    filterModel?: any,
    lastCurrentKeyValues?: string[],
    selectedViewKey?: string
    defaultViewNeedsLoading?: boolean;
    // sortModel: $scope.gridOptions.api.getSortModel(),
    // rowCount: $scope.gridOptions.api.getInfiniteRowCount(),
    // searchFormData: $scope.searchOptions.additionalRawData,
    // additionalParams: $scope.model.additionalParams,
}

interface GridStateDict {
    [gridName: string]: GridState;
}

export interface GridView {
    _id?: string;
    name?: string;

    state?: GridState;

    is_public?: boolean;
    is_default?: boolean;

    updated_when?: Date;

    can_update?: boolean;
    can_delete?: boolean;
}

interface GridViews {
    [viewName: string]: GridView;
}

interface GridViewDict {
    [gridName: string]: GridViews;
}

interface GridViewStoreState extends BaseZappsmithStoreState {
    gridStates?: GridStateDict;
    gridViews?: GridViewDict;
}

@Injectable({
    providedIn: 'root'
})
export class ZappGridViewStateStore extends BaseZappsmithComponentStore<GridViewStoreState> {
    readonly gridStates$: Observable<GridStateDict> = this.select(state => state.gridStates);
    readonly gridViews$: Observable<GridViewDict> = this.select(state => state.gridViews);

    private get storeState(): GridViewStoreState {
        return this.get();
    }

    private get gridStates(): GridStateDict {
        return this.storeState?.gridStates;
    }

    private get gridViews(): GridViewDict {
        return this.storeState?.gridViews;
    }

    constructor(private zappsmithWebService: ZappsmithWebService,
        private alertService: AlertService) {
        super();
        this.setState({
            gridStates: {},
            gridViews: {}
        });
    }

    // Grid State Methods
    saveGridState(gridName: string, stateToSave: GridState): void {
        const stateCopy: GridState = {};
        Object.assign(stateCopy, stateToSave);
        this.gridStates[gridName] = stateCopy;
    };

    getGridState(gridName: string): GridState {
        return this.gridStates[gridName];
    };

    clearGridState(gridName: string): void {
        delete this.gridStates[gridName];
    };

    // Grid View Methods
    // getGridViews(gridName: string): Observable<GridView[]> {
    //     if (!this.gridViews[gridName]) {
    //         this.retrieveViews(gridName);
    //     }
    //     return this.select(state => state.gridViews[gridName]).pipe(
    //         map((value, index) => value ? Object.values(value) : undefined)
    //     );
    // };

    observeGridViews(gridName: string): Observable<GridView[]> {
        return this.gridViews$.pipe(
            map((value, index) =>
                value && value[gridName] ? Object.values(value[gridName]) : undefined
            )
        );
    }

    getLatestDefaultGridView(gridName: string): GridView {
        if (this.gridViews[gridName]) {
            const defaultViews = Object.values(this.gridViews[gridName]).filter(gridView => {
                return gridView.is_default;
            });

            if (defaultViews?.length > 0) {
                return defaultViews.reduce((prev, current) => {
                    return prev.updated_when > current.updated_when ? prev : current;
                });
            } else {
                return null;
            }
        }

        return null;
    }

    getGridViews(gridName: string): Promise<GridViews> {
        if (!this.gridViews[gridName]) {
            return this.retrieveViews(gridName);
        } else {
            return Promise.resolve(this.gridViews[gridName]);
        }
    };

    getGridView(gridName: string, view_id: string): GridView {
        const gridViews = this.gridViews[gridName];
        if (gridViews) {
            return gridViews[view_id];
        } else {
            return undefined;
        }
    };

    saveGridView(gridName: string, gridViewToSave: GridView): Promise<GridView> {
        const params: ZappsmithBaseParmDict = {
            view_name: gridViewToSave.name,
            state: JSON.stringify(gridViewToSave.state),
            is_public: gridViewToSave.is_public,
            is_default: gridViewToSave.is_default
        };
        if (gridViewToSave._id) {
            params['existing_id'] = gridViewToSave._id;
        }
        return new Promise<GridView>((resolve, reject) => {
            this.zappsmithWebService.post('/rm_view/' + gridName, params).then(
                result => {
                    gridViewToSave._id = result?.result?.doc_id;
                    gridViewToSave.updated_when = new Date(result?.result?.updated_when);
                    gridViewToSave.can_update = true;
                    gridViewToSave.can_delete = true;

                    const gridViews = this.getOrMakeGridViews(gridName);
                    gridViews[gridViewToSave._id] = gridViewToSave;

                    this.patchState({
                        gridViews: this.gridViews
                    });

                    resolve(gridViewToSave);
                },
                result => {
                    this.alertService.addAlert({
                        title: 'Error',
                        message: "Could not save view for " + gridViewToSave.name,
                        type: AlertType.error
                    });
                    reject(null);
                }
            );
        });
    };

    deleteView(gridName: string, gridViewToDelete: GridView): Promise<boolean | any> {
        const params: ZappsmithBaseParmDict = {
            existing_id: gridViewToDelete?._id
        };

        return new Promise<boolean | any>((resolve, reject) => {
            this.zappsmithWebService.delete('/rm_view/' + gridName, params).then(
                result => {
                    const gridViews = this.getOrMakeGridViews(gridName);
                    delete gridViews[gridViewToDelete._id];

                    this.patchState({
                        gridViews: this.gridViews
                    });

                    resolve(true);
                },
                result => {
                    this.alertService.addAlert({
                        title: 'Error',
                        message: "Could not delete view: " + gridViewToDelete?.name,
                        type: AlertType.error
                    });
                    reject(result);
                }
            );
        });
    };

    private retrieveViews(gridName: string): Promise<GridViews> {
        return new Promise<GridViews>((resolve, reject) => {
            this.zappsmithWebService.get('/rm_view/' + gridName, {}).then(
                (result: any[]) => {
                    const gridViews = this.getOrMakeGridViews(gridName);

                    result?.forEach(gridViewResult => {
                        const gridView: GridView = {
                            _id: gridViewResult?._id,
                            name: gridViewResult?.view_name,
                            state: JSON.parse(gridViewResult?.state),
                            updated_when: new Date(gridViewResult?.updated_when),
                            is_public: gridViewResult?.is_public ? gridViewResult?.is_public : false,
                            is_default: gridViewResult?.is_default ? gridViewResult?.is_default : false,
                            can_update: gridViewResult?.can_update ? gridViewResult?.can_update : false,
                            can_delete: gridViewResult?.can_delete ? gridViewResult?.can_delete : false,
                        };
                        gridViews[gridView._id] = gridView;
                    });

                    this.patchState({
                        gridViews: this.gridViews
                    });

                    resolve(gridViews)
                },
                result => {
                    this.alertService.addAlert({
                        title: 'Error',
                        message: "Could not get views for " + gridName,
                        type: AlertType.error
                    });
                    reject(undefined);
                }
            );
        });
    };

    private getOrMakeGridViews(gridName: string): GridViews {
        if (!this.gridViews[gridName]) {
            this.gridViews[gridName] = {};
        }
        return this.gridViews[gridName];
    };

    // availableViewNames(gridName: string): void {
    //     //const storedValue = JSON.parse(item)
    //     return Object.keys(this.getOrMakeGridView(gridName))
    // };

    // getGridView(gridName: string, viewName: string): GridView {
    //     const gridViewDict = this.getOrMakeGridViewDict(gridName);
    //     return gridViewDict[viewName];
    // };

    // getExistingViewId(gridName: string, viewName: string): string {
    //     const cv = this.getOrMakeGridView(gridName);
    //     return !!cv[viewName] ? cv[viewName]['_id'] : null;
    // };

    // getExistingViewAllowUpdate(gridName: string, viewId: string): boolean {
    //     const gridView = this.getGridView(gridName, viewId);
    //     return gridView?.can_update;
    // };

    // getExistingViewAllowDelete(gridName: string, viewId: string): boolean {
    //     const gridView = this.getGridView(gridName, viewId);
    //     return gridView?.can_delete;
    // };
}
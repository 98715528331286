<mat-card class="nvt-surface border-base">

    <mat-card-header>
        <mat-card-title>
            {{title}}
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <!-- <form>
            <mat-form-field>
                <input matInput placeholder="New Facility Id" [(ngModel)]="facilityId">
            </mat-form-field>
        </form> -->
        <mat-form-field appearance="outline">
            <input matInput [formControl]="facilityId" placeholder="New Facility Id" (keyup.enter)="save()" />
        </mat-form-field>
    </mat-card-content>

    <mat-card-actions align="end">
        <ng-container>
            <button mat-button class="dismiss-btn" color="primary" [disabled]="!allowSave" (click)="save()">
                Ok
            </button>
        </ng-container>

        <ng-container>
            <button mat-button class="dismiss-btn" color="accent" (click)="dialogRef.close(false)">
                Cancel
            </button>
        </ng-container>
    </mat-card-actions>
</mat-card>
import { Component, Input } from '@angular/core';

import { EditorOptions } from '@dicorp/html-ffe';

@Component({
    selector: 'zs-hfe-portal-editor',
    templateUrl: 'hfe-portal-editor.component.html',
    styleUrls: ['hfe-portal-editor.component.scss']
})
export class HfePortalEditorComponent {
    private _editorOptions: EditorOptions;
    get editorOptions(): EditorOptions {
        return this._editorOptions;
    }
    @Input() set editorOptions(value: EditorOptions) {
        if (value) {
            this._editorOptions = value;
        }
    }

    constructor() {
    }
}

import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'zs-form-default-dialog',
    templateUrl: 'form-default-dialog.component.html',
    styleUrls: ['form-default-dialog.component.scss']
})
export class FormDefaultDialogComponent {

    facilityId = new FormControl<string>(null);

    get allowSave(): boolean {
        return !!this.facilityId.value;
    }

    constructor(public dialogRef: MatDialogRef<FormDefaultDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public title: string) {
    }

    save(): void {
        if (this.allowSave) {
            this.dialogRef.close(this.facilityId.value);
        }
    }
}

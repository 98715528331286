import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GridView } from 'src/component-store';
import { SessionService } from 'src/services';

@Component({
    selector: 'zs-grid-view-editor',
    templateUrl: 'grid-view-editor.component.html',
    styleUrls: ['grid-view-editor.component.scss']
})
export class GridViewEditorComponent {

    get publicViewSaveAllow(): boolean {
        return this.sessionService.hasPermission("RmViewAddLevel", 2);
    }

    constructor(private sessionService: SessionService,
        public dialogRef: DialogRef<GridView | false>,
        @Inject(DIALOG_DATA) public data: GridView) {
    }

    save(): void {
        if (this.data?.name) {
            this.dialogRef.close(this.data);
        }
    }
}

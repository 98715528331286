<!-- Loading Spinner -->
<mat-card class="board-data-load-loading" [hidden]="!showLoadingSpinner">
    <mat-spinner mode="indeterminate" diameter="200">
    </mat-spinner>
</mat-card>

<!-- Board Data Load -->
<mat-card appearance="outlined" [hidden]="showLoadingSpinner">
    <mat-card-header>
        <mat-card-title>Board Data Load</mat-card-title>
    </mat-card-header>
    <mat-card-content>

        <!-- Board Id -->
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Board Id</mat-label>
                <input matInput [(ngModel)]="boardId" [ngModelOptions]="{standalone: true}" maxlength="50" size="50" />
            </mat-form-field>
        </section>

        <section style="margin-bottom: 10px;">
            <div>
                <input #fileInput hidden="true" type="file" onclick="this.value=null"
                    (change)="fileInputChange($event)" />
                <button mat-flat-button (click)="fileInput.click()" [disabled]="!boardId">Select File to Load</button>
            </div>
        </section>

        <div class="results-div" *ngIf="results">Results: {{results}}</div>

    </mat-card-content>
</mat-card>
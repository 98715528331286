<mat-card>
    <mat-card-header>
        <!-- Record Manager Info -->
        <!-- <mat-icon *ngIf="zappAppBoard?.manifest?.icon" [hidden]="hideTitle">{{zappAppBoard?.manifest?.icon}}</mat-icon> -->
        <mat-card-title [hidden]="hideTitle">{{ zappAppBoard?.recordManagerLabel }}</mat-card-title>

        <!-- Record Manager Buttons -->
        <mat-card-actions align="end">
            <zs-hfe-grid-actions [editorOptions]="editorOptions" [gridField]="recordManagerGridField"
                [zappAppBoard]="zappAppBoard" [currentKeyValues]="currentKeyValues" [actionRules]="allowedActionRules"
                (refreshColumnsEvent)="refreshColumnsEvent()" (runActionRuleEvent)="runActionRule($event)">
            </zs-hfe-grid-actions>
        </mat-card-actions>
    </mat-card-header>
    <mat-card-content>
        <zs-hfe-portal-editor [editorOptions]="editorOptions"></zs-hfe-portal-editor>
    </mat-card-content>
</mat-card>

<ng-container *ngIf="showChildRecordManagers">

    <!-- Single Child View -->
    <ng-container *ngIf="zappAppBoard?.children?.length === 1">
        <zs-record-manager class="single-child-record-manager"
            [zappAppBoardChild]="zappAppBoard?.children[0]"></zs-record-manager>
    </ng-container>

    <!-- Tab View of Children -->
    <ng-container *ngIf="zappAppBoard?.children?.length > 1">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" preserveContent="true"
            [(selectedIndex)]="selectedChildTabIndex">
            <ng-container *ngFor="let zappAppBoardChild of zappAppBoard?.children">
                <mat-tab [label]="zappAppBoardChild.zappAppBoard?.recordManagerLabel">
                    <zs-record-manager [zappAppBoardChild]="zappAppBoardChild" [hideTitle]="true"
                        [fillHeight]="true"></zs-record-manager>
                </mat-tab>
            </ng-container>
        </mat-tab-group>
    </ng-container>

</ng-container>
import { Component } from '@angular/core';
import { AlertService, AlertType, GeneralDatasetService } from 'src/services';

@Component({
    selector: 'zs-ravs-restore',
    templateUrl: 'ravs-restore.component.html',
    styleUrls: ['ravs-restore.component.scss']
})
export class RavsRestoreComponent {
    appIdAndBoardId: string;
    uuid: string;
    version: number;

    get canRestore(): boolean {
        return this.appIdAndBoardId?.length > 0 && this.uuid?.length > 0;
    }

    constructor(private alertService: AlertService,
        private generalDatasetService: GeneralDatasetService) {
    }

    restore(): void {
        const version = this.version > 0 ? this.version.toString() : '';
        this.generalDatasetService.restore_version('/zapp/' + this.appIdAndBoardId, this.uuid, version).then(
            result => {
                this.alertService.addAlert({
                    title: 'Restore Version',
                    message: "Restored Version of Document",
                    type: AlertType.success
                });
            },
            result => {
                this.alertService.addAlert({
                    title: 'Error',
                    message: "Failed to Restore.",
                    type: AlertType.error
                });
            }
        );
    }

}


import { Component, HostListener, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import moment from 'moment';

import { EditorOptions, EditorMode } from '@dicorp/html-ffe';

import {
    SessionService,
    AlertService, AutoSaveService, RecordLockService,
    DatasetService, DatasetUtilsService, GeneralDatasetService,
    ClientModuleRulesService, ClientModuleRulesContext, ConfigurationService, AlertType,
} from 'src/services';

import { PortalFunctions, CheckFunctions, RepeaterListIterator, IMenu, IMenuItem, IHeader, FfeEditorOptions } from 'src/common';

import { ZappAppBoard, ZappAppBoardStore } from 'src/component-store';
import { HfeEditorOptionsService } from '../hfe-services';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { ConfirmationDialogService } from 'src/components/dialog-components';
import { CommonFunctions } from '@dicorp/zappsmith-ngx-core';

const AUTOSAVE_CYCLE = 6000; // one sec 60 * 1 * 1000;

@Component({
    selector: 'ffe-editor',
    templateUrl: 'ffe-editor.component.html',
    styleUrls: ['ffe-editor.component.scss']
})
export class FfeEditorComponent implements OnInit, OnDestroy {
    // private _options: FfeEditorOptions;
    // get options(): FfeEditorOptions {
    //     return this._options;
    // }
    // @Input() set options(value: FfeEditorOptions) {
    //     if (value) {
    //         this._options = value;
    //         this.initEditor();
    //     }
    // }

    // Setup properties
    private options: FfeEditorOptions;
    private queryParams: FfeEditorQueryParams;
    private zappAppBoard: ZappAppBoard;
    private zappAppBoardParent: ZappAppBoard;
    // Setup properties

    editorOptions: EditorOptions;

    editorMode: EditorMode;
    get isAddState(): boolean {
        return this.editorMode === EditorMode.Add;
    }
    get isEditState(): boolean {
        return this.editorMode === EditorMode.Edit;
    }

    context: ClientModuleRulesContext;

    _versionTag: string;
    document?: any;
    dataset?: any;

    shouldDeleteAttachments: boolean;
    recordNeedsLoading: boolean;

    isPreview?: boolean;
    isSaving?: boolean;
    isJobProcessing?: boolean;

    orig_doc: any;

    settings: any;

    dynamic_menu_dict: IMenu = {};

    checks: any[];
    repeater_checks: any[];

    longRulesRunning?: number = 0;
    lastAutoSaveWhen: Date;
    autoSavePromise?: NodeJS.Timeout;

    private clientModuleRulesLoaded: boolean;

    private editorInitialized: boolean;

    use_working_context: boolean; // TODO Remove?

    // mainForm = null;
    // save_context = null;
    // settings = {};

    get optionsInitialized(): boolean {
        return this.options && this.zappAppBoard && this.queryParams && this.clientModuleRulesLoaded;
    }

    get isNewDoc(): boolean {
        return this.context?.doc_id && this.context?.doc_id.toString() == "-1";
    }

    get isDirty(): boolean {
        return this.editorOptions?.html_ffe_api?.is_record_dirty();
    };

    get isEmbedded(): boolean {
        return !!this.dialogRef && !!this.dialogData;
    };

    get showFfeLoadingSpinner(): boolean {
        return this.isSaving || this.isJobProcessing || this.longRulesRunning > 0;
    };

    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private sessionService: SessionService,
        private configurationService: ConfigurationService,
        private alertService: AlertService,
        private datasetService: DatasetService,
        private datasetUtilsService: DatasetUtilsService,
        private generalDatasetService: GeneralDatasetService,
        private clientModuleRulesService: ClientModuleRulesService,
        private autoSaveService: AutoSaveService,
        private recordLockService: RecordLockService,
        private hfeEditorOptionsService: HfeEditorOptionsService,
        private zappAppBoardStore: ZappAppBoardStore,
        private dialog: MatDialog,
        private confirmationDialogService: ConfirmationDialogService,
        @Optional() private dialogRef: MatDialogRef<any>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: FfeEditorEmbeddedParams) {
    }

    ngOnInit(): void {
        this.clientModuleRulesLoaded = this.clientModuleRulesService.loaded;
        if (!this.clientModuleRulesLoaded) {
            const _clientModuleRulesLoadedSub = this.clientModuleRulesService.loaded$.subscribe(
                loaded => {
                    if (loaded) {
                        _clientModuleRulesLoadedSub.unsubscribe();
                        this.clientModuleRulesLoaded = loaded;
                        this.initEditor();
                    }
                });
        }

        if (!this.isEmbedded) {
            this.activatedRoute.queryParams.subscribe(queryParams => {
                this.queryParams = {
                    doc_id: queryParams['doc_id'],
                    version: queryParams['version'],
                    parentKeyField: queryParams['parentKeyField'],
                    parentKeyType: queryParams['parentKeyType'],
                    parentKey: queryParams['parentKey'],
                    backOnClose: queryParams['backOnClose']
                };

                if (queryParams['parentBoard']) {
                    this.zappAppBoardParent = this.zappAppBoardStore.getZappAppBoardById(queryParams['parentBoard']);
                }

                this.initEditor();
            });

            this.activatedRoute.data.subscribe(data => {
                this.editorMode = data['editorMode'];
                this.zappAppBoard = data['zappAppBoard'];
                this.options = PortalFunctions.copyObject(this.zappAppBoard?.ffeEditorOptions);

                this.initEditor();
            });
        } else {
            this.editorMode = this.dialogData.editorMode;
            this.zappAppBoard = this.dialogData.zappAppBoard;
            this.options = this.dialogData.options;

            this.queryParams = {
                doc_id: this.dialogData?.queryParams?.doc_id,
                version: this.dialogData?.queryParams?.version,
                parentKeyField: this.dialogData?.queryParams?.parentKeyField,
                parentKeyType: this.dialogData?.queryParams?.parentKeyType,
                parentKey: this.dialogData?.queryParams?.parentKey
            };

            this.initEditor();

            // const boardOptions = PortalFunctions.copyObject(this.zappAppBoard?.ffeEditorOptions);
            // Object.assign(boardOptions, this.options);

            // this.options = boardOptions;
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    ngOnDestroy(): void {
        // Make sure that the interval is destroyed too
        if (this.autoSavePromise) {
            clearInterval(this.autoSavePromise);
            this.autoSavePromise = null;
        }
        // make sure any locks or autosaves are removed
        this.release_record();
    }

    initEditor(): void {
        if (this.optionsInitialized) {
            if (!this.editorInitialized) {
                this.editorInitialized = true;
                this.setupOptions();
            }
        }
    }

    private setupOptions(): void {
        this.setupContext();
        this.setupFfeEditorOptions();

        this.options.parentKeyField = this.options.parentKeyField ? this.options.parentKeyField : this.queryParams.parentKeyField;
        this.options.parentKeyType = this.options.parentKeyType ? this.options.parentKeyType : this.queryParams.parentKeyType;
        this.options.parentKey = this.options.parentKey ? this.options.parentKey : this.queryParams.parentKey;

        this.options.forceReloadAfterSave = this.options.forceReloadAfterSave ?
            this.options.forceReloadAfterSave :
            this.zappAppBoard?.manifest.force_reload_after_save;

        this.options.attachmentUploadUrl = this.options.attachmentUploadUrl ?
            this.options.attachmentUploadUrl :
            this.options.baseObject + '/attachment';

        this.options.rulesActive = this.options.rulesActive === undefined ? true : this.options.rulesActive;
        this.options.autoSaveActive = this.options.autoSaveActive === undefined ? true : this.options.autoSaveActive;

        if (this.options.newOnly) {
            this.context.doc_id = "-1";
            this.editorOptions.diCancel = null;
            this.editorOptions.diSaveRecordAndAddAnother = null;
        } else if (this.options.newWithCancel) {
            this.context.doc_id = "-1";
            this.editorOptions.diCancel = null;
            this.editorOptions.diSaveRecordAndAddAnother = null;
        } else if (this.options.mode == "summary") {
            this.editorOptions.diCancel = null;
            this.editorOptions.diSaveRecord = null;
            this.editorOptions.diSaveRecordAndAddAnother = null;
            this.options.autoSaveActive = false; // no autosave on summary view
            this.editorOptions.editorMode = EditorMode.View;
        } else if (this.options.mode == "single_record") {
            this.editorOptions.diCancel = null;
            this.editorOptions.diSaveRecordAndAddAnother = null;
            this.editorOptions.editorMode = EditorMode.Edit;
            this.editorOptions.hide_save_and_exit = true;
        } else if (this.options.mode == "single_record_read_only") {
            this.editorOptions.diCancel = null;
            this.editorOptions.diSaveRecord = null;
            this.editorOptions.diSaveRecordAndAddAnother = null;
            this.editorOptions.editorMode = EditorMode.View;
            this.editorOptions.hide_save_and_exit = true;
        } else if (this.options.mode == "record_manager") {
            this.editorOptions.diCancel = null;
            this.editorOptions.diSaveRecord = null;
            this.editorOptions.diSaveRecordAndAddAnother = null;
            this.editorOptions.editorMode = EditorMode.Edit;
            this.editorOptions.hide_save_and_exit = true;
            this.options.autoSaveActive = false; // no autosave on summary view
        } else {
            const permissionBaseName = this.sessionService.fixPermissionName(
                this.options.permissionBaseName === undefined ?
                    this.options.baseObject :
                    this.options.permissionBaseName);

            // const allowAdd = this.options.allowAdd === undefined ? true :
            //     ((this.options.allowAdd) && this.options.addState && this.sessionService.hasPermission(permissionBaseName + "AddLevel"));
            const allowAdd = this.options.allowAdd === undefined ? true :
                ((this.options.allowAdd) && this.sessionService.hasPermission(permissionBaseName + "AddLevel"));
            if (!allowAdd) {
                this.editorOptions.diSaveRecordAndAddAnother = null;
            }
        }


        if (this.editorOptions.hide_save_and_exit) {
            // if we are hiding the Save and Exit, Hide Save and Add
            this.editorOptions.diSaveRecordAndAddAnother = null;
        }

        // don't allow save and add another if there if a parent key
        if (this.options.parentKey || this.options.parentKeyField) {
            this.editorOptions.diSaveRecordAndAddAnother = null;
        }

        for (var menu_name in this.options?.additionalMenus) {
            const menu = this.options?.additionalMenus[menu_name];
            this.set_standard_dynamic_menu(menu_name, menu)
        };

        // Version tag switches us to view only
        if (this.context.version != null) {
            this.editorOptions.editorMode = EditorMode.View;
            this.options.autoSaveActive = false; // no autosave on view
        }

        this.isPreview = this.options.mode == "preview";
        if (this.isPreview) {
            this.editorOptions.editorMode = EditorMode.Edit;
        }

        if (this.editorMode === EditorMode.Edit) {
            // Make sure we can lock the record first
            this.lock_record(this.context.doc_id).then(
                result => {
                    this.autoSavePromise = setInterval(() => this.checkAutoSave(), AUTOSAVE_CYCLE);
                    this.reload();
                },
                result => {
                    this.view_record(this.context.doc_id)
                });
        } else {
            this.reload();
        }
    }

    private setupFfeEditorOptions(): void {
        if (this.options.editorOptions) {
            this.editorOptions = this.options.editorOptions;
        } else {
            this.editorOptions = new EditorOptions();
        }

        this.editorOptions.htmlFfeApiChanged.subscribe(api => {
            this.options.api = api;
        });

        this.hfeEditorOptionsService.setDefaultEditorOptions(this.editorOptions, this.options, true);

        switch (this.editorMode) {
            case EditorMode.View: {
                this.editorOptions.editorMode = EditorMode.View;
                break;
            }
            case EditorMode.Add: {
                // Use Edit mode for an Add
                this.editorOptions.editorMode = EditorMode.Edit;
                break;
            }
            case EditorMode.Edit: {
                this.editorOptions.editorMode = EditorMode.Edit;
                break;
            }
        }

        if (this.options.isModal) {
            this.editorOptions.is_modal = true;
        }

        // Methods
        // Save and Cancel
        this.editorOptions.diSaveRecord = (record: any, close: boolean) => {
            return this.save_record(record, close);
        }

        this.editorOptions.diSaveRecordAndAddAnother = (record: any) => {
            return this.save_and_add_another_record(record);
        }

        this.editorOptions.diCancel = () => {
            return this.cancel();
        }

        // Rules
        this.editorOptions.diRunRule = (rule_name: string, record: any) => {
            return this.runNamedRule(rule_name, record);
        }

        // Menues
        this.editorOptions.diGetDynamicMenu = (menu_name: string, record: any) => {
            return this.get_dynamic_menu(menu_name, record);
        }

        // TODO Move this into HfeEditorOptionsService
        this.editorOptions.diViewEntity = (entity_name: string, key: string) => {
            return this.viewEntity(entity_name, key);
        }
        this.editorOptions.diAddEntity = (entity_name: string) => {
            return this.addEntity(entity_name);
        }
        this.editorOptions.diUpdateEntity = (entity_name: string, key: string) => {
            return this.updateEntity(entity_name, key);
        }
        this.editorOptions.diDeleteEntity = (entity_name: string, key: string) => {
            return this.deleteEntity(entity_name, key);
        }
        // TODO Move this into HfeEditorOptionsService

        if (this.use_working_context) {
            this.editorOptions.diPublishRecord = (record: any) => {
                return this.publish_record(record);
            }
        }

        if (this.options?.checkValidationPasswordConfig) {
            this.editorOptions.checkValidationPassword = this.configurationService.getConfigurationItem(this.options.checkValidationPasswordConfig);
        }
    };

    private setupDataset(dataset: any): void {
        this.dataset = dataset;
        if (this.dataset) {
            this.dataset['entity_map'] = this.options.entityMap;
        }

        this.editorOptions?.html_ffe_api?.load_dataset(this.dataset);
        if (this.recordNeedsLoading) {
            this.editorOptions.html_ffe_api.reload_record(this.document); // load the record now (since we normally have to after setting this
            this.recordNeedsLoading = false;
        }

        if (this.dataset && this.dataset.rules && this.dataset.rules.RUL_Check) {
            this.checks = this.dataset.rules.RUL_Check;
            this.options.has_checks = true;
        }
        if (this.dataset && this.dataset.rules && this.dataset.rules.RUL_Repeater_Check) {
            this.repeater_checks = this.dataset.rules.RUL_Repeater_Check;
            this.options.has_checks = true;
        }

        if (this.options.has_checks) {
            this.editorOptions.diRunCheck = (record: any) => {
                return this.run_check(record);
            }
        }
    };

    private loadDataset(): void {
        const dataset_name = this.getDatasetName();
        this.datasetService.get(dataset_name).then(
            result => {
                this.setupDataset(result);
            },
            result => {
                this.alertService.addAlert({
                    title: 'Error',
                    message: 'Could not get dataset',
                    type: AlertType.error
                });
            });
    };

    private getDatasetName(): string {
        if (this.isAddState && this.zappAppBoard?.add_dataset) {
            // Don't allow save and add another when doing add_dataset override
            this.editorOptions.hide_save_and_exit = true;
            this.editorOptions.hide_save_and_add_another = true;
            this.options.forceReloadAfterSave = true;
            return this.zappAppBoard?.add_dataset;
        } else if (this.document?.dataset_name) {
            return this.document.dataset_name;
        } else {
            const dataset_name = (!!(this.options.datasetFieldLookup) ? PortalFunctions.deep_value(this.document, this.options.datasetFieldLookup) :
                (!!(this.options.datasetFieldMapping) && !!(this.options.datasetFieldPath) ?
                    this.options.datasetFieldMapping[PortalFunctions.deep_value(this.document, this.options.datasetFieldPath)] :
                    this.options.dataSet));
            return dataset_name;
        }
    }

    private setDefaultHeaders(new_doc: any, checkForNesting: boolean = false) {
        if (checkForNesting && !!(new_doc[this.options.nestedData])) {
            const working_id = new_doc['_id'];
            new_doc = new_doc[this.options.nestedData];
            new_doc['_id'] = working_id;
        }

        if (!this.editorOptions.headers) {
            this.editorOptions.headers = [];

            let the_options = !!(this.options.headers) ? this.options.headers : null;
            if (this.isNewDoc && !!(this.options.new_headers)) {
                the_options = this.options.new_headers;
            }

            if (the_options != null) {
                the_options.forEach(value => {
                    let the_val: any = null;
                    if (!!(value.path) && value.path) {
                        the_val = "";
                        [].concat(value.path).forEach(v => {
                            if (v.substring(0, 1) == '"') {
                                the_val += v.replace(/['"]+/g, '')
                            } else {
                                let wv = PortalFunctions.deep_value(new_doc, v);
                                if (wv == null || wv == undefined) {
                                    wv = "";
                                } else if (PortalFunctions.isDateObj(wv)) {
                                    wv = wv.getFullYear() + '-' + (wv.getMonth() + 1) + "-" + wv.getDate();
                                } else if (typeof wv === "string" && wv.endsWith("T00:00:00+00:00")) {
                                    wv = wv.substring(0, wv.length - 15)
                                }

                                the_val += wv;
                            }
                        }
                        );
                        // if (the_val.length > 25) {
                        //     // truncate header parts
                        //     the_val = the_val.substring(0, 25);
                        // }
                    }
                    if (!!(value.mappings) && value.mappings) {
                        if (value.mappings.hasOwnProperty(the_val)) {
                            the_val = value.mappings[the_val];
                        } else if (value.mappings.hasOwnProperty('default')) {
                            the_val = value.mappings['default'];
                        }
                    }

                    this.editorOptions.headers.push({
                        label: value.label,
                        value: the_val
                    });
                }
                );
            } else {
                this.editorOptions.headers.push({
                    label: this.options.label,
                    value: this.context.doc_id
                });
            }
            if (this.context.version) {
                this.editorOptions.headers.push({
                    label: "Version",
                    value: this.context.version
                })
            }

            if (this.use_working_context) {
                this.editorOptions.headers.push({
                    label: "Working Version",
                    value: this.document._working_version
                })
            }
        }
    };

    gotoState(newState: string, doc_id: string = null, confirmExit: boolean = null) {
        if (this.isEmbedded) {
            this.alertService.addAlert({
                title: 'Warning',
                message: 'Cannot use gotoState method inside an embedded editor',
                type: AlertType.warning
            });
            return;
        }
        if (confirmExit !== null) {
            this.options.confirm_exit = confirmExit;
        }
        if (doc_id) {
            this.router.navigate([newState], {
                replaceUrl: true,
                onSameUrlNavigation: 'reload',
                queryParams: { doc_id: doc_id }
            });
        } else {
            this.router.navigate([newState], {
                replaceUrl: true,
                onSameUrlNavigation: 'reload',
            });
        }
    };

    checkAutoSave() {
        if (!this.isNewDoc && this.editorOptions?.html_ffe_api && this.editorMode === EditorMode.Edit) {
            const lmw = this.editorOptions.html_ffe_api.last_modified_when();
            if (this.lastAutoSaveWhen != lmw) {
                this.autoSaveService.save(
                    this.options.baseObject,
                    this.editorOptions.html_ffe_api.HtmlFFEService.gets.get_active_record()
                );
                this.lastAutoSaveWhen = lmw;
            }
        }
    };

    get_dynamic_menu(menu_name: string, record: any): Promise<IMenuItem[]> {
        return Promise.resolve(this.dynamic_menu_dict[menu_name] ? this.dynamic_menu_dict[menu_name] : null);
    };

    activeRecord(): any {
        return this.editorOptions?.html_ffe_api?.HtmlFFEService?.gets?.get_active_record();
    };

    // FFE Editor options methods

    save_record(record: any, close: boolean) {
        this.actual_save(record, close, false)
    };

    save_and_add_another_record(record: any) {
        this.actual_save(record, false, true);
    };

    publish_record(record: any) {
        this.generalDatasetService.publish_working(this.options.baseObject, this.context.doc_id).then(
            result => {
                this.alertService.addAlert({
                    title: 'Published',
                    message: "Record Published",
                    type: AlertType.success
                });
                this.options.confirm_exit = false;
                this.close(this.context.doc_id);

            },
            result => {
                this.alertService.addAlert({
                    title: 'Error',
                    type: AlertType.error,
                    message: 'Could not get dataset'
                });
                this.alertService.addAlert({
                    title: 'Error',
                    message: "Could not publish record",
                    type: AlertType.error
                });
            });
    };

    cancel() {
        this.release_record();
        this.close(false);
    };

    getEntityMap(entityName: string): { [key: string]: string; } {
        return this.options?.entityMap[entityName];
    };

    // TODO Move this into HfeEditorOptionsService
    loadNestedFfe(entity_name: string, editorMode: EditorMode, key?: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let nestedBoard = this.zappAppBoardStore.getZappAppBoard(entity_name);

            if (!nestedBoard && this.zappAppBoard?.entityMap && this.zappAppBoard?.entityMap[entity_name]['object_name']) {
                nestedBoard = this.zappAppBoardStore.getZappAppBoard(this.zappAppBoard?.entityMap[entity_name]['object_name']);
            }

            if (!nestedBoard) {
                this.alertService.addAlert({
                    title: 'Error',
                    message: 'Could not get board for baseObject: ' + entity_name,
                    type: AlertType.error
                });
                reject();
            }

            const nestedBoardOptions: FfeEditorOptions = nestedBoard.ffeEditorOptions;
            nestedBoardOptions.isModal = true;

            const nestedBoardEditorOptions = new EditorOptions();
            nestedBoardEditorOptions.hide_publish = true;
            nestedBoardEditorOptions.hide_save_and_add_another = true;
            nestedBoardEditorOptions.hide_save_and_exit = true;

            nestedBoardOptions.editorOptions = nestedBoardEditorOptions;

            const dialogData: FfeEditorEmbeddedParams = {
                editorMode: editorMode,
                zappAppBoard: nestedBoard,
                options: nestedBoardOptions,
                queryParams: {
                    doc_id: editorMode !== EditorMode.Add ? key : '-1'
                }
            }

            if (this.zappAppBoard?.entityMap && this.zappAppBoard?.entityMap[entity_name]['default_add_path']) {
                const default_add_path = this.zappAppBoard?.entityMap[entity_name]['default_add_path'];
                const record = this.editorOptions?.html_ffe_api?.HtmlFFEService?.gets?.get_active_record();
                const defaultAddData = CommonFunctions.resolvePath(default_add_path, record);
                if (defaultAddData) {
                    dialogData.defaultAddData = defaultAddData;
                }
            }

            const dialogRef = this.dialog.open(FfeEditorComponent, {
                data: dialogData,
                disableClose: true,
                closeOnNavigation: true,
                hasBackdrop: true
            });

            dialogRef.afterClosed().subscribe(
                doc_id => {
                    if (doc_id) {
                        const entityMap = this.zappAppBoard.entityMap;
                        this.datasetUtilsService.clearEntityCache(entity_name, entityMap);
                        this.resetEntityCache(entity_name);
                        this.datasetUtilsService.getEntity(entity_name, entityMap, doc_id).then(
                            document => {
                                resolve(document)
                            },
                            result => {
                                reject(result)
                            });
                    } else {
                        reject();
                    }
                });
        });
    }

    updateEntity(entityName: string, key: string): Promise<any> {
        if (this.hfeEditorOptionsService.hasEntityPermission(this.options, entityName, "Update")) {
            return this.loadNestedFfe(entityName, EditorMode.Edit, key);
        } else {
            return Promise.reject("No permissions to update")
        }
    };

    viewEntity(entityName: string, key: string): Promise<void> {
        if (this.hfeEditorOptionsService.hasEntityPermission(this.options, entityName, "Search")) {
            return this.loadNestedFfe(entityName, EditorMode.View, key);
        } else {
            return Promise.reject("No permissions to view")
        }

    };

    addEntity(entityName: string): Promise<any> {
        if (this.hfeEditorOptionsService.hasEntityPermission(this.options, entityName, "Add")) {
            return this.loadNestedFfe(entityName, EditorMode.Add);
        } else {
            return Promise.reject("No permissions to add")
        }
    };

    deleteEntity(entityName: string, control_number: string): Promise<void> {
        if (this.hfeEditorOptionsService.hasEntityPermission(this.options, entityName, "Delete")) {
            return this.datasetUtilsService.deleteEntity(entityName, this.options?.entityMap, control_number);
        } else {
            return Promise.reject("No permissions to delete")
        }
    };
    // TODO Move this into HfeEditorOptionsService

    resetEntityCache(entityName: string) {
        this.editorOptions?.html_ffe_api?.reset_entity_cache(entityName);
    }

    resetEntitiesCache() {
        this.editorOptions?.html_ffe_api?.reset_entities_cache();
    }

    runNamedRule(fullRuleName: string, record: any = this.document, results: any[] = null, postRules: string[] = null): Promise<any[]> {
        return new Promise<any[]>((resolve, reject) => {
            if (results === null) {
                results = [];
            }
            const commaPos = fullRuleName.indexOf(',');
            let ruleToUse = commaPos < 0 ? fullRuleName : fullRuleName.substr(0, commaPos);
            //ruleToUse = ruleToUse.trim();
            // since I don't want to stress polyfill on a monday ...
            ruleToUse = ruleToUse.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
            let force_to_continue = false;
            const plusPos = fullRuleName.indexOf('+');
            if (plusPos > 0) {
                ruleToUse = ruleToUse.substr(0, plusPos);
                force_to_continue = true;
            }

            const rule = this.editorOptions.html_ffe_api.HtmlFFEService.finds.find_rule(ruleToUse);

            if (rule) {
                if (commaPos >= 0) {
                    rule.chain_rule = fullRuleName.substr(commaPos + 1); // get the remaining stuff
                }
                this.run_rule(rule, record, results, force_to_continue, postRules).then(resolve, reject);
            } else {
                console.error("Rule " + ruleToUse + " not found");
                reject("Rule " + ruleToUse + " not found");
            }
        });
    };

    run_rule(rule: any, record: any, results: any[] = null, force_continue: boolean = false, postRules: string[] = null): Promise<any[]> {
        return new Promise<any[]>((resolve, reject) => {
            if (results === null) {
                results = [];
            }

            if (postRules === null) {
                postRules = [];
            }

            if (!this.options?.rulesActive) {
                if (rule.kind == "RUL_Eval") {
                    resolve([{ 'rule': rule, 'result': { 'value': true } }]); // rules are not active so just return ok...
                } else if (rule.kind == "RUL_Check" || rule.kind == "RUL_Repeater_Check" || rule.kind == "RUL_Skip") {
                    resolve([{ 'rule': rule, 'result': { 'value': false } }]); // no check or skip
                }
            } else {
                if (rule.kind == "RUL_Speed")
                    resolve([{ 'rule': rule, 'result': { 'value': -1, 'message': "Speed Rule" } }]);
                else {
                    if (rule.is_long_running && rule.is_long_running.toLowerCase() == "true") {
                        this.longRulesRunning++;
                    }
                    this.datasetUtilsService.runRulePromise(rule, record, this.options, this.context).then(
                        result => {
                            // is_long_running
                            if (rule.is_long_running && rule.is_long_running.toLowerCase() == "true") {
                                this.longRulesRunning--;
                            }

                            if (result) {
                                if (!!(result.alert)) {
                                    // chain the results of an alert as well
                                    results.push({ 'rule': rule, 'result': result });

                                    this.datasetUtilsService.handleRuleResultAlert(result).then(
                                        data => {
                                            const new_rule = PortalFunctions.copyObject(rule);
                                            new_rule.clisp_module = data; // value is the rule name
                                            this.run_rule(new_rule, record, results, force_continue, postRules).then(resolve, reject);
                                        });

                                } else { // not an alert
                                    if (result.message) {
                                        let message_parts: string[] = result.message.split(':');
                                        if (message_parts.length == 1) {
                                            message_parts = result.message.split('__SPLIT__');
                                        }

                                        this.alertService.addAlert({
                                            title: message_parts.length == 1 ? 'Error' : message_parts[0],
                                            message: message_parts.length == 1 ? message_parts[0] : message_parts.slice(1).join(':'),
                                            type: AlertType.error
                                        });
                                    }
                                    const postRule = (result.value && rule.post_success_rule) ? rule.post_success_rule :
                                        ((!result.value && rule.post_failure_rule) ? rule.post_failure_rule : null);

                                    if (postRule) {
                                        postRules.push(postRule)
                                    }
                                    // chain if it makes sense
                                    if ((force_continue || result.value) && (!!(result.chain_rule) || !!(rule.chain_rule))) { // was result.value &&
                                        // still send the results to success even though chained for each part
                                        results.push({ 'rule': rule, 'result': result });

                                        if (!!(result.chain_rule)) // if we are chaining then keep going
                                        {
                                            this.runNamedRule(result.chain_rule, record, results, postRules).then(resolve, reject);
                                        } else if (!!(rule.chain_rule)) // if we are chaining then keep going
                                        {
                                            this.runNamedRule(rule.chain_rule, record, results, postRules).then(resolve, reject);
                                        }
                                    } else {
                                        // if last link in chain or the chain failed push and then run
                                        results.push({ 'rule': rule, 'result': result });
                                        if (postRules.length > 0) {
                                            this.editorOptions.html_ffe_api.process_rule_results(results);
                                            results = [];
                                            const chained: string = postRules.join('+,');
                                            this.runNamedRule(chained, record, results, null).then(resolve, reject);
                                        } else {
                                            resolve(results);
                                        }
                                    }
                                }
                            } else {
                                reject();
                            }
                        },
                        result => {
                            reject();
                        }
                    );
                }
            }
        });

    };

    private actual_save(record: any, close: boolean, add_after: boolean) {
        // $anchorScroll(); // TODO
        if (this.isSaving) {
            this.alertService.addAlert({
                title: 'Already Saving',
                message: "We are already in process of saving this record. Please wait a moment and try again",
                type: AlertType.warning
            });

            return;
        }

        if (this.isPreview) {
            this.alertService.addAlert({
                title: 'Save Simulated',
                message: "Preview Mode",
                type: AlertType.warning
            });
            this.options.confirm_exit = false;
            if (close) {
                this.close();
            }
        } else {
            // fixup _Root if changed
            if (!!record._Root) {
                if (!!(record._Root.PrimaryFacilityKey) && !!(record._Root.PrimaryFacilityKey['#value']) && record._Root.PrimaryFacilityKey['#value']) {
                    record['primary_facility_key'] = record._Root.PrimaryFacilityKey['#value'];
                    record['primary_facility'] = {
                        'key': record._Root.PrimaryFacilityKey['#value'],
                        'id': record._Root.PrimaryFacilityKey['@id'],
                        'name': record._Root.PrimaryFacilityKey['@name']
                    };
                }
                if (!!record._Root.DatasetName && !!record._Root.DatasetName['#value']) {
                    record['dataset_name'] = record._Root.DatasetName['#value'];
                }
                if (!!record._Root.PrimaryUserKey && !!record._Root.PrimaryUserKey['#value']) {
                    record['primary_user_key'] = record._Root.PrimaryUserKey['#value'];
                }
            }
            if (!!(record._Root)) {
                delete record._Root;
            }
            if (!!(record._SessionVariables)) {
                delete record._SessionVariables;
            }
            if (!!(record._Temp)) {
                delete record._Temp;
            }
            let rec_to_save = record;
            if (!!(this.options?.nestedData) && this.options?.nestedData) {

                const tmp_rec_to_save = this.context.orig_doc;
                tmp_rec_to_save[this.options.nestedData] = rec_to_save;
                rec_to_save = tmp_rec_to_save;
            }

            this.isSaving = true;

            if (this._versionTag) {
                rec_to_save['_version'] = this._versionTag;
            }

            close = this.isEmbedded ? true : close;

            this.perform_upsert(rec_to_save, close, add_after);

        }
    };

    private close(document?: any): void {
        if (this.isEmbedded) {
            this.dialogRef.close(document);
        } else {
            if (this.queryParams?.backOnClose) {
                this.location.back();
            } else if (this.zappAppBoardParent) {
                this.gotoState(this.zappAppBoardParent?.recordManagerPath);
            } else {
                this.gotoState(this.zappAppBoard?.recordManagerPath);
            }
        }
    }

    private perform_upsert(rec_to_save: any, close: boolean, add_after: boolean): void {
        this.generalDatasetService.upsert(this.options.baseObject, rec_to_save, this.use_working_context).then(
            result => {
                this.options.confirm_exit = false;
                if (close) {
                    this.release_record();
                    this.close(result.doc_id);
                } else if (add_after) {
                    this.release_record();
                    this.add_record();
                } else {
                    this.alertService.addAlert({
                        title: 'Record',
                        message: "Saved",
                        type: AlertType.success
                    });
                    if (this.isAddState) {
                        if (this.options?.forceReloadAfterSave) {
                            // this.reload(result.doc_id);
                            this.gotoState(this.zappAppBoard?.editRecordPath, result.doc_id);
                        } else {
                            this.update_version(rec_to_save);
                            this.setDefaultHeaders(rec_to_save, true);
                            this.editorMode = EditorMode.Edit;
                            this.lock_record(result.doc_id).then(
                                lock_record_result => {
                                    this.edit_record(result.doc_id);
                                },
                                lock_record_result => {
                                    this.view_record(result.doc_id);
                                }
                            );
                        }
                    } else if (this.isEditState) {
                        if (this.context.doc_id !== result.doc_id) {
                            this.release_record();
                            this.edit_record(result.doc_id);
                        } else {
                            this.lock_record().then(
                                lock_record_result => {
                                    if (this.options?.forceReloadAfterSave) {
                                        this.reload();
                                    } else {
                                        this.update_version(rec_to_save);
                                        this.setDefaultHeaders(rec_to_save, true);
                                    }
                                },
                                lock_record_result => {
                                    if (this.options?.forceReloadAfterSave) {
                                        this.reload();
                                    } else {
                                        this.update_version(rec_to_save);
                                        this.setDefaultHeaders(rec_to_save, true);
                                    }
                                }
                            );
                        }
                    }
                }
                this.isSaving = false;

            },
            result => {
                this.isSaving = false;
                const message = result?.error?.message ? result.error?.message : "Could not upsert Record";
                this.alertService.addAlert({
                    title: 'Error',
                    message: message,
                    type: AlertType.error
                });
            });
    };

    private update_version(rec_to_save: any): void {
        if (rec_to_save.hasOwnProperty('_version')) {
            this._versionTag = rec_to_save['_version'] += 1;
        }
    }

    private release_record(doc_id?: string): Promise<void> {
        if (this.isAddState) {
            return Promise.reject();
        }

        if (!doc_id) {
            doc_id = this.context?.doc_id;
        }

        if (this.options?.autoSaveActive && !this.isAddState) {
            this.autoSaveService.release(this.options.baseObject, doc_id).then(
                result => {
                    // ignore good
                },
                result => {
                    if (result.status == 403) {
                        // ignore release failure if not logged in
                    } else {
                        this.alertService.addAlert({
                            title: 'Error',
                            message: "Error releasing autosave",
                            type: AlertType.error
                        });
                    }
                })
        }

        const lockKey = this.lock_key(doc_id);
        if (!lockKey) {
            return Promise.reject();
        }

        return new Promise<void>((resolve, reject) => {
            this.recordLockService.unlock(lockKey).then(
                result => {
                    resolve();
                },
                result => {
                    console.error("Could not unlock record ");
                    reject();
                    // AlertService.addAlert({
                    //     title: 'Error',
                    //     message: "Could not release lock on the record",
                    //     type: 'errorAlert', // this has to match the alert-type attribute
                    //     alertClass: 'alert-danger' //the alert element will have this class, good for css styling
                    // });
                });
        });
    };

    private lock_record(doc_id?: string): Promise<void> {
        if (this.isAddState) {
            return Promise.reject();
        }

        const lockKey = this.lock_key(doc_id);
        if (!lockKey) {
            return Promise.reject();
        }

        return new Promise<void>((resolve, reject) => {
            this.recordLockService.lock(lockKey).then(
                result => {
                    resolve();
                },
                result => {
                    this.alertService.openOkAlert({
                        title: 'Error',
                        message: result?.statusText ? result?.statusText : "Could not obtain lock to edit record",
                        type: AlertType.error
                    }).then(
                        () => {
                            reject();
                        }
                    )
                });
        });
    };

    private lock_key(doc_id?: string) {
        if (!doc_id) {
            doc_id = this.context?.doc_id;
        }

        if (!doc_id) {
            return null;
        }

        return this.options.baseObject + doc_id;
    };

    private add_record() {
        if (this.isAddState) {
            this.reload();
        } else {
            const queryParams: Params = {
                doc_id: -1,
            };
            if (this.options?.parentKey) {
                queryParams['parentKey'] = this.options?.parentKey;
            }

            this.router.navigate([this.zappAppBoard?.addRecordPath], {
                onSameUrlNavigation: 'reload',
                skipLocationChange: true,
                queryParams
            });
        }
    };

    private edit_record(doc_id: string) {
        this.gotoState(this.zappAppBoard?.editRecordPath, doc_id);
    }

    private view_record(doc_id: string) {
        this.gotoState(this.zappAppBoard?.viewRecordPath, doc_id);
    }

    private run_check(record: any): Promise<any[]> {
        if (!this.options?.has_checks) {
            return Promise.resolve([]);
        }
        if (this.checks || this.repeater_checks) {
            return new Promise<any[]>((resolve, reject) => {
                const promises: Promise<any>[] = [];
                // inner common function
                const eval_check = (check: any) => {
                    const is_repeater = check.kind == "RUL_Repeater_Check";
                    let validated = !is_repeater && CheckFunctions.checkValidField(record, check);
                    if (!validated) {
                        if (is_repeater && check.list_dm_reference) {
                            const mdc = new RepeaterListIterator(record, check);
                            if (mdc) {
                                const orig_message = check.message;
                                const orig_vali_field = check.vali_field;
                                for (let currentIndexReplacement = mdc.next(); currentIndexReplacement != null; currentIndexReplacement = mdc.next()) {
                                    if (currentIndexReplacement) {
                                        // recompute validated field and make a message copy of the rule
                                        if (orig_message || orig_vali_field) {
                                            check = Object.assign({}, check); // since we are manipulating the .message
                                            //rule = newRule;
                                            check.message = PortalFunctions.convertCurrentIndexes(orig_message, currentIndexReplacement, 1); //offset by 1
                                            check.vali_field = PortalFunctions.convertCurrentIndexes(orig_vali_field, currentIndexReplacement);
                                            validated = CheckFunctions.checkValidField(record, check);
                                        }
                                        if (!validated) {
                                            promises.push(this.datasetUtilsService.runRulePromise(check, record, this.options, this.context, currentIndexReplacement));
                                        }
                                    }
                                }
                            }
                        } else if (!is_repeater) {
                            promises.push(this.datasetUtilsService.runRulePromise(check, record, this.options, this.context));
                        }
                    }
                };

                this.checks.forEach(check => { eval_check(check); });
                this.repeater_checks.forEach(check => { eval_check(check); });

                Promise.all(promises).then(
                    rule_results => {
                        const results: any[] = [];
                        rule_results.forEach(
                            rr => {
                                if (rr) {
                                    if (rr.value) {
                                        if (rr.paths === null || rr.paths.length == 0) {
                                            rr.paths = ["."];
                                        }
                                        if (rr.message === null) {
                                            rr.message = rr.rule.message;
                                        }
                                        rr.ffe_options = null;
                                        rr.context = null;

                                        if (Array.isArray(rr.message)) {
                                            (rr.message as any[]).forEach(m => {
                                                let new_rr = PortalFunctions.copyObject(rr);
                                                new_rr.message = m;
                                                results.push({ 'rule': rr.rule, 'result': new_rr });
                                            })
                                        } else {
                                            results.push({ 'rule': rr.rule, 'result': rr });
                                        }
                                    }
                                }
                            });

                        resolve(results);
                    });
            });
        } else {
            return Promise.resolve([]);
        }
    };

    private reload(doc_id?: string, auto_save: boolean = true) {
        if (doc_id) {
            this.context.doc_id = doc_id;
        }
        let is_saving = this.isSaving;
        this.generalDatasetService.get(this.options.baseObject, this.context.doc_id, this.context.version, this.use_working_context).then(
            result => {
                let load_data = result;
                if (this.context.doc_id === '-1' && this.dialogData?.defaultAddData) {
                    load_data = this.dialogData?.defaultAddData;
                }
                if (!this.context.doc_id && Array.isArray(load_data)) { // handle the fringe case of the load_data being an object
                    load_data = {}; // [] -> {}
                }
                if (auto_save && this.options?.autoSaveActive && !is_saving) {
                    this.autoSaveService.load(this.options.baseObject, this.context.doc_id).then(
                        result => {
                            const working_result = result;
                            if (working_result?.hasOwnProperty('autosave_created_when')) {
                                this.confirmationDialogService.openConfirmationDialog({
                                    title: 'Restore Auto Save',
                                    message: "An auto-save record was found on " +
                                        moment(working_result['autosave_created_when']).format('YYYY-MM-DD HH:mm') +
                                        ". Do you wish to restore it"
                                }).then(result => {
                                    load_data = result ? working_result : load_data;
                                    this.setup_form_with_data(load_data);
                                });
                            } else {
                                this.setup_form_with_data(load_data);
                            }
                        },
                        result => {
                            // Let's just skip the error here. This could be a 403 permission issue
                            // AlertService.addAlert({
                            //     title: 'Error',
                            //     message: "Could not get auto-save. Loading latest",
                            //     type: 'errorAlert', // this has to match the alert-type attribute
                            //     alertClass: 'alert-danger' //the alert element will have this class, good for css styling
                            // });
                            this.setup_form_with_data(load_data);
                        });
                } else {
                    this.setup_form_with_data(load_data);
                }
            },
            result => {
                this.alertService.addAlert({
                    title: 'Error',
                    message: "Could not get record",
                    type: AlertType.error
                });
            });

    };

    // private routeReload(doc_id: string, edit_mode: string): void {
    //     this.router.navigate(['/'], {
    //         queryParamsHandling: 'merge',
    //         queryParams: {
    //             doc_id: doc_id,
    //             edit_mode: edit_mode
    //         }
    //     });
    // }

    private setup_form(new_doc: any) {
        const realOrigDoc = new_doc;
        const orig_primary_key = !!(new_doc.primary_facility_key) ? new_doc.primary_facility_key :
            (!!(new_doc.primary_facility_link) ? new_doc.primary_facility_link : null);
        const orig_primary_id = !!(new_doc.primary_facility) ? new_doc.primary_facility.id :
            (!!(new_doc.primary_facility_link_id) ? new_doc.primary_facility_link_id : null);
        const orig_primary_name = !!(new_doc.primary_facility) ? new_doc.primary_facility.name :
            (!!(new_doc.primary_facility_link_name) ? new_doc.primary_facility_link_name : null);
        const orig_primary_user_key = !!(new_doc.primary_user_key) ? new_doc.primary_user_key :
            null;

        this.orig_doc = new_doc;
        if (!!(this.options.nestedData) && this.options.nestedData) {
            if (!!(new_doc[this.options.nestedData])) {
                const working_id = new_doc['_id'];
                new_doc = new_doc[this.options.nestedData];
                new_doc['_id'] = working_id;
            } else {
                // if we didn't have existing this is likely a new doc so let's wrap it back up
                const tmp_doc: any = {};
                tmp_doc[this.options.nestedData] = new_doc;
                // handle a case where we are loading based on the autosave in which case we need to use the doc's _id
                const working_id = new_doc['_id'];
                tmp_doc['_id'] = working_id ? working_id : -1;
                this.orig_doc = tmp_doc
            }
        }

        if (!!(this.options.prefixData) && this.options.prefixData) {
            new_doc = {};
            new_doc[this.options.prefixData] = new_doc;
        }

        // copy additional fields
        if (!!(this.options.secondaryData) && this.options.secondaryData) {
            for (let s of this.options.secondaryData) {
                if (realOrigDoc.hasOwnProperty(s)) {
                    Object.assign(new_doc, realOrigDoc[s]);
                }
            }
        }

        //copy additional raw data
        if (!!(this.options.additionalRawData) && this.options.additionalRawData) {
            Object.assign(new_doc, this.options.additionalRawData);
        }


        // copy tracking info to root
        if (orig_primary_key) {
            // exists and is not Null
            new_doc['_Root'] = {
                'PrimaryFacilityKey': {
                    '#value': orig_primary_key,
                    '@id': orig_primary_id,
                    '@name': orig_primary_name
                },
                'PrimaryUserKey': {
                    '#value': orig_primary_user_key,
                },
                "DocumentUUID": {
                    '#value': new_doc['_id']
                },
                "CreatedWhen": {
                    '#value': new_doc['created_when']
                },
                "UpdatedWhen": {
                    '#value': new_doc['updated_when']
                }

            }
        }
        new_doc['_SessionVariables'] = {
            'CurrentFacilityLink': {
                '#value': this.sessionService.model.facilityKey,
                '@id': this.sessionService.model.facilityId,
                '@name': this.sessionService.model.facilityName
            },
            'CurrentOrigFacilityLink': {
                '#value': this.sessionService.model.facilityOrigKey,
                '@id': this.sessionService.model.facilityId,
                '@name': this.sessionService.model.facilityName
            },
            'CurrentUserLink': {
                '#value': this.sessionService.model.userKey,
                '@id': this.sessionService.model.userId,
                '@name': this.sessionService.model.userName
            },

            'When': {
                '#value': new Date()
            },
            'TenantDetails': this.sessionService.model.tenantDetails,
            'PersonInfo': this.sessionService.model.personInfo,
        };

        if (this.isNewDoc && this.options?.parentKey && this.options?.parentKeyField) {
            PortalFunctions.set_deep_value(new_doc, this.options?.parentKeyField, this.options?.parentKey);
            if (this.options?.parentKeyType && this.options?.parentKey) {
                const parentLookupSuccess = (res: any) => {
                    PortalFunctions.set_deep_value(new_doc, this.options?.parentKeyField.replace("#value", "@id"), res.id);
                    PortalFunctions.set_deep_value(new_doc, this.options?.parentKeyField.replace("#value", "@name"), res.name);
                };

                this.datasetUtilsService.getEntity(this.options?.parentKeyType, this.options?.entityMap, this.options?.parentKey).then(parentLookupSuccess);
            }
        }

        this.document = new_doc; // {'NtdsRecord':result};
        this.recordNeedsLoading = true;
        this.setDefaultHeaders(new_doc, false);
    };

    private setup_form_with_data(form_data: any) {
        this.setup_form(form_data);
        this.datasetUtilsService.getSettings([this.getDatasetName()]).then(
            result => {
                this.settings = {};
                if (result) {
                    (result as any[]).forEach(value => {
                        this.settings[value.name] = value;
                    });
                    this.loadDataset();
                } else {
                    console.error("Could not get settings from " + result);
                }
            },
            result => {
                this.alertService.addAlert({
                    title: 'Error',
                    message: "Could not get settings",
                    type: AlertType.error
                });
            }
        );
    };

    // Context Methods used by client modules rules
    setupContext(): void {
        // TODO create getDefaultContext method in clientModuleRulesService
        this.context = {
            doc_id: this.options?.doc_id ? this.options.doc_id : this.queryParams.doc_id,
            version: this.options?.version ? this.options.version : this.queryParams.version,
            orig_doc: this.orig_doc,
            model: {
                mode: this.editorMode
            },
            getHtmlFfeApi: () => {
                return this.editorOptions?.html_ffe_api;
            },
            reload: () => {
                return this.reload()
            },
            cancel: () => {
                return this.cancel()
            },
            lowLevelSave: (record) => {
                return this.lowLevelSave(record)
            },
            calculateActiveStatus: (defaultActiveStatus) => {
                return this.calculateActiveStatus(defaultActiveStatus)
            },
            hasAnyPermission: (permission: string) => {
                return this.sessionService.hasAnyPermission(permission)
            },
            getPermission: (permission: string) => {
                return this.sessionService.getPermission(permission);
            },
            get_setting: (name: string) => {
                return this.get_setting(name);
            },
            getConfigurationItem: (name, defaultValue) => {
                return this.configurationService.getConfigurationItem(name, defaultValue);
            },
            gotoState: (newState: string, doc_id: string, confirmExit: boolean) => {
                return this.gotoState(newState, doc_id, confirmExit)
            },
            get_standard_dynamic_menu: (menu_name: string, record: any) => {
                return this.get_standard_dynamic_menu(menu_name, record);
            },
            set_standard_dynamic_menu: (menu_name: string, menu: IMenuItem[]) => {
                return this.set_standard_dynamic_menu(menu_name, menu);
            },
            refreshDynamicMenu: (menu_name: string) => {
                this.editorOptions?.html_ffe_api?.refresh_menu(menu_name);
            },
            refreshFieldPath: (field_path: string) => {
                this.editorOptions?.html_ffe_api?.refresh_field_path(field_path);
            },
            viewEntity: (entityName: string, key: string) => {
                return this.viewEntity(entityName, key);
            },
            updateEntity: (entityName: string, key: string) => {
                return this.updateEntity(entityName, key);
            },
            resetEntitiesCache: () => {
                return this.resetEntitiesCache()
            },
            resetEntityCache: (entityName: string) => {
                return this.resetEntityCache(entityName);
            },
            openCheckMode: () => {
                return this.editorOptions.html_ffe_api.open_check_mode();
            },
            setHeaders: (headers: IHeader[]) => {
                return this.setHeaders(headers);
            },
            hasAnyLicense: (licenses: string[]) => {
                return this.configurationService.hasAnyLicense(licenses);
            },
            lookupMenuValue: (mdlPath: string, value: any) => {
                return this.lookupMenuValue(mdlPath, value);
            },
            frm_rule_success: (results: any[]) => {
                this.editorOptions?.html_ffe_api?.process_rule_results(results);
            },
            frm_rule_failure: (results: any[]) => {
                // nothing for now
            }
        }

    };

    lowLevelSave(record: any): void {
        // fixup _Root if changed
        if (!!record._Root) {
            if (!!(record._Root.PrimaryFacilityKey) && !!(record._Root.PrimaryFacilityKey['#value']) && record._Root.PrimaryFacilityKey['#value']) {
                record['primary_facility_key'] = record._Root.PrimaryFacilityKey['#value'];
                record['primary_facility'] = {
                    'key': record._Root.PrimaryFacilityKey['#value'],
                    'id': record._Root.PrimaryFacilityKey['@id'],
                    'name': record._Root.PrimaryFacilityKey['@name']
                };
            }
            if (!!record._Root.DatasetName && !!record._Root.DatasetName['#value']) {
                record['dataset_name'] = record._Root.DatasetName['#value'];
            }
        }
        if (!!(record._Root)) {
            delete record._Root;
        }
        if (!!(record._SessionVariables)) {
            delete record._SessionVariables;
        }
        let rec_to_save = record;
        if (!!(this.options.nestedData) && this.options.nestedData) {

            const tmp_rec_to_save = this.context.orig_doc;
            tmp_rec_to_save[this.options.nestedData] = rec_to_save;
            rec_to_save = tmp_rec_to_save;
        }

        this.generalDatasetService.upsert(this.options.baseObject, rec_to_save, this.use_working_context).then(
            result => {
                this.alertService.addAlert({
                    title: 'Record',
                    message: "Saved",
                    type: AlertType.success
                });
            },
            result => {
                this.isSaving = false;
                const message = result?.message ? result.message : "Could not upsert Record";
                this.alertService.addAlert({

                    title: 'Error',
                    message: message,
                    type: AlertType.error
                });
            });


    };

    calculateActiveStatus(defaultActiveStatus: boolean = true): boolean { //default usually should pass current
        const lastCheckedWhen = this.editorOptions.html_ffe_api.last_checked_when();
        const lastModifiedWhen = this.editorOptions.html_ffe_api.last_modified_when();
        const lastNumberOfCheckErrors = this.editorOptions.html_ffe_api.record_check_status();
        const isDirty = this.isDirty;
        const result: boolean = ((lastCheckedWhen == null && isDirty) ? true :
            (lastModifiedWhen == null && lastCheckedWhen !== null && lastNumberOfCheckErrors == 0) ? false :
                (lastModifiedWhen <= lastCheckedWhen && lastNumberOfCheckErrors == 0) ? false :
                    (isDirty) ? true :
                        defaultActiveStatus
        );
        return result;
    };

    get_standard_dynamic_menu(menu_name: string, record: any) {
        const value = this.dynamic_menu_dict[menu_name];
        return Promise.resolve(value ? value : null);
    };

    set_standard_dynamic_menu(menu_name: string, menu: IMenuItem[]) {
        this.dynamic_menu_dict[menu_name] = menu;
    };

    get_setting(name: string): any {
        const result = this.settings.hasOwnProperty(name) ? this.settings[name] : null;
        if (!result) {
            console.error("Setting " + name + " was not found loaded into the dataset")
        }
        return result;
    };

    lookupMenuValue(mdlPath: string, value: any = null): any {
        const v = !!value ? value : PortalFunctions.deep_value(this.editorOptions?.html_ffe_api?.HtmlFFEService?.gets?.get_active_record(), mdlPath);
        return this.editorOptions?.html_ffe_api?.get_menu_value(mdlPath, v);  // return promise
    };

    setHeaders(headers: IHeader[]) {
        this.editorOptions.headers = headers; //  headers is label/value list
    };
}

interface FfeEditorQueryParams {
    doc_id: string;
    version?: string;
    parentKeyField?: string;
    parentKeyType?: string;
    parentKey?: string;
    parentBoard?: string;
    backOnClose?: boolean;
}

interface FfeEditorEmbeddedParams {
    zappAppBoard: ZappAppBoard;
    editorMode?: EditorMode;
    options?: FfeEditorOptions;

    defaultAddData?: any;

    queryParams: FfeEditorQueryParams;
}

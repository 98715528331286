import { Component } from '@angular/core';

@Component({
    selector: 'zs-report-domain',
    templateUrl: 'report-domain.component.html',
    styleUrls: ['report-domain.component.scss']
})
export class ReportDomainComponent {

}

// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'src/3rd-party';

import { ServicesModule } from 'src/services';
import { ComponentStoreModule } from 'src/component-store';

import { AdminBoardsService } from './admin-boards.service';
import { AdminRoutingService, ZappDesignerRoutingService, ReportingRoutingService } from './services';

import { ActivityTrackingComponent, ZappTransferComponent } from './components';
import { HfeComponentsModule } from 'src/components/hfe-components';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ServicesModule,
        ComponentStoreModule,
        HfeComponentsModule,
    ],
    declarations: [
        ActivityTrackingComponent,
        ZappTransferComponent
    ],
    providers: [
        AdminBoardsService,
        AdminRoutingService,
        ZappDesignerRoutingService,
        ReportingRoutingService
    ]
})
export class AdminModule {
}

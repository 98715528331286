// Angular Imports
import { Inject, NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, TitleStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Angular Material
import { MaterialModule } from 'src/3rd-party';

// App Imports
import packageJson from '../../package.json';
import { appEnvironmentOptions } from 'src/environments/environment';
import { APP_VERSION } from './app-tokens';

// DICORP Imports
import { DARK, LIGHT, NgxThemingModule } from "@anvilor/ngx-theming";

import { ZappsmithNgxAuthModule } from '@dicorp/zappsmith-ngx-auth';
import { ZappsmithNgxCoreModule } from '@dicorp/zappsmith-ngx-core';
import { APP_ENVIRONMENT_OPTIONS, APIInterceptor, AppEnvironment, AppEnvironmentOptions } from '@dicorp/zappsmith-ngx-core';

// Component Store Module
import { ComponentStoreModule } from 'src/component-store';

// Services Modules
import { ServicesModule } from 'src/services';

// App Modules
import { AppComponentsModule, AppComponent } from 'src/components/app-components';
import { HfeComponentsModule } from 'src/components/hfe-components';
import { PortalModules } from 'src/modules/portal-modules.module';
import { CookieService } from 'ngx-cookie-service';
import { AppTitleStrategy } from './app-title-strategy.service';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot([], {
            initialNavigation: 'disabled'
        }),
        // 3rd Party
        MaterialModule,
        // Dicorp Imports
        ZappsmithNgxAuthModule,
        ZappsmithNgxCoreModule,
        // HtmlFFEModule,
        // NgxTheming
        NgxThemingModule.forRoot({
            themes: [
                { key: "var-light", uses_vars: true, contrast: DARK },
                { key: "var-dark", uses_vars: true, contrast: LIGHT },
            ],
            options: {
                defaultTheme: "var-light",
            },
        }),
        // Component Store Module
        ComponentStoreModule,
        // Services Modules
        ServicesModule,
        // App Modules
        AppComponentsModule,
        HfeComponentsModule,
        PortalModules
    ],
    providers: [
        {
            provide: APP_VERSION,
            useValue: packageJson.version
        },
        {
            provide: APP_ENVIRONMENT_OPTIONS,
            useValue: appEnvironmentOptions
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: APIInterceptor,
            multi: true,
        },
        {
            provide: APP_BASE_HREF,
            useValue: '/portal4/'
        },
        {
            provide: TitleStrategy,
            useClass: AppTitleStrategy
        },
        CookieService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(@Inject(APP_ENVIRONMENT_OPTIONS) private appEnvironmentOptions: AppEnvironmentOptions) {
        switch (appEnvironmentOptions.appEnvironment) {
            case AppEnvironment.local:
            case AppEnvironment.dev:
            case AppEnvironment.test: {
                console.log('Core Module loaded using ' + appEnvironmentOptions.appEnvironment + 'environment');
                break;
            }
            default: {
                break;
            }
        }
    }
}

import { Component } from '@angular/core';
import { ZappsmithBaseParmDict, ZappsmithWebService } from '@dicorp/zappsmith-ngx-core';
import { AlertService, AlertType } from 'src/services';

@Component({
    selector: 'zs-csv-data-load',
    templateUrl: 'csv-data-load.component.html',
    styleUrls: ['csv-data-load.component.scss']
})
export class CsvDataLoadComponent {
    showLoadingSpinner: boolean;
    results: string;

    constructor(private alertService: AlertService,
        private zappsmithWebService: ZappsmithWebService) {
    }

    fileInputChange(fileInputEvent: any) {
        if (fileInputEvent?.target?.files?.length > 0) {
            this.processFile(fileInputEvent?.target?.files[0]);
        }
    }

    private processFile(file: File): Promise<void> {
        this.results = null;
        const filename = file ? file.name : null;
        const params: ZappsmithBaseParmDict = {
            file,
            filename
        };

        this.showLoadingSpinner = true;
        return new Promise<void>((resolve, reject) => {
            this.zappsmithWebService.post('/csv_data_load', params).then(
                result => {
                    this.alertService.addAlert({
                        title: 'Processed',
                        message: "File " + filename + " Processed",
                        type: AlertType.success
                    });
                    this.results = JSON.stringify(result);

                    this.showLoadingSpinner = false;
                    resolve();
                },
                result => {
                    this.alertService.addAlert({
                        title: 'Upload Failed',
                        message: 'Failed to Process File: ' + filename,
                        type: AlertType.error
                    });
                    this.results = result?.error?.text;

                    this.showLoadingSpinner = false;
                    reject();
                }
            );
        });
    }

}

import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TitleStrategy, RouterStateSnapshot } from "@angular/router";
import { ClientConfiguration, ClientConfigurationStore } from "@dicorp/zappsmith-ngx-auth";

@Injectable({ providedIn: 'root' })
export class AppTitleStrategy extends TitleStrategy {
    public clientConfiguration: ClientConfiguration;

    constructor(private readonly title: Title, clientConfigurationStore: ClientConfigurationStore) {
        super();

        clientConfigurationStore.activeClientConfiguration$.subscribe(activeClientConfiguration => {
            this.clientConfiguration = activeClientConfiguration;
        });
    }

    override updateTitle(routerState: RouterStateSnapshot) {
        const title: string = this.clientConfiguration?.portal_app_title ? this.clientConfiguration?.portal_app_title : 'Portal 4'
        this.title.setTitle(title);
    }
}
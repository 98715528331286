import { Component } from '@angular/core';
import { EditorMode, EditorOptions } from '@dicorp/html-ffe';
import { ZappsmithBaseParmDict, ZappsmithWebService } from '@dicorp/zappsmith-ngx-core';
import { DatasetConfig, GridType, HfeDatasetService } from 'src/components/hfe-components';
import { AlertService, AlertType } from 'src/services';

const CHANGES_INFO_FIELD = "ChangesInfo";
const COUNTS_INFO_FIELD = "CountsInfo";

@Component({
    selector: 'zs-board-data-pass',
    templateUrl: 'board-data-pass.component.html',
    styleUrls: ['board-data-pass.component.scss']
})
export class BoardDataPassComponent {
    showLoadingSpinner: boolean;

    reviewOnly: boolean;

    changesEditorOptions: EditorOptions;
    countsEditorOptions: EditorOptions;

    constructor(private alertService: AlertService,
        private zappsmithWebService: ZappsmithWebService,
        private hfeDatasetService: HfeDatasetService) {
        this.setupEditorOptions();
    }

    fileInputChange(fileInputEvent: any) {
        if (fileInputEvent?.target?.files?.length > 0) {
            this.processFile(fileInputEvent?.target?.files[0]);
        }
    }

    private setupEditorOptions(): void {
        // Changes Editor Options
        this.changesEditorOptions = new EditorOptions();
        this.changesEditorOptions.editorMode = EditorMode.View;
        this.changesEditorOptions.headers = [{ label: 'Changes ', value: '' }];

        this.changesEditorOptions.htmlFfeApiChanged.subscribe(api => {
            const changesDataset = this.createDataset(CHANGES_INFO_FIELD, CHANGES_COLUMNS)
            api?.load_dataset(changesDataset);
        });

        // Counts Editor Options
        this.countsEditorOptions = new EditorOptions();
        this.countsEditorOptions.editorMode = EditorMode.View;
        this.countsEditorOptions.headers = [{ label: 'Counts ', value: '' }];

        this.countsEditorOptions.htmlFfeApiChanged.subscribe(api => {
            const countsDataset = this.createDataset(COUNTS_INFO_FIELD, COUNTS_COLUMNS)
            api?.load_dataset(countsDataset);
        });
    }

    private createDataset(field: string, columnDefs: any[]): any {
        // Add the status column
        const datasetConfig = this.hfeDatasetService.getDatasetConfigUsingColumnDefs(field, null, columnDefs, GridType.Local);

        const editorDataset: any = {
            sections: {
                "MainForm": {
                    "name": "MainForm",
                    "height": "100%",
                    "children": [
                        {
                            "dm_reference": field,
                            "type": "Grid",
                            "align_label": "Hidden",
                            "height": "100%",
                            "allow_multi_select": true,
                            "hide_grid_filter": true,
                            "number_of_rows": 0,
                            "children": datasetConfig.columns
                        }
                    ]
                }
            },
            field_configurations: datasetConfig.fieldConfigurations,
            rules: datasetConfig.rules,
            menus: datasetConfig.menus,
            default_date_format: "ISO8601",
            default_time_format: "string"
        };

        return editorDataset;
    }

    private processFile(file: File): Promise<void> {
        const filename = file ? file.name : null;
        const params: ZappsmithBaseParmDict = {
            file,
            filename,
            should_update: !this.reviewOnly,
        };

        this.showLoadingSpinner = true;
        return new Promise<void>((resolve, reject) => {
            this.zappsmithWebService.post('/board_data_pass', params).then(
                result => {
                    this.alertService.addAlert({
                        title: 'Processed',
                        message: "File " + filename + " Processed",
                        type: AlertType.success
                    });

                    // Changes Record
                    const changesRecord: any = {};
                    const changes: { [key: string]: any[] } = result?.changes;
                    const changeList = [];
                    for (var k in changes) {
                        changes[k]?.forEach(value => {
                            changeList.push({ key: k, change: JSON.stringify(value) });
                        })
                    }
                    changesRecord[CHANGES_INFO_FIELD] = changeList;
                    this.changesEditorOptions?.html_ffe_api?.reload_record(changesRecord);

                    // Counts Record
                    const countsRecord: any = {};
                    const counts: { [key: string]: number } = result?.counts;
                    const countsList = [];
                    for (var k in counts) {
                        countsList.push({ key: k, count: counts[k] });
                    }
                    countsRecord[COUNTS_INFO_FIELD] = countsList;
                    this.countsEditorOptions?.html_ffe_api?.reload_record(countsRecord);

                    this.showLoadingSpinner = false;
                    resolve();
                },
                result => {
                    const message = result?.status && result.data ?
                        result.status + ': ' + result.data :
                        'Failed to Process File: ' + filename;
                    this.alertService.addAlert({
                        title: 'Upload Failed',
                        message: message,
                        type: AlertType.error
                    });
                    this.showLoadingSpinner = false;
                    reject();
                }
            );
        });
    }
}

const CHANGES_COLUMNS: any[] = [
    { heading: 'Key', field: 'key', kind: 'text', active: true },
    { heading: 'Change', field: 'change', kind: 'text', active: true }
]

const COUNTS_COLUMNS: any[] = [
    { heading: 'Key', field: 'key', kind: 'text', active: true },
    { heading: 'Count', field: 'count', kind: 'int', active: true }
]

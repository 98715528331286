import { Component, inject } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

import { AlertData, AlertType } from '../alert-models';
import { AlertService } from '../alert.service';

@Component({
    selector: 'zs-alert-list',
    templateUrl: 'alert-list.component.html',
    styleUrls: ['alert-list.component.scss']
})
export class AlertListComponent {
    public alertData: AlertData[];

    constructor(
        public snackBarRef: MatSnackBarRef<AlertListComponent>,
        private alertService: AlertService) {
        this.alertData = alertService.alerts;
        this.setAlertColors()
    }

    setAlertColors(): void {
        this.alertData.forEach(alert => {
            switch (alert.type) {
                case (AlertType.error): {
                    alert.color = 'warn';
                    break;
                }
                case (AlertType.warning): {
                    alert.color = 'accent';
                    break;
                }
                case (AlertType.success): {
                    alert.color = 'primary';
                    break;
                }
            }
        })
    }

    remove(alertData: AlertData) {
        this.alertService.removeAlert(alertData);
    }

    dismiss(): void {
        this.alertService.clearAllAlerts();
        this.snackBarRef.dismiss();
    }
}

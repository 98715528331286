import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommonFunctions } from '@dicorp/zappsmith-ngx-core';

import { ZappsmithWebService, ZappsmithWebServiceApiParams } from '@dicorp/zappsmith-ngx-core';
import { BaseZappsmithBoard, BaseZappsmithStoreState, BaseZappsmithComponentStore } from '@dicorp/zappsmith-ngx-core';
import { ZappAppBoard, ZappAppBoardStore } from './zapp-app-board.store';

function getZappAppBoardParams(): ZappsmithWebServiceApiParams {
    const apiParams = new ZappsmithWebServiceApiParams('zapp_app');
    apiParams.setQuery({ projection: { 'tracking_info': 0 } });
    return apiParams;
}

export class ZappApp extends BaseZappsmithBoard {
    public Label: string;
    public Description: string;

    public SortOrder: number;

    public IsDisabled: boolean;
    public Enabled: boolean;

    public ZappAppBoards?: ZappAppBoard[];

    constructor(rawZappApp: any) {
        super(rawZappApp, 'Application.Id', 'Application.Name');

        this.Label = CommonFunctions.resolvePathValue('Application.Label', rawZappApp);
        this.Description = CommonFunctions.resolvePathValue('Application.Description', rawZappApp);

        this.SortOrder = CommonFunctions.resolvePathValue('Application.SortOrder', rawZappApp);

        this.IsDisabled = CommonFunctions.resolvePathValue('Application.IsDisabled', rawZappApp);
        this.Enabled = !this.IsDisabled;
    }
}

interface ZappAppState extends BaseZappsmithStoreState {
    zappApps?: ZappApp[];
    zappAppsState?: ZappAppsState;
}

interface ZappAppsState {
    zappApps?: ZappApp[];

    zappAppsLoaded?: boolean;
    zappAppBoardsLoaded?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ZappAppStore extends BaseZappsmithComponentStore<ZappAppState> {

    readonly zappApps$: Observable<ZappApp[]> = this.select(state => state.zappApps);
    readonly zappAppsState$: Observable<ZappAppsState> = this.select(state => state.zappAppsState);

    private zappApps?: ZappApp[] = [];
    private zappAppBoards?: ZappAppBoard[] = [];

    private zappAppBoardsLoaded: boolean;

    constructor(
        private zappsmithWebService: ZappsmithWebService,
        private zappAppBoardStore: ZappAppBoardStore) {
        super();
        this.setState({});

        this.getZappApps();

        this.zappAppBoardStore.zappAppBoardsLoaded$.subscribe(zappAppBoardsLoaded => {
            if (zappAppBoardsLoaded) {
                this.zappAppBoardsLoaded = zappAppBoardsLoaded;
                this.zappAppBoardStore.zappAppBoards$.subscribe(zappAppBoards => {
                    this.zappAppBoards = zappAppBoards;
                    this.updateZappAppBoards();
                });
            }
        });
    }

    private getZappApps(): void {
        const zappAppBoardParams = getZappAppBoardParams();
        zappAppBoardParams.setOrderBy({ 'Application.SortOrder.#value': 'asc' });

        this.zappsmithWebService.getApi(zappAppBoardParams).then(
            result => {
                const zappAppResult = this.convertGetBoardResults(ZappApp, result);
                this.zappApps = zappAppResult.data;

                this.updateZappAppBoards();
            },
            () => {
            });
    }

    private updateZappAppBoards(): void {
        for (var zappApp of this.zappApps) {
            zappApp.ZappAppBoards = this.zappAppBoards?.filter(zappAppBoard => {
                return zappAppBoard.ApplicationLinkValue === zappApp._id &&
                    zappAppBoard.permissions?.canAccessModule() &&
                    zappAppBoard.permissions?.canView() &&
                    zappAppBoard.Enabled &&
                    !zappAppBoard.IsHidden;
            });
        }

        this.patchState({
            zappApps: this.zappApps,
            zappAppsState: {
                zappApps: this.zappApps,
                zappAppsLoaded: this.zappApps?.length > 0,
                zappAppBoardsLoaded: this.zappAppBoardsLoaded
            }
        });
    }
}
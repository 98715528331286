import { Component } from '@angular/core';

@Component({
    selector: 'zs-sequence-admin',
    templateUrl: 'sequence-admin.component.html',
    styleUrls: ['sequence-admin.component.scss']
})
export class SequenceAdminComponent {

}

import { Injectable, Type, inject, Injector, runInInjectionContext } from '@angular/core';
import { Route, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class RoutingService {

    constructor(private injector: Injector) {
    }

    public canLoadRoute(route: Route, routeSnapshot: ActivatedRouteSnapshot, stateSnapshot: RouterStateSnapshot): boolean {
        return this.canActivateRoute(route, routeSnapshot, stateSnapshot) &&
            this.canMatchRoute(route, routeSnapshot);
    }

    public canActivateAnyRoute(routes: Route[], routeSnapshot: ActivatedRouteSnapshot, stateSnapshot: RouterStateSnapshot): boolean {
        let canActivateAny = false;
        routes.forEach(route => {
            if (this.canLoadRoute(route, routeSnapshot, stateSnapshot)) {
                canActivateAny = true;
            }
        })

        return canActivateAny;
    }

    private canActivateRoute(route: Route, routeSnapshot: ActivatedRouteSnapshot, stateSnapshot: RouterStateSnapshot): boolean {
        if (route.canActivate?.length > 0) {
            let canActivate = false;
            for (var i in route.canActivate) {
                const canActivateClass: Type<any> = route.canActivate[i];
                const canActivateValue = this.injector.get<CanActivate>(canActivateClass);

                if (canActivateValue?.canActivate(routeSnapshot, stateSnapshot)) {
                    canActivate = true;
                    break;
                }
            }

            return canActivate;
        } else {
            return true;
        }
    }

    private canMatchRoute(route: Route, routeSnapshot: ActivatedRouteSnapshot): boolean {
        if (route.canMatch?.length > 0) {
            let canMatch = false;
            for (var i in route.canMatch) {
                const canMatchResult = route.canMatch[i](route, routeSnapshot);

                if (canMatchResult === true) {
                    canMatch = true;
                    break;
                } else if (typeof canMatchResult === 'function') {
                    const canMatchFunctionResult = runInInjectionContext(this.injector, canMatchResult);
                    if (canMatchFunctionResult === true) {
                        canMatch = true;
                        break;
                    }
                }
            }

            return canMatch;
        } else {
            return true;
        }
    }
}
import { Component, ViewChild } from '@angular/core';
import { JsonEditorOptions, JsonEditorComponent } from 'ang-jsoneditor';
import { ConfigurationService, AlertService, AlertType } from 'src/services';

@Component({
    selector: 'zs-raw-system-configuration',
    templateUrl: 'raw-system-configuration.component.html',
    styleUrls: ['raw-system-configuration.component.scss']
})
export class RawSystemConfigurationComponent {
    @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;
    public editorOptions: JsonEditorOptions;

    public data: any = {};
    public updatedData: any;

    public stringToEncrypt: string;
    public encryptedString: string;

    constructor(private configurationService: ConfigurationService,
        private alertService: AlertService) {
        this.setupJsonEditorOptions();
        this.reload();
    }

    private setupJsonEditorOptions(): void {
        this.editorOptions = new JsonEditorOptions();

        this.editorOptions.mode = 'tree';
        this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
        this.editorOptions.indentation = 4;
        this.editorOptions.name = 'Configuration';

        this.editorOptions.mainMenuBar = true;
        this.editorOptions.navigationBar = true;
    }

    dataChanged(): void {
        this.updatedData = this.editor.get();
    }

    reload(): void {
        this.configurationService.getRaw().then(
            result => {
                this.data = result;
            },
            result => {
                this.alertService.addAlert({
                    title: 'Error',
                    message: "Could not get configuration",
                    type: AlertType.error
                });
            });

    };

    save(): void {
        if (this.updatedData) {
            this.configurationService.saveRaw(this.updatedData).then(
                result => {
                    this.alertService.addAlert({
                        title: 'Configuration Saved',
                        message: "Configuration changed",
                        type: AlertType.success
                    });
                },
                result => {
                    this.alertService.addAlert({
                        title: 'Error',
                        message: result.error,
                        type: AlertType.error
                    });
                });
        }
    }

    cancel(): void {
        this.reload();
    }

    encryptString(): void {
        this.configurationService.encryptString(this.stringToEncrypt).then(
            result => {
                this.encryptedString = result.data;
            },
            result => {
                this.alertService.addAlert({
                    title: 'Error',
                    message: "Could not encrypt string",
                    type: AlertType.error
                });
            });


    };
}

import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

const EXPAND_SYSTEM_TEMPLATE_PATH = '/expand_system_template?template_id=';

@Component({
    selector: 'zs-system-template',
    templateUrl: 'system-template.component.html',
    styleUrls: ['system-template.component.scss']
})
export class SystemTemplateComponent {
    private _templateName: string;
    @Input() set templateName(value: string) {
        if (value) {
            this._templateName = value;
            this.getTemplate(this._templateName);
        }
    }

    public templateHtml: string;

    constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) {
        this.activatedRoute.data.subscribe(data => {
            const templateName = data['templateName'];
            if (templateName) {
                this.getTemplate(templateName);
            }
        });
    }

    private getTemplate(templateName: string): void {
        const path = EXPAND_SYSTEM_TEMPLATE_PATH + templateName;

        this.http.get(path, { responseType: 'text' }).subscribe({
            next: (result: any) => {
                this.templateHtml = result;
            },
            error: (result: any) => {
                console.log("Error retrieving system template: " + result);
            }
        })
    }
}

<mat-card class="confirmation-dialog-card nvt-surface border-base">
    <mat-card-header *ngIf="dialogData?.title">
        <mat-card-title> {{ dialogData?.title }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-card class="nvt-surface border-base">
            <mat-card-content>
                {{ dialogData?.message }}
            </mat-card-content>
        </mat-card>
    </mat-card-content>
    <mat-card-actions align="end">
        <button class="theme-button
                        primary-button
                        border-base
                        interactive-base
                        transition-base 
                        nvt-colorbg-pb" (click)="dialogRef.close(true)">Yes</button>
        <button class="theme-button
                        border-button
                        border-base
                        transition-base" (click)="dialogRef.close()">No</button>
    </mat-card-actions>
</mat-card>
import { Component } from '@angular/core';

@Component({
    selector: 'zs-node-editor-node-kind',
    templateUrl: 'node-editor-node-kind.component.html',
    styleUrls: ['node-editor-node-kind.component.scss']
})
export class NodeEditorNodeKindComponent {

}

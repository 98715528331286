<mat-card>
    <mat-card-header>
        <mat-card-title>Version Compare</mat-card-title>
    </mat-card-header>
    <mat-card-content zsVersionCompareHTML>

        <!-- Compare Controls -->
        <div class="compare-controls">

            <!-- Version Selection -->
            <div>
                <!-- First Version -->
                <div>
                    <label>
                        Comparing Version
                    </label>

                    <select style="margin-left: 10px;" [(ngModel)]="firstVersion">
                        <ng-container *ngFor="let version of versions">
                            <option [value]="version">{{version}}</option>
                        </ng-container>
                    </select>
                </div>

                <!-- Second Version -->
                <div>
                    <label>
                        With Version
                    </label>

                    <select style="margin-left: 10px;" [(ngModel)]="secondVersion">
                        <ng-container *ngFor="let version of versions">
                            <option [value]="version">{{version}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>

            <!-- Format Types -->
            <div style="margin-bottom: 10px;">
                <button (click)="compareVersions(CompareFormat.compressed)">Compressed</button>
                <button (click)="compareVersions(CompareFormat.text)" style="margin-left: 10px;">Text</button>
            </div>
        </div>

        <!-- Compare HTML -->
        <div zsVersionCompareHTML class="compare-html" *ngIf="compareHtml" [innerHTML]="compareHtml | sanitizeHtml">
        </div>

    </mat-card-content>
    <mat-card-actions align="end">
        <button class="theme-button
                        primary-button
                        border-base
                        interactive-base
                        transition-base 
                        nvt-colorbg-pb" (click)="dialogRef.close(true)">Close</button>
    </mat-card-actions>
</mat-card>
import { Component } from '@angular/core';
import { EditorOptions, EditorMode } from '@dicorp/html-ffe';

@Component({
    selector: 'zs-system-configuration',
    templateUrl: 'system-configuration.component.html',
    styleUrls: ['system-configuration.component.scss']
})
export class SystemConfigurationComponent {
    // public editorOptions: EditorOptions;

    // private editorDataset: any;
    // private editorDatasetLoaded: boolean = false;

    // constructor() {
    //     this.editorOptions = new EditorOptions();
    //     this.editorOptions.editorMode = EditorMode.Edit;
    // }

    // private loadDataset(): void {
    //     if (this.editorDatasetLoaded) {
    //         return;
    //     }

    //     this.editorOptions?.html_ffe_api?.load_dataset(this.editorDataset);
    //     this.editorOptions?.html_ffe_api?.reload_record({});
    // }
}

import { Component } from '@angular/core';
import { ZappsmithBaseParmDict, ZappsmithWebService } from '@dicorp/zappsmith-ngx-core';
import { AlertService, AlertType } from 'src/services';

@Component({
    selector: 'zs-board-data-load',
    templateUrl: 'board-data-load.component.html',
    styleUrls: ['board-data-load.component.scss']
})
export class BoardDataLoadComponent {
    showLoadingSpinner: boolean;
    boardId: string;
    results: string;

    constructor(private alertService: AlertService,
        private zappsmithWebService: ZappsmithWebService) {
    }

    fileInputChange(fileInputEvent: any) {
        if (fileInputEvent?.target?.files?.length > 0) {
            this.processFile(fileInputEvent?.target?.files[0]);
        }
    }

    private processFile(file: File): Promise<void> {
        this.results = null;
        const filename = file ? file.name : null;
        const params: ZappsmithBaseParmDict = {
            file,
            filename,
            board: this.boardId
        };

        this.showLoadingSpinner = true;
        return new Promise<void>((resolve, reject) => {
            this.zappsmithWebService.post('/board_data_load', params).then(
                result => {
                    this.alertService.addAlert({
                        title: 'Processed',
                        message: "File " + filename + " Processed",
                        type: AlertType.success
                    });
                    this.results = JSON.stringify(result);

                    this.showLoadingSpinner = false;
                    resolve();
                },
                result => {
                    const message = result?.status && result.data ?
                        result.status + ': ' + result.data :
                        'Failed to Process File: ' + filename;
                    this.alertService.addAlert({
                        title: 'Upload Failed',
                        message: message,
                        type: AlertType.error
                    });

                    this.showLoadingSpinner = false;
                    reject();
                }
            );
        });
    }

}

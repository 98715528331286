import { PortalFunctions } from "./portal.functions";

export class CheckFunctions {
    static lookupBitChar(bit: number) {
        return (Math.floor(bit / 8)) * 2 + (((bit % 8) > 3) ? 0 : 1);
    }

    static lookupBit(fieldValue: any, bit: number): 0 | 1 {
        let retval: 0 | 1 = 0;
        if (fieldValue && bit) {
            const whichChar = CheckFunctions.lookupBitChar(bit);
            const whichRbit = bit % 4;
            if (fieldValue.length >= whichChar) // are we even in bits avail
            {
                const relevantByte = parseInt(fieldValue[whichChar], 16);
                const bitTest = ((relevantByte >> whichRbit) % 2 != 0);
                if (bitTest) {
                    retval = 1;
                }
            }
        }
        return retval;
    }

    static checkValidField(record: any, checkRule: any) {
        let validated: 0 | 1 = 0;
        if (checkRule.vali_field && checkRule.vali_bit) { // this only applies if CURRENT not in path
            const field_value = PortalFunctions.deep_value(record, checkRule.vali_field);
            validated = CheckFunctions.lookupBit(field_value, parseInt(checkRule.vali_bit))
        }
        if (validated == 0) {
            if (checkRule.guard_field) {
                let field_value = PortalFunctions.deep_value(record, checkRule.guard_field);
                if (field_value && field_value.hasOwnProperty('#value')) {
                    field_value = field_value['#value'];
                }
                // if its true then skip the rule (set to 0) - as it it were validated
                validated = (field_value == 'true' || field_value == 'True' || field_value == 1 || field_value == true) ? 0 : 1
            }
        }
        return validated;
    };
}

export class RepeaterListIterator {
    record: any;
    checkRule: any;
    isValid: boolean = true;
    listDms: string[];
    numLevels: number;
    allItems: any[] = [];
    totalCount: number;
    pos: number = 0;

    constructor(record: any, checkRule: any) {
        this.record = record;
        this.checkRule = checkRule;
        this.listDms = this.checkRule.list_dm_reference.split(',');
        this.numLevels = this.listDms.length;
        this._diveDown();
        this.totalCount = this.allItems.length;
    }

    _diveDown(level: number = 0, pathSoFar: number[] = null) {
        if (level >= this.numLevels) {
            const newItem = [];
            // a dumb copy
            for (let i = 0; i < pathSoFar.length; i++) {
                newItem[i] = pathSoFar[i];
            }
            this.allItems.push(newItem);
        }
        else {
            if (pathSoFar == null) {
                pathSoFar = [];
            }
            const list_dm = PortalFunctions.convertCurrentIndexes(this.listDms[level].trim(), pathSoFar);
            const theList: any = list_dm ? PortalFunctions.deep_value(this.record, list_dm) : null;
            const theListSize = theList ? theList.length : null;
            if (theListSize) {
                for (let i = 0; i < theListSize; i++) {
                    pathSoFar.push(i);
                    this._diveDown(level + 1, pathSoFar);
                    pathSoFar.pop(); // remove from stack
                }
            }
            else {
                this.isValid = false;
            }
        }
    }

    next(): number[] {
        let res: number[] | null = null;
        if (this.pos < this.totalCount) {
            res = this.allItems[this.pos];
            this.pos += 1;
        }
        return res;
    }
};
<mat-card class="nvt-surface border-base">
    <mat-card-header *ngIf="alertDialogData?.title">
        <mat-card-title>{{alertDialogData?.title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-card class="nvt-surface border-base">
            <mat-card-content>
                <div [innerHTML]="alertDialogData?.message | sanitizeHtml">
                </div>
            </mat-card-content>
        </mat-card>
    </mat-card-content>
    <mat-card-actions align="end">
        <ng-container *ngIf="showOk">

            <button
                class="theme-button primary-button border-base interactive-base transition-base nvt-colorbg-pb dismiss-btn"
                (click)="dialogRef.close(true)">
                Ok
            </button>

        </ng-container>
        <ng-container *ngIf="showCancel">

            <button
                class="theme-button alt-button border-base interactive-base transition-base nvt-colorbg-pb dismiss-btn"
                (click)="dialogRef.close(false)">
                Cancel
            </button>

        </ng-container>
    </mat-card-actions>
</mat-card>
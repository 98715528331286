import { Component } from '@angular/core';
import { ConfirmationDialogService } from 'src/components/dialog-components';
import { AlertService, AlertType, ConfigurationService, SystemUpdateService, ZappService } from 'src/services';

@Component({
    selector: 'zs-system-update',
    templateUrl: 'system-update.component.html',
    styleUrls: ['system-update.component.scss']
})
export class SystemUpdateComponent {
    showLoadingSpinner: boolean;

    configurationCustomers: string[];
    configurationCustomerInput: string = '';
    clearConfiguration: boolean;
    configurationRevision: string;
    configurationBranch: string;

    tailoringCustomers: string[];
    tailoringCustomerInput: string = '';
    tailoringRevision: string;

    boardCustomers: string[];
    boardCustomerInput: string;
    boardRevision: string;

    constructor(private confirmationDialogService: ConfirmationDialogService,
        private alertService: AlertService,
        private configurationService: ConfigurationService,
        private systemUpdateService: SystemUpdateService,
        private zappService: ZappService) {

        this.configurationCustomers = this.configurationService.getConfigurationItemAsStringList('restricted_configuration_options', []);
        this.tailoringCustomers = this.configurationService.getConfigurationItemAsStringList('restricted_tailoring_options', []);
        this.boardCustomers = this.configurationService.getConfigurationItemAsStringList('restricted_board_options', []);
    }

    resetService(): void {
        this.confirmationDialogService.openConfirmationDialog(
            {
                title: 'Reset Service',
                message: 'Are you sure you want to reset this service?'
            }).then(result => {
                if (result) {
                    this.showLoadingSpinner = true;
                    this.systemUpdateService.reset_service().then(
                        result => {
                            this.showLoadingSpinner = false;
                            this.alertService.addAlert({
                                title: 'Restarting',
                                message: "Service Updating",
                                type: AlertType.success
                            });
                        },
                        result => {
                            this.showLoadingSpinner = false;
                            // System Update call returns terrible results for some reason
                            if (result?.status === 200 || result?.status === 502) {
                                this.alertService.addAlert({
                                    title: 'Restarting',
                                    message: "Service Updating",
                                    type: AlertType.success
                                });
                            } else {
                                this.alertService.addAlert({
                                    title: 'Error',
                                    message: "Could not reset service",
                                    type: AlertType.error
                                });
                            }
                        }
                    )
                }
            });
    };

    clearCache(): void {
        this.confirmationDialogService.openConfirmationDialog(
            {
                title: 'Clear Cache',
                message: 'Are you sure you want to clear the cache?'
            }).then(result => {
                if (result) {
                    this.showLoadingSpinner = true;
                    this.systemUpdateService.clear_cache().then(
                        result => {
                            this.showLoadingSpinner = false;
                            this.alertService.addAlert({
                                title: 'Cache Cleared',
                                message: "Cache Cleared",
                                type: AlertType.success
                            });
                        },
                        result => {
                            this.showLoadingSpinner = false;
                            // System Update call returns terrible results for some reason
                            if (result?.status === 200) {
                                this.alertService.addAlert({
                                    title: 'Cache Cleared',
                                    message: "Cache Cleared",
                                    type: AlertType.success
                                });
                            } else {
                                this.alertService.addAlert({
                                    title: 'Error',
                                    message: "Could not clear cache",
                                    type: AlertType.error
                                });
                            }
                        }
                    )
                }
            });
    };

    reloadScheduler(): void {
        this.confirmationDialogService.openConfirmationDialog(
            {
                title: 'Reload Scheduler',
                message: 'Are you sure you want to reload the scheduler?'
            }).then(result => {
                if (result) {
                    this.showLoadingSpinner = true;
                    this.systemUpdateService.setup_scheduler().then(
                        result => {
                            this.showLoadingSpinner = false;
                            this.alertService.addAlert({
                                title: 'Scheduler Reloaded',
                                message: "Scheduler Reloaded",
                                type: AlertType.success
                            });
                        },
                        result => {
                            this.showLoadingSpinner = false;
                            // System Update call returns terrible results for some reason
                            if (result?.status === 200) {
                                this.alertService.addAlert({
                                    title: 'Scheduler Reloaded',
                                    message: "Scheduler Reloaded",
                                    type: AlertType.success
                                });
                            } else {
                                this.alertService.addAlert({
                                    title: 'Error',
                                    message: "Could not reload scheduler",
                                    type: AlertType.error
                                });
                            }
                        }
                    )
                }
            });
    };

    updateConfiguration(): void {
        this.confirmationDialogService.openConfirmationDialog(
            {
                title: 'Update Configuration',
                message: 'Are you sure you want to update the build? This will reset the service and cause a temporary outage'
            }).then(result => {
                if (result) {
                    this.showLoadingSpinner = true;
                    this.systemUpdateService.update_build(null, null,
                        this.configurationCustomerInput, this.configurationRevision,
                        this.configurationBranch, this.clearConfiguration).then(
                            result => {
                                this.showLoadingSpinner = false;
                                this.alertService.addAlert({
                                    title: 'Updating',
                                    message: "Build update requested. You may need to reset and verify all.",
                                    type: AlertType.success
                                });
                            },
                            result => {
                                this.showLoadingSpinner = false;
                                // System Update call returns terrible results for some reason
                                if (result?.status === 200 || result?.status === 502) {
                                    this.alertService.addAlert({
                                        title: 'Updating',
                                        message: "Build update requested. You may need to reset and verify all.",
                                        type: AlertType.success
                                    });
                                } else {
                                    this.alertService.addAlert({
                                        title: 'Error',
                                        message: "Build update call failed.",
                                        type: AlertType.error
                                    });
                                }
                            }
                        )
                }
            });
    };

    loadTailoring(): void {
        this.confirmationDialogService.openConfirmationDialog(
            {
                title: 'Load Tailoring',
                message: 'Are you sure you want to load this tailoring?'
            }).then(result => {
                if (result) {
                    this.showLoadingSpinner = true;
                    this.systemUpdateService.load_tailoring(this.tailoringCustomerInput, this.tailoringRevision).then(
                        result => {
                            this.showLoadingSpinner = false;
                            this.alertService.addAlert({
                                title: 'Tailoring Loaded',
                                message: "Loaded Tailoring for " + this.tailoringCustomerInput,
                                type: AlertType.success
                            });
                        },
                        result => {
                            this.showLoadingSpinner = false;
                            // System Update call returns terrible results for some reason
                            if (result?.status === 200) {
                                this.alertService.addAlert({
                                    title: 'Tailoring Loaded',
                                    message: "Loaded Tailoring for " + this.tailoringCustomerInput,
                                    type: AlertType.success
                                });
                            } else {
                                this.alertService.addAlert({
                                    title: 'Error',
                                    message: "Could not load tailoring",
                                    type: AlertType.error
                                });
                            }
                        }
                    )
                }
            });
    };

    loadBoards(): void {
        this.confirmationDialogService.openConfirmationDialog(
            {
                title: 'Load Boards',
                message: 'Are you sure you want to load these boards?'
            }).then(result => {
                if (result) {
                    this.showLoadingSpinner = true;
                    this.zappService.load(this.boardCustomerInput, this.boardRevision).then(
                        result => {
                            this.showLoadingSpinner = false;
                            this.alertService.addAlert({
                                title: 'Boards Loaded',
                                message: "Loaded Boards for " + this.boardCustomerInput,
                                type: AlertType.success
                            });
                        },
                        result => {
                            this.showLoadingSpinner = false;
                            // System Update call returns terrible results for some reason
                            if (result?.status === 200) {
                                this.alertService.addAlert({
                                    title: 'Boards Loaded',
                                    message: "Loaded Boards for " + this.boardCustomerInput,
                                    type: AlertType.success
                                });
                            } else {
                                this.alertService.addAlert({
                                    title: 'Error',
                                    message: "Could not load boards",
                                    type: AlertType.error
                                });
                            }
                        }
                    )
                }
            });
    };
}

<!-- Loading Spinner -->
<mat-card class="csv-data-load-loading" [hidden]="!showLoadingSpinner">
    <mat-spinner mode="indeterminate" diameter="200">
    </mat-spinner>
</mat-card>

<!-- CSV Data Load -->
<mat-card appearance="outlined" [hidden]="showLoadingSpinner">
    <mat-card-header>
        <mat-card-title>CSV Data Load</mat-card-title>
    </mat-card-header>
    <mat-card-content>

        <section style="margin-bottom: 10px;">
            <div>
                <input #fileInput hidden="true" type="file" onclick="this.value=null"
                    (change)="fileInputChange($event)" />
                <button mat-flat-button (click)="fileInput.click()">Select File to Load</button>
            </div>
        </section>

        <div class="results-div" *ngIf="results">Results: {{results}}</div>

    </mat-card-content>
</mat-card>
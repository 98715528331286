import { Component } from '@angular/core';

@Component({
    selector: 'zapp-transfer',
    templateUrl: 'zapp-transfer.component.html',
    styleUrls: ['zapp-transfer.component.scss']
})
export class ZappTransferComponent {

}

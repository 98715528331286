import { Injectable } from "@angular/core";

import { ICdict, RuleBindingMap } from "../common/rule-binding";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { SessionService } from "./session.service";

export class RuleTrace {
    name: string;
    module: string;
    cdict: ICdict;
    start: Date;
    end: Date;
    duration: number;
    bindings: RuleBindingMap;
    bindingsRep: string;
    valueRep: string;
    changesRep: string;
    messageRep: string;

    constructor(name: string, module: string, start: Date, bindings: RuleBindingMap, cdict: ICdict) {
        this.start = start;
        this.bindings = bindings;
        this.cdict = cdict;
        this.end = new Date();
        this.duration = this.end.getTime() - start.getTime();
        this.name = name;
        this.module = module;
        this.valueRep = JSON.stringify(this.cdict.value);
        this.changesRep = JSON.stringify(this.cdict.changes);
        this.messageRep = JSON.stringify(this.cdict.message);
        this.bindingsRep = JSON.stringify(this.bindings);
    }

    log() {
        console.log(`Rule ${this.name} Module ${this.module} ran for ${this.duration} ms : Bindings ${this.bindingsRep} -> value: ${this.valueRep}  changes: ${this.changesRep} message: ${this.messageRep}`);
    }
}

@Injectable({
    providedIn: 'root'
})
export class TraceRuleService {

    private _ruleTraces: RuleTrace[] = [];
    public traceRulesActive: boolean;

    constructor() { }

    add(name: string, module: string, start: Date, bindings: RuleBindingMap, cdict: ICdict): void {
        if (this.traceRulesActive) {
            const rt = new RuleTrace(name, module, start, bindings, cdict);
            this._ruleTraces.push(rt);
            rt.log()
        }
    };

    ruleTraces(): RuleTrace[] {
        return this._ruleTraces;
    };

    clear(): void {
        this._ruleTraces = [];
    }
}

@Injectable({
    providedIn: 'root'
})
export class CanActivateTraceRules implements CanActivate {
    constructor(private sessionService: SessionService) { }

    canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.sessionService.hasPermission('IsAdminAbility');
    }
}

import { Injectable } from "@angular/core";

import { ZappsmithWebService, ZappsmithBaseParmDict } from "@dicorp/zappsmith-ngx-core";
import { CacheService } from "./cache.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AlertService, AlertType } from "./alerts";

@Injectable({
    providedIn: 'root'
})
export class GeneralDatasetService {

    constructor(private zappsmithWebService: ZappsmithWebService,
        private cacheService: CacheService,
        private http: HttpClient,
        private alertService: AlertService) {
    }

    // get(name: string): Promise<any> {
    //     const params: ZappsmithBaseParmDict = { 'name': name };
    //     return this.zappsmithWebService.get('/dataset', params);
    // }

    get(base: string, doc_id: string, version?: string, use_working_context?: boolean): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'doc_id': doc_id };
        if (!!(version) && version)
            params['version'] = version;
        if (!!(use_working_context) && use_working_context)
            params['use_working_context'] = use_working_context;

        return this.zappsmithWebService.get(base, params);
    };

    get_add_default(base: string): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'doc_id': -1 };
        return this.zappsmithWebService.get(base, params);
    };

    upsert(base: string, document: any, use_working_context?: any): Promise<any> {
        if (base == 'facility' || base == 'user_no_admin' || base == 'user' || base == 'staff'
            || base == 'role' || base == 'session_obj/facility' || base == 'session_obj/user' ||
            base == 'session_obj/staff' || base == 'session_obj/role' || base == 'session_obj/facility_with_children'
            || base.startsWith('zapp/') || base.startsWith('/zapp/')) {
            //console.log("Clearing cache since " + base + " updated");
            this.cacheService.clearCache();
        }

        // strip the tracking_info
        if (document.hasOwnProperty('tracking_info')) {
            delete document.tracking_info;
        }

        //var params = {'document': document};
        const params: ZappsmithBaseParmDict = { 'document': JSON.stringify(document) };
        if (!!(use_working_context) && use_working_context) {
            params['use_working_context'] = use_working_context;
        }

        return new Promise<any>((resolve, reject) => {
            try {
                this.zappsmithWebService.post(base, params).then(
                    result => {
                        resolve(result);
                    },
                    result => {
                        reject(result);
                    }
                );
            } catch (e) {
                reject();
            }
        })
    };

    set_default(base: string, document: any, facility_key: string): Promise<any> {
        //var params = {'document': document};
        const params: ZappsmithBaseParmDict = {
            'document': JSON.stringify(document),
            'is_default': true
        };

        if (!!(facility_key)) {
            params['facility_key'] = facility_key;
        }

        return this.zappsmithWebService.post(base, params);
    };

    get_default(base: string, facility_key: string): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'doc_id': -1 };
        if (!!(facility_key)) {
            params['facility_key'] = facility_key;
        }
        return this.zappsmithWebService.get(base, params);
    };

    publish_working(base: string, doc_id: string, version?: string): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'doc_id': doc_id };
        if (!!(version) && version) {
            params['version'] = version;
        }
        return this.zappsmithWebService.post(base + '/publish', params);
    };

    list_versions(base: string, doc_id: string, use_working_context?: boolean): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'doc_id': doc_id, 'list_versions': 1 };
        if (!!(use_working_context) && use_working_context) {
            params['use_working_context'] = use_working_context;
        }

        return this.zappsmithWebService.get(base, params);
    };

    diff_versions(base: string, doc_id: string, version: string, compare_version: string,
        diff_format: string, use_working_context?: boolean): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'doc_id': doc_id, 'diff_versions': 1, 'diff_format': diff_format };
        if (!!(compare_version)) {
            params['compare_version'] = compare_version;
        }
        if (!!(use_working_context) && use_working_context) {
            params['use_working_context'] = use_working_context;
        }

        if (!!(version)) {
            params['version'] = version;
        }

        // return this.zappsmithWebService.get(base, params);
        let httpParams = new HttpParams();
        Object.entries(params).forEach(([key, value]) => {
            const stringParams = JSON.stringify(value);
            httpParams = httpParams.append(key, stringParams);
        });

        return new Promise<any>((resolve, reject) => {
            this.http.get(base, { responseType: 'text', params }).subscribe({
                next: (result: any) => {
                    resolve(result);
                },
                error: (result: any) => {
                    reject(result);
                }
            });
        });
    };

    restore_version(base: string, doc_id: string, version: string): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'recovery_doc_id': doc_id, 'recovery_version': version }
        return this.zappsmithWebService.post(base, params);
    };

    deleteItem(base: string, doc_id: string): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'doc_id': doc_id };
        return this.zappsmithWebService.delete(base, params);
    };

    deleteItems(base: string, doc_ids: string[]): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'doc_ids': JSON.stringify(doc_ids) };
        return this.zappsmithWebService.delete(base, params);
    };

    deleteAttachment(base: string, key: string, document: any): Promise<any> {
        const params: ZappsmithBaseParmDict = { 'key': key, 'document': JSON.stringify(document) };
        return this.zappsmithWebService.delete(base + '/attachment', params);
    };

    buildBoard(key: string): Promise<void> {
        if (key) {
            return new Promise<void>((resolve, reject) => {
                const document: any = {
                    "Job": {
                        "ZappBoardLink": { "#value": key },
                        "JobType": { "#value": "BuildBoard", "@id": "BuildBoard", "@name": "Build Board" }
                    }
                };

                this.upsert('/zapp_job', document).then(
                    result => {
                        this.alertService.addAlert({
                            title: 'Job Submitted',
                            message: "Job Submitted",
                            type: AlertType.success
                        });
                        resolve();
                    },
                    result => {
                        this.alertService.addAlert({
                            title: 'Error',
                            message: "Could not add build board",
                            type: AlertType.error
                        });
                        reject();
                    });
            });
        } else {
            this.alertService.addAlert({
                title: "Parent Board",
                message: "No parent board selected.",
                type: AlertType.warning
            });

            return Promise.resolve();
        }
    }
}
<mat-card>
    <mat-card-header>
        <mat-card-title>Node Editor Document Viewer</mat-card-title>

        <mat-card-actions align="end">
            <button mat-button (click)="close()">Close</button>
        </mat-card-actions>
    </mat-card-header>
    <mat-card-content>
        <json-editor [options]="editorOptions" [data]="data"></json-editor>
    </mat-card-content>
</mat-card>
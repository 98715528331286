import { Component } from '@angular/core';

@Component({
    selector: 'zs-menu-manager',
    templateUrl: 'menu-manager.component.html',
    styleUrls: ['menu-manager.component.scss']
})
export class MenuManagerComponent {

}

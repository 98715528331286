import { Injectable } from "@angular/core";

import { ZappsmithWebService, ZappsmithBaseParmDict } from "@dicorp/zappsmith-ngx-core";

@Injectable({
    providedIn: 'root'
})
export class FfeMenuService {

    constructor(private zappsmithWebService: ZappsmithWebService) {
    }

    lookup(name: string,
        partial_string: string,
        path: string,
        match_code: boolean,
        match_label: boolean,
        is_exact: boolean): Promise<any> {

        const ffeMenuParams: ZappsmithBaseParmDict = {
            'name': name,
            'partial_string': partial_string
        };

        if (!!(path) && path != null) {
            ffeMenuParams["path"] = path;
        }
        if (!!(match_code) && match_code != null) {
            ffeMenuParams["match_code"] = match_code;
        }
        if (!!(match_label) && match_label != null) {
            ffeMenuParams["match_label"] = match_label;
        }
        if (!!(is_exact) && is_exact != null) {
            ffeMenuParams["is_exact"] = is_exact;
        }

        return this.zappsmithWebService.get('/ffe_menu', ffeMenuParams); // force refresh
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminModule } from './admin';
import { SystemModule } from './system';
import { PORTAL_MODULE_SERVICES, PortalRoutingServices } from './models';
import { DataExchangeRoutingService, AdminRoutingService, ZappDesignerRoutingService, ReportingRoutingService } from './admin/services';
import { SystemRoutingService } from './system';

const portalModuleServices: PortalRoutingServices = [
    DataExchangeRoutingService,
    ReportingRoutingService,
    ZappDesignerRoutingService,
    AdminRoutingService,
    SystemRoutingService
]

@NgModule({
    imports: [
        CommonModule,
        AdminModule,
        SystemModule
    ],
    exports: [
        AdminModule,
        SystemModule
    ],
    declarations: [],
    providers: [
        {
            provide: PORTAL_MODULE_SERVICES,
            useValue: portalModuleServices
        }
    ]
})
export class PortalModules {
}
import { APP_BASE_HREF } from "@angular/common";
import { Inject, Injectable, Injector, inject } from '@angular/core';
import { ActivatedRoute, Route, Router, Routes } from '@angular/router';
import { EditorMode } from "@dicorp/html-ffe";
import { Subject } from 'rxjs';

import { ZappApp, ZappAppBoard, ZappAppBoardStore, ZappAppStore } from "src/component-store";

import { AppVersionComponent, HomePageComponent, SystemTemplateComponent } from "src/components/app-components";
import { RecordManagerComponent, FfeEditorComponent, TrackingInfoComponent, NeDocumentViewerComponent } from "src/components/hfe-components";
import { PORTAL_MODULE_SERVICES, PortalRoutingServices } from "src/modules";
import { RoutingService, SessionService } from "src/services";

export const HOME_ROUTE_PATH: string = 'Home';

const NE_DOCUMENT_PATH = 'NeDocumentViewer';
const NE_DOCUMENT_OBJECT = '/ne_document';

@Injectable({
    providedIn: 'root'
})
export class AppRoutingService {

    private routesLoaded = new Subject<boolean>();
    routesLoaded$ = this.routesLoaded.asObservable();

    public homeRoute: Route = {
        title: 'Home',
        path: HOME_ROUTE_PATH,
        component: HomePageComponent
    };

    private boardRoutes: Routes;
    private portalPromises: Promise<Route>[] = [];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private injector: Injector,
        private routingService: RoutingService,
        private sessionService: SessionService,
        zappAppStore: ZappAppStore,
        zappAppBoardStore: ZappAppBoardStore,
        @Inject(APP_BASE_HREF) private appBaseHref: string,
        @Inject(PORTAL_MODULE_SERVICES) private portalRoutingServices: PortalRoutingServices) {

        zappAppStore.zappAppsState$.subscribe(zappAppsState => {
            if (zappAppsState?.zappAppsLoaded && zappAppsState?.zappAppBoardsLoaded) {
                this.addBoardRoutes(zappAppBoardStore.getZappAppBoards());
            }
        });

        this.portalRoutingServices?.forEach(portalRoutingServiceType => {
            const portalRoutingService = inject(portalRoutingServiceType);
            this.portalPromises.push(portalRoutingService?.getRouterRoute());
        });

    }

    public canLoadRoute(route: Route): boolean {
        return this.routingService.canLoadRoute(route, this.activatedRoute.snapshot, this.router.routerState.snapshot);
    }

    public openPathInNewWindow(url: string): void {
        const urlFixed = url.startsWith('/') ? url.substring(1, url.length) : url;
        window.open(this.appBaseHref + urlFixed, '_blank');
    }

    private loadRoutes(): void {
        let routes: Routes = [];
        routes.push({ path: '', pathMatch: 'full', redirectTo: HOME_ROUTE_PATH });

        // Support Routes
        this.addSupportRoutes(routes);

        // Home Page
        routes = routes.concat(this.homeRoute);

        // Board Routes
        routes = routes.concat(this.boardRoutes);

        // Benchtop
        // routes.push({
        //     component: BenchTopComponent,
        //     path: 'BenchTop'
        // });

        // // Admin
        // routes.push({
        //     path: 'Admin',
        //     loadChildren: () => import('../modules/admin/admin.module').then(m => m.AdminModule)
        // });

        // // System
        // routes.push({
        //     path: 'System',
        //     loadChildren: () => import('../modules/system/system.module').then(m => m.SystemModule)
        // });

        Promise.all(this.portalPromises).then(portalRoutes => {
            portalRoutes?.forEach(portalRoute => {
                routes.push(portalRoute);
            })

            // Route not found default path
            routes.push({ path: '**', redirectTo: HOME_ROUTE_PATH });

            this.router.resetConfig(routes);
            this.routesLoaded.next(true);
        })
    }

    private addSupportRoutes(routes: Routes): void {
        // App Version Route
        const appVersionRoute: Route = {
            title: 'Version',
            path: 'version',
            component: AppVersionComponent,
            data: { hideRouteOption: true }
        }
        routes.push(appVersionRoute);

        // Support Page Route
        const supportPageRoute: Route = {
            title: 'Support',
            path: 'Support',
            component: SystemTemplateComponent,
            data: {
                templateName: 'Support'
            }
        }
        routes.push(supportPageRoute);
    }

    private addBoardRoutes(zappAppBoards: ZappAppBoard[]): void {
        this.boardRoutes = [];

        for (var zappAppBoard of zappAppBoards) {
            this.boardRoutes = this.boardRoutes.concat(this.createBoardRoutes(zappAppBoard));
        }

        this.loadRoutes();
    }

    private createBoardRoutes(zappAppBoard: ZappAppBoard): Routes {
        const boardRoutes: Routes = [];

        if (zappAppBoard.permissions.canView()) {
            boardRoutes.push({
                component: RecordManagerComponent,
                path: zappAppBoard.recordManagerPath,
                data: { zappAppBoard }
            });

            boardRoutes.push({
                component: FfeEditorComponent,
                path: zappAppBoard.viewRecordPath,
                data: {
                    zappAppBoard,
                    editorMode: EditorMode.View
                }
            });
        }

        if (zappAppBoard.permissions.canEdit()) {
            boardRoutes.push({
                component: FfeEditorComponent,
                path: zappAppBoard.editRecordPath,
                data: {
                    zappAppBoard,
                    editorMode: EditorMode.Edit
                }
            });
        }

        if (zappAppBoard.permissions.canAdd()) {
            boardRoutes.push({
                component: FfeEditorComponent,
                path: zappAppBoard.addRecordPath,
                data: {
                    zappAppBoard,
                    editorMode: EditorMode.Add
                }
            });
        }

        if (this.sessionService.hasPermission('RecMgrViewHistoryAbility')) {
            boardRoutes.push({
                component: TrackingInfoComponent,
                path: zappAppBoard.detailsPath,
                data: {
                    zappAppBoard
                }
            });
        }

        if (zappAppBoard.versionObjectOverride === NE_DOCUMENT_OBJECT) {
            zappAppBoard.versionObjectPath = zappAppBoard.viewRecordPath.replace('/View', '/' + NE_DOCUMENT_PATH);

            boardRoutes.push({
                component: NeDocumentViewerComponent,
                path: zappAppBoard.versionObjectPath
            });
        }

        return boardRoutes;
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServicesModule } from 'src/services';

import { ZappAppBoardStore } from './zapplication/zapp-app-board.store';
import { ZappAppStore } from './zapplication/zapp-app.store';
import { ZappGridViewStateStore } from './zapplication/zapp-grid-view-state.store';

@NgModule({
    imports: [
        CommonModule,
        ServicesModule
    ],
    providers: [
        ZappAppBoardStore,
        ZappAppStore,
        ZappGridViewStateStore
    ]
})
export class ComponentStoreModule {
}
<!-- Ravs Restore -->
<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Ravs Restore</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <!-- AppId / BoardId -->
        <section>
            <mat-form-field appearance="outline">
                <mat-label>AppId / BoardId</mat-label>
                <input matInput [(ngModel)]="appIdAndBoardId" [ngModelOptions]="{standalone: true}" />
            </mat-form-field>
        </section>

        <!-- UUID -->
        <section>
            <mat-form-field appearance="outline">
                <mat-label>UUID</mat-label>
                <input matInput [(ngModel)]="uuid" [ngModelOptions]="{standalone: true}" />
            </mat-form-field>
        </section>

        <!-- Version -->
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Version</mat-label>
                <input matInput [(ngModel)]="version" [ngModelOptions]="{standalone: true}" type="number" />
            </mat-form-field>
        </section>

        <!-- Restore -->
        <section>
            <button mat-flat-button (click)="restore()" [disabled]="!canRestore">Restore</button>
        </section>
    </mat-card-content>
</mat-card>
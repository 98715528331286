import { InjectionToken, Type } from "@angular/core";
import { Route, Routes } from "@angular/router";

export const PORTAL_MODULE_SERVICES = new InjectionToken<Routes>("PORTAL_MODULE_SERVICES");

export abstract class PortalRoutingService {
    abstract getRouterRoute(): Promise<Route>;
    abstract getToolbarRoute(): Promise<Route>;
}

export declare type PortalRoutingServices = Type<PortalRoutingService>[];
<mat-card>
    <mat-card-header>
        <mat-card-title>Ravs Migrate</mat-card-title>

        <mat-card-actions align="end">
            <button mat-button [matMenuTriggerFor]="actionsMenu" class="menu-btn" [disabled]="!canCopyItems">
                Actions
                <mat-icon class="menu-btn-icon">expand_more</mat-icon>
            </button>

            <mat-menu #actionsMenu="matMenu">
                <button mat-menu-item (click)="pullItems()">Pull items from target</button>
                <button mat-menu-item (click)="pushItems()">Push items to target</button>
            </mat-menu>

            <button mat-button (click)="refresh()">Refresh</button>
            <button mat-button (click)="reset()">Reset</button>
        </mat-card-actions>
    </mat-card-header>
    <mat-card-content>
        <!-- Inputs -->
        <section class="inputs-section">
            <mat-form-field appearance="outline" class="mat-form-field-input">
                <mat-label>Target Ravs Url</mat-label>
                <input matInput [(ngModel)]="targetRavsUrl" [ngModelOptions]="{standalone: true}" maxlength="40"
                    size="40" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="mat-form-field-input">
                <mat-label>Target User</mat-label>
                <input matInput [(ngModel)]="targetUser" [ngModelOptions]="{standalone: true}" maxlength="40"
                    size="40" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="mat-form-field-input">
                <mat-label>Target Password</mat-label>
                <input matInput [(ngModel)]="targetPassword" [ngModelOptions]="{standalone: true}" maxlength="40"
                    size="40" type="password" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Ravs Migrate</mat-label>
                <mat-select [(value)]="compareKindSelect" (selectionChange)="compareKindChange()" panelWidth="">
                    <mat-option *ngFor="let compareKind of compareKinds | keyvalue"
                        [value]="compareKind.value">{{compareKind?.value?.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </section>

        <!-- Inputs -->
        <section *ngIf="compareKindSelect == RAW_BOARD">
            <mat-form-field appearance="outline">
                <mat-label>AppId/BoardId</mat-label>
                <input matInput [(ngModel)]="boardName" [ngModelOptions]="{standalone: true}" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Field to Display</mat-label>
                <input matInput [(ngModel)]="boardPath" [ngModelOptions]="{standalone: true}" />
            </mat-form-field>
        </section>

        <!-- Record Manager -->
        <zs-hfe-portal-editor [editorOptions]="editorOptions"
            [hidden]="!editorOptions?.html_ffe_api?.HtmlFFEService?.html_ffe_editor_ready">
        </zs-hfe-portal-editor>

        <!-- Compare HTML -->
        <div class="compare-html" *ngIf="compareHtml" [innerHTML]="compareHtml">
        </div>
    </mat-card-content>
</mat-card>
import { Injectable } from "@angular/core";
import { Dialog } from "@angular/cdk/dialog";
import { ConfirmationDialogComponent, ConfirmationDialogData } from "./confirmation-dialog/confirmation-dialog.component";

@Injectable({
    providedIn: 'root'
})
export class ConfirmationDialogService {

    constructor(private dialog: Dialog) {
    }

    openConfirmationDialog(dialogData: ConfirmationDialogData): Promise<boolean> {
        return new Promise<any>((resolve, reject) => {
            const dialogRef = this.dialog.open<boolean>(ConfirmationDialogComponent, { data: dialogData });

            dialogRef.closed.subscribe(result => {
                resolve(result);
            });
        });
    }
}
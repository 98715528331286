import { Component } from '@angular/core';

@Component({
    selector: 'zs-node-editor-transfer',
    templateUrl: 'node-editor-transfer.component.html',
    styleUrls: ['node-editor-transfer.component.scss']
})
export class NodeEditorTransferComponent {

}

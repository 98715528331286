import { Component } from '@angular/core';
import { AlertService, AlertType, SystemUpdateService } from 'src/services';
import { ConfirmationDialogService } from 'src/components/dialog-components';


@Component({
    selector: 'zs-migrate-collection',
    templateUrl: 'migrate-collection.component.html',
    styleUrls: ['migrate-collection.component.scss']
})
export class MigrateCollectionComponent {
    collection: string = 'trauma';
    updatedWithin: number;

    constructor(private alertService: AlertService,
        private sytemUpdateService: SystemUpdateService,
        private confirmationDialogService: ConfirmationDialogService,) {
    }

    migrateCollection(): void {
        this.confirmationDialogService.openConfirmationDialog(
            {
                title: 'Migrate Collection',
                message: 'Are you sure you want to run migration at this time?'
            }
        ).then(
            result => {
                if (result) {
                    const updatedWithin = this.updatedWithin > 0 ? this.updatedWithin.toString() : null
                    this.sytemUpdateService.migrate_collection(this.collection, updatedWithin).then(
                        result => {
                            this.alertService.addAlert({
                                title: 'Migrating Collection',
                                message: "Running Migrate",
                                type: AlertType.success
                            });
                        },
                        result => {
                            // Migrate call is returns terrible results for some reason
                            if (result?.status === 200) {
                                this.alertService.addAlert({
                                    title: 'Migrating Collection',
                                    message: "Running Migrate",
                                    type: AlertType.success
                                });
                            } else {
                                this.alertService.addAlert({
                                    title: 'Error',
                                    message: "Migrating Collection failed.",
                                    type: AlertType.error
                                });
                            }
                        }
                    );
                }
            });
    }

}

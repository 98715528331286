import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'zs-column-def-manager',
    templateUrl: 'column-def-manager.component.html',
    styleUrls: ['column-def-manager.component.scss']
})
export class ColumnDefManagerComponent implements AfterViewInit {
    dataSource: MatTableDataSource<ColumnDefRow>;
    displayedColumns: string[] = ['name', 'description'];
    tableData: ColumnDefRow[];

    @ViewChild(MatSort) sort: MatSort;

    get selectedColumns(): string[] {
        return this.tableData.filter(row => {
            return row.columnSelectionRow?.selected;
        }).map(row => {
            return row.columnSelectionRow?.columnDef?.heading;
        });
    }

    constructor(public dialogRef: DialogRef<string[] | false>,
        @Inject(DIALOG_DATA) public data: ColumnDefManagerData) {
        this.setupDataTable();
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    cancel(): void {
        this.dialogRef.close(false);
    }

    saveSelectedColumns(): void {
        this.dialogRef.close(this.selectedColumns);
    }

    isGroup(index: number, row: ColumnDefRow): boolean {
        return row.rowGroup !== undefined;
    }

    groupHeaderClick(row: ColumnDefRow): void {
        row.rowGroup.expanded = !row.rowGroup.expanded;
    }

    private setupDataTable(): void {
        const columnSelectionsRows: ColumnSelectionRow[] = this.data?.columnDefs.map(columnDef => {
            const selected = this.data?.selectedColumns ?
                this.data?.selectedColumns?.indexOf(columnDef?.heading) >= 0 :
                columnDef.active;

            const columnSelection: ColumnSelectionRow = {
                columnDef,
                selected
            }

            return columnSelection;
        });

        const rowGroupNames: string[] = [];
        const rowGroups: RowGroup[] = [];
        columnSelectionsRows?.forEach(columnSelectionsRow => {
            const rowGroupIndex = rowGroupNames.indexOf(columnSelectionsRow.columnDef?.group);
            if (rowGroupIndex < 0) {
                rowGroupNames.push(columnSelectionsRow.columnDef?.group);
                const rowGroup = new RowGroup(columnSelectionsRow.columnDef?.group, rowGroupNames.length + 1);
                rowGroup.columnSelectionRows.push(columnSelectionsRow);
                rowGroups.push(rowGroup);
            } else {
                rowGroups[rowGroupIndex].columnSelectionRows.push(columnSelectionsRow);
            }
        });

        this.tableData = [];
        rowGroups.forEach(rowGroup => {
            this.tableData.push({ rowGroup: rowGroup });
            rowGroup.columnSelectionRows.forEach(columnSelectionRow => {
                this.tableData.push({ parentRowGroup: rowGroup, columnSelectionRow: columnSelectionRow });
            })
        });

        this.dataSource = new MatTableDataSource(this.tableData);
        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (row, property) => {
            return row.columnSelectionRow?.columnDef[property];
        };

        this.dataSource.sortData = (data: ColumnDefRow[], sort: MatSort) => {
            return data.sort((a: ColumnDefRow, b: ColumnDefRow) => {
                let aRowIndex = a.rowGroup?.index ? a.rowGroup.index : a.parentRowGroup.index;
                let bRowIndex = a.rowGroup?.index ? a.rowGroup.index : a.parentRowGroup.index;

                if (sort.direction === '') {
                    return 1;
                }

                if (a.rowGroup) {
                    return sort.direction === 'asc' ? aRowIndex - bRowIndex : aRowIndex - bRowIndex;
                } else if (a.columnSelectionRow && b.columnSelectionRow) {
                    if (a.parentRowGroup?.index !== b.parentRowGroup?.index) {
                        return a.parentRowGroup?.index - b.parentRowGroup?.index
                    } else {
                        const active = sort.active;
                        const valueA = this.dataSource.sortingDataAccessor(a, active);
                        const valueB = this.dataSource.sortingDataAccessor(b, active);
                        const compare = valueA?.toLocaleString()?.localeCompare(valueB?.toLocaleString());
                        return sort.direction === 'asc' ? compare : -compare;
                    }
                } else {
                    return 0;
                }
            });
        }
    }
}

export interface ColumnDefManagerData {
    columnDefs: any[];
    selectedColumns: string[];
}

export interface ColumnSelectionRow {
    columnDef: any;
    selected: boolean;
}

export class RowGroup {
    expanded: boolean = true;
    columnSelectionRows: ColumnSelectionRow[] = [];

    constructor(public name: string, public index: number) { }
}

export interface ColumnDefRow {
    rowGroup?: RowGroup;

    parentRowGroup?: RowGroup;
    columnSelectionRow?: ColumnSelectionRow;
}

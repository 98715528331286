<mat-card>
    <mat-card-header>
        <mat-card-title>Activity Tracking</mat-card-title>

        <!-- Record Manager Buttons -->
        <mat-card-actions align="start">
            <mat-form-field class="report-panel" subscriptSizing="dynamic" appearance="outline">
                <mat-label>Select report to run</mat-label>
                <mat-select [formControl]="reportControl">
                    @for (report of activityTrackingReports; track report) {
                    <mat-option [value]="report">{{report.label}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </mat-card-actions>

        <!-- Record Manager Buttons -->
        <mat-card-actions align="end">
            <zs-hfe-grid-actions [editorOptions]="editorOptions"
                [gridField]="activityTrackingGridField"></zs-hfe-grid-actions>
        </mat-card-actions>
    </mat-card-header>
    <mat-card-content>
        <zs-hfe-portal-editor *ngIf="reportControl.value" [editorOptions]="editorOptions"></zs-hfe-portal-editor>
    </mat-card-content>
</mat-card>
import { Component, Inject } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router } from '@angular/router';

import { ZappState, AppAuthService, ClientConfigurationStore } from '@dicorp/zappsmith-ngx-auth';

import { APP_VERSION, AppRoutingService } from 'src/app';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'Zappsmith Portal 4';
    public applicationModulesLoaded: boolean;

    private zappState: ZappState;

    constructor(
        private router: Router,
        private location: Location,
        appAuthService: AppAuthService,
        appRoutingService: AppRoutingService,
        clientConfigurationStore: ClientConfigurationStore,
        @Inject(DOCUMENT) private _document: Document) {

        // Set the application fav icon href
        clientConfigurationStore.activeClientConfiguration$.subscribe(activeClientConfiguration => {
            if (activeClientConfiguration?.favicon) {
                const favIconElements = this._document.getElementsByName('appFavIcon');
                favIconElements?.forEach(favIconElement => {
                    favIconElement?.setAttribute('href', activeClientConfiguration?.favicon);
                });
            }
        });

        appAuthService.authService?.appState$?.subscribe(appState => {
            this.zappState = appState;
        });

        appRoutingService.routesLoaded$.subscribe(routesLoaded => {
            if (routesLoaded) {
                if (this.zappState) {
                    this.router.navigateByUrl(this.zappState.pathUrl);
                } else if ((this.router as any).navigationId > 0) {
                    this.router.navigate(['']);
                } else {
                    this.router.initialNavigation();
                    // const path = this.location.path(true);
                    // this.router.navigate([path])
                }
            }
        });
    }
}

@Component({
    template: '<div style="padding: 20px">Current Version: {{appVersion}}</div>'
})
export class AppVersionComponent {
    constructor(@Inject(APP_VERSION) public appVersion: string) {
    }
}

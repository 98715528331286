<mat-card class="confirmation-dialog-card nvt-surface border-base">
    <mat-card-header *ngIf="dialogData?.title">
        <mat-card-title> {{ dialogData?.title }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-card-content>
            <div [innerHtml]="dialogData?.body | sanitizeHtml"></div>
        </mat-card-content>
    </mat-card-content>
    <mat-card-actions align="end">
        <button class="theme-button border-button border-base transition-base" *ngFor="let alert of dialogData?.alerts"
            (click)="dialogRef.close(alert.module)">
            {{alert.label}}
        </button>
    </mat-card-actions>
</mat-card>
import * as i0 from '@angular/core';
import { Injectable, Inject, APP_INITIALIZER, NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import * as i1 from '@angular/common/http';
import { HttpHeaders, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import * as i2 from '@auth0/auth0-angular';
import { AuthService, AuthModule } from '@auth0/auth0-angular';
import { ComponentStore } from '@ngrx/component-store';
const API_PATH = '/api/';
const CLIENT_CONFIGURATION_PATH = 'client_configuration';
class ClientConfiguration {
  rawClientConfiguration;
  auth0_clientId;
  auth0_domain;
  auth0_audience;
  portal_app_title;
  logo;
  favicon;
  defaultSurveyReports;
  constructor(rawClientConfiguration) {
    this.rawClientConfiguration = rawClientConfiguration;
    this.auth0_clientId = rawClientConfiguration.auth0_clientId;
    this.auth0_domain = rawClientConfiguration.auth0_domain;
    this.auth0_audience = rawClientConfiguration.auth0_audience;
    this.portal_app_title = rawClientConfiguration.portal_app_title;
    this.logo = rawClientConfiguration.logo;
    this.favicon = rawClientConfiguration.favicon;
    if (rawClientConfiguration.default_communicator_survey_reports) {
      try {
        this.defaultSurveyReports = JSON.parse(rawClientConfiguration.default_communicator_survey_reports);
      } catch {
        console.error('Invalid value in: "ClientConfiguration.default_communicator_survey_reports" for this client.');
        this.defaultSurveyReports = [];
      }
    } else {
      console.error('"ClientConfiguration.default_communicator_survey_reports" have not been setup for this client.');
      this.defaultSurveyReports = [];
    }
  }
}
class ClientConfigurationStore extends ComponentStore {
  http;
  activeClientConfiguration$ = this.select(state => state.activeClientConfiguration);
  errorState$ = this.select(state => state.errorState);
  constructor(http) {
    super();
    this.http = http;
    this.getClientConfiguration().then(result => {
      const clientConfiguration = new ClientConfiguration(result);
      this.setState({
        activeClientConfiguration: clientConfiguration
      });
    }, () => {
      this.setState({
        errorState: 'Failed to retreive client_configuration'
      });
    });
  }
  getClientConfiguration() {
    const clientConfigurationPath = API_PATH + CLIENT_CONFIGURATION_PATH;
    return new Promise((resolve, reject) => {
      this.http.get(clientConfigurationPath).subscribe({
        next: result => {
          result ? resolve(result) : reject(result);
        },
        error: result => {
          reject(result);
        }
      });
    });
  }
  static ɵfac = function ClientConfigurationStore_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ClientConfigurationStore)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ClientConfigurationStore,
    factory: ClientConfigurationStore.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClientConfigurationStore, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
const HEARBEAT_INTERVAL = 10000;
class AppAuthService extends ComponentStore {
  injector;
  http;
  authClientConfig;
  clientConfigurationStore;
  appBaseHref;
  activeUser$ = this.select(state => state.activeUser);
  activeCommunityId$ = this.select(state => state.activeCommunityId);
  redirectUri;
  heartbeatTimer;
  heartbeatFailCount = 0;
  authService;
  zappState;
  get isLoggedIn() {
    return this.authService?.isAuthenticated$;
  }
  constructor(injector, http, authClientConfig, clientConfigurationStore, appBaseHref) {
    super();
    this.injector = injector;
    this.http = http;
    this.authClientConfig = authClientConfig;
    this.clientConfigurationStore = clientConfigurationStore;
    this.appBaseHref = appBaseHref;
    this.redirectUri = window.location.origin + appBaseHref;
    this.setState({});
  }
  load() {
    return new Promise((resolve, reject) => {
      this.clientConfigurationStore.activeClientConfiguration$.subscribe(activeClientConfiguration => {
        // Setup auth0
        const authConfig = {
          clientId: activeClientConfiguration.auth0_clientId,
          domain: activeClientConfiguration.auth0_domain,
          authorizationParams: {
            audience: activeClientConfiguration.auth0_audience,
            redirect_uri: this.redirectUri
          }
        };
        this.authClientConfig.set(authConfig);
        this.authService = this.injector.get(AuthService);
        this.authService.appState$.subscribe(zappState => {
          this.zappState = zappState;
        });
        this.verifyLoggedIn().then(activeCommunityId => {
          resolve(activeCommunityId);
        }, () => {
          this.handleError('Failed to associate tokens');
          reject();
        });
      });
      this.clientConfigurationStore.errorState$.subscribe(errorState => {
        if (errorState) {
          this.handleError(errorState);
          reject();
        }
      });
    });
  }
  logout(redirectUri) {
    this.authService.logout({
      logoutParams: {
        returnTo: redirectUri ? redirectUri : this.redirectUri
      }
    });
  }
  verifyLoggedIn() {
    return new Promise((resolve, reject) => {
      this.isLoggedIn?.subscribe({
        next: isLoggedIn => {
          if (isLoggedIn) {
            this.associateTokens().then(activeCommunityId => {
              resolve(activeCommunityId);
            }, () => {
              this.handleError('Failed to associate tokens');
              reject();
            });
          } else {
            const pathname = window.location.pathname;
            const path = pathname.replace(this.appBaseHref, '');
            const zappState = {
              pathUrl: path + window.location.search
            };
            this.authService?.loginWithRedirect({
              appState: zappState
            });
          }
        }
      });
    });
  }
  associateTokens() {
    return new Promise((resolve, reject) => {
      this.authService?.getAccessTokenSilently().subscribe(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          })
        };
        this.http.post('/api/token_login', {}, httpOptions).subscribe({
          next: response => {
            const activeUser = response;
            const activeCommunityId = activeUser?.facility?.id;
            this.setState({
              activeUser: response,
              activeCommunityId
            });
            this.startHearbeatMonitor();
            resolve(activeCommunityId);
          },
          error: () => {
            this.handleError('Invalid account information or your session may have timed out. Please retry.');
            reject();
          }
        });
      });
    });
  }
  handleError(error) {
    alert(error);
    window.location.href = window.location.origin;
  }
  startHearbeatMonitor() {
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
    }
    this.heartbeatFailCount = 0;
    this.heartbeatTimer = setInterval(() => {
      this.http.post('/heartbeat/is_heartbeat_expired', {}).subscribe({
        next: response => {
          if (response !== 'success') {
            this.heartbeatFailCount++;
            if (this.heartbeatFailCount > 1) {
              clearInterval(this.heartbeatTimer);
              alert("The session has been terminated");
              this.logout();
            }
          } else {
            this.heartbeatFailCount = 0;
          }
        }
      });
    }, HEARBEAT_INTERVAL);
  }
  static ɵfac = function AppAuthService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AppAuthService)(i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthClientConfig), i0.ɵɵinject(ClientConfigurationStore), i0.ɵɵinject(APP_BASE_HREF));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AppAuthService,
    factory: AppAuthService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppAuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.Injector
  }, {
    type: i1.HttpClient
  }, {
    type: i2.AuthClientConfig
  }, {
    type: ClientConfigurationStore
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [APP_BASE_HREF]
    }]
  }], null);
})();
class ZappsmithNgxAuthModule {
  static ɵfac = function ZappsmithNgxAuthModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ZappsmithNgxAuthModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ZappsmithNgxAuthModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [{
      provide: APP_INITIALIZER,
      useFactory: appConfigInitialize,
      deps: [AppAuthService],
      multi: true
    }, provideHttpClient(withInterceptorsFromDi())],
    imports: [AuthModule.forRoot()]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ZappsmithNgxAuthModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      exports: [],
      imports: [AuthModule.forRoot()],
      providers: [{
        provide: APP_INITIALIZER,
        useFactory: appConfigInitialize,
        deps: [AppAuthService],
        multi: true
      }, provideHttpClient(withInterceptorsFromDi())]
    }]
  }], null, null);
})();
function appConfigInitialize(appAuthService) {
  return () => {
    return new Promise((resolve, reject) => {
      appAuthService.load().then(activeCommunityId => {
        resolve();
      }, reject);
    });
  };
}

/*
 * Public API Surface of zappsmith-ngx-auth
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AppAuthService, ClientConfiguration, ClientConfigurationStore, ZappsmithNgxAuthModule, appConfigInitialize };

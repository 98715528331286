import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'src/3rd-party';
import { ServicesModule } from 'src/services';

import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog-service';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ServicesModule
    ],
    declarations: [
        ConfirmationDialogComponent
    ],
    exports: [
        ConfirmationDialogComponent
    ],
    providers: [
        ConfirmationDialogService
    ]
})
export class DialogComponentsModule {
}
<mat-card class="nvt-surface border-base">
    <!-- <mat-card-header>
        <mat-card-title>Select Columns</mat-card-title>
    </mat-card-header> -->

    <mat-card-content>

        <table mat-table matSort [dataSource]="dataSource">

            <!-- Group Column -->
            <ng-container matColumnDef="groupColumn">
                <td mat-cell *matCellDef="let row" colspan="999" style="cursor: pointer;">
                    <mat-icon *ngIf="row.rowGroup?.expanded">expand_less</mat-icon>
                    <mat-icon *ngIf="!row.rowGroup?.expanded">expand_more</mat-icon>
                    <strong style="vertical-align: top; margin-left: 5px;">{{row?.rowGroup?.name}}</strong>
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let row" style="min-width: 300px;">
                    <mat-checkbox style="margin-left: 15px;" [(ngModel)]="row.columnSelectionRow.selected">
                        {{row.columnSelectionRow?.columnDef?.name}}
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                <td mat-cell *matCellDef="let row" style="min-width: 500px;">
                    {{row?.columnSelectionRow?.columnDef?.description}}
                </td>
            </ng-container>

            <!-- Header Row -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

            <!-- Group Row -->
            <tr mat-row *matRowDef="let row; columns: ['groupColumn']; when: isGroup" (click)="groupHeaderClick(row)">
            </tr>

            <!-- Data Rows -->
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [hidden]="!row.parentRowGroup?.expanded"></tr>

        </table>

    </mat-card-content>

    <mat-card-actions align="end">

        <button class="theme-button
                        primary-button
                        border-base
                        interactive-base
                        transition-base 
                        nvt-colorbg-pb" [disabled]="selectedColumns?.length <= 0" (click)="saveSelectedColumns()">
            Save
        </button>

        <button class="theme-button
                        border-button
                        border-base
                        transition-base" (click)="cancel()">
            Cancel
        </button>

    </mat-card-actions>
</mat-card>
<mat-card class="nvt-surface border-base">

    <mat-card-header>
        <mat-card-title> {{ data._id ? 'Update View' : 'Add New View'}}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <form>
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <input matInput [(ngModel)]="data.name" [ngModelOptions]="{standalone: true}" tabindex="0"
                    placeholder="View Name" (keyup.enter)="save()" />
            </mat-form-field>

            <mat-checkbox [(ngModel)]="data.is_default" [ngModelOptions]="{standalone: true}">
                Is Default
            </mat-checkbox>

            <mat-checkbox *ngIf="publicViewSaveAllow" [(ngModel)]="data.is_public"
                [ngModelOptions]="{standalone: true}">
                Is Public
            </mat-checkbox>
        </form>
    </mat-card-content>

    <mat-card-actions align="end">

        <button class="theme-button
                        primary-button
                        border-base
                        interactive-base
                        transition-base 
                        nvt-colorbg-pb" [disabled]="!data?.name" (click)="save()">
            Save
        </button>

        <button class="theme-button
                        border-button
                        border-base
                        transition-base" (click)="dialogRef.close(false)">
            Cancel
        </button>

    </mat-card-actions>
</mat-card>
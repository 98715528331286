import { ThemePalette } from "@angular/material/core";

export interface AlertData {
    title?: string;
    message?: string;
    type?: AlertType;
    color?: ThemePalette;

    require_confirm?: boolean;
    duration?: number;
}

export enum AlertType {
    success,
    warning,
    error
}

export interface AlertDialogData {
    message: string;

    title?: string;
    type?: AlertDialogType;
}

export enum AlertDialogType {
    ok,
    okCancel
}

export interface FfeAlert {
    label: string;
    module: string;
}

export interface FfeAlertDialogData {
    title: string;
    body: string;
    alerts: FfeAlert[];
}
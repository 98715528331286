import { DialogRef, DIALOG_DATA } from "@angular/cdk/dialog";
import { Component, Inject } from "@angular/core";

@Component({
    templateUrl: 'confirmation-dialog.component.html',
    styleUrls: ['confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
    constructor(
        public dialogRef: DialogRef<boolean>,
        @Inject(DIALOG_DATA) public dialogData: ConfirmationDialogData) {
    }
}

export interface ConfirmationDialogData {
    title?: string;
    message: string;
}
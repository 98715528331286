import { IHtmlFFEApi } from '@dicorp/html-ffe';
import moment from 'moment';

// interface IRuleBindingMap {
//      [details: string]: RuleBinding
// }

export class RuleBindingMap { //  implements IRuleBindingMap
    [index: string]: any;

    constructor() {
    }

    getBinding(key: string, defaultValue: any = null) {
        return this.hasOwnProperty(key) ? this[key] : defaultValue
    };

    getValue(key: string, defaultValue: any = null) {
        return this.hasOwnProperty(key) ? this[key].getValue() : defaultValue
    };

    iterateKeys(callbackFn: (key: string) => void) {
        return Object.keys(this).forEach(function (key) {
            if (typeof (key) != 'function') {
                callbackFn(key);
            }
        })
    }
}


// interface IClientModuleRuleDict {
//     [details: string]: (bindings: RuleBindingMap) => any
// }

export type BindingRule = (bindings: RuleBindingMap) => any;

//type BindingRuleOptionalParams = (bindings: RuleBindingMap, ...any) => any;

export interface BindingDefinition {
    name: string;
    description?: string;
    defaultValue?: string;
    required?: boolean;
}

export interface RuleDefinition {
    docs: {
        definition?: string;
        details?: string;
        bindings?: BindingDefinition[];
    },
    testInfo?: any;
    rule: BindingRule;
}

export interface IClientModuleRuleDict {
    //[details: string]: RuleDefinition | BindingRule; // | BindingRuleOptionalParams;
    [details: string]: any | RuleDefinition | BindingRule; // | BindingRuleOptionalParams;
}

//var clientModuleRules: any = {};

const INT_TEXT_TAG = '#value';
const INT_BIU_TAG = '@biu';

// http://stackoverflow.com/questions/8817394/javascript-get-deep-value-from-object-by-passing-path-to-it-as-string
// reimplement from grid_common for now to allow localized calls without dependencies in test cases
const local_deep_value = function (obj: any, path: string): any | string {
    const spath = path.split('.');
    let i = 0;
    const len = spath.length;
    for (; i < len; i++) {
        if ((typeof obj === 'undefined') || obj === null) {
            obj = null;
            break;
        }
        obj = obj[spath[i]];
    }
    return obj;
};

const isObjEmpty = function (obj: any): boolean {
    if (typeof obj !== 'object') {
        return obj === null; // if its null return true, otherwise return false
    }
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            return false;
        }
    }
    return true;
};

export interface IRawBinding {
    lookup_value: string | object;
    raw_value: string;
    type: string;
    is_skipped?: boolean; // used in test cases as the default skipped
    is_record_view_only?: boolean; // used in test cases as the default is record view only
}

export interface IRawBindingMap {
    [details: string]: IRawBinding;
}

export interface IFfeOptions {
    api: IHtmlFFEApi;
}

export interface ICdict {
    error: any;
    value: any;
    changes: { [key: string]: any };
    message: any | any[];
    paths: string[];
    rule: any;
    record: any;
    alert?: any;
    ffe_options: IFfeOptions;
    context?: any;
}


export type IIterateValueCallback = (key: string, value: any) => boolean;

/*

interface IIterateValueCallback {
    (key: string, value: any): boolean
}
*/

function copySimpleObj(o: any) {
    if (o) {
        o = JSON.parse(JSON.stringify(o))
    }
    return o;
}

export class RuleBinding {
    raw: IRawBinding;
    lookup_value: any;
    raw_value: string;
    rule_kind: string;
    is_field: any;
    binding_type: string = "Field"
    cdict: ICdict;
    private value: any;
    is_complex: boolean;
    biu_value: number | null;
    is_biu: boolean;
    is_skipped: boolean;
    working_value: any;
    is_blank: boolean;
    is_populated: any;
    is_existing_record: boolean;
    is_record_view_only: boolean;

    constructor(s: any, cdict: ICdict) {
        if (s && (typeof s.lookup_value) === 'undefined') {
            s.lookup_value = null;
        }
        this.raw = s;
        this.lookup_value = (s && s.lookup_value) ? copySimpleObj(s.lookup_value) : null; // force a copy that they can work with
        //this.lookup_value = (s && (typeof s.lookup_value !== 'undefined') )?s.lookup_value:null;//  ? s.lookup_value : null;
        this.binding_type = (s ? s.type : "String").toLowerCase();
        this.is_field = this.binding_type == "field";
        this.raw_value = s ? this.valueAsType(s.raw_value) : null;

        this.cdict = cdict;
        this.rule_kind = (this.cdict && this.cdict.rule && this.cdict.rule.kind) ? this.cdict.rule.kind : "RUL_Eval";
        this.value = this.is_field ? this.lookup_value : this.raw_value;
        // is it a field and make sure it doesn't have the biu/value parts
        this.is_complex = this.is_field && this.lookup_value && !isObjEmpty(this.lookup_value) && !(this.lookup_value.hasOwnProperty(INT_BIU_TAG) || this.lookup_value.hasOwnProperty(INT_TEXT_TAG));
        // if (this.value.hasOwnProperty(INT_TEXT_TAG))
        // {
        //     this.value = this.value[INT_TEXT_TAG];
        // }
        this.resetParts();
    }

    valueAsType(v: any): any {
        if (this.binding_type == 'integer') {
            return parseInt(v);
        }
        if (this.binding_type == 'float') {
            return parseFloat(v);
        }
        if (this.binding_type == 'json') {
            try {
                let o = JSON.parse(v.replace(/__DOT__/g, '.'));
                if (o && typeof o === "object") {
                    return o;
                }
            }
            catch (e) {

            }
        }

        if (this.binding_type == 'boolean') {
            return (v === 1 || v === "True" || v === "true" || v === true);
        }
        return v;

    }

    resetParts() {
        this.biu_value = (this.lookup_value && this.lookup_value[INT_BIU_TAG]) ? this.lookup_value[INT_BIU_TAG] : null;
        this.is_biu = this.biu_value !== null;

        this.is_skipped = (this.cdict && this.cdict.ffe_options && this.cdict.ffe_options.api && this.cdict.ffe_options.api.is_field_skipped(this.raw_value)) ? true : (this.raw && this.raw.is_skipped == true);
        this.is_existing_record = this.cdict && this.cdict.record && this.cdict.record.hasOwnProperty('_id') && this.cdict.record['_id'] != '-1';
        this.is_record_view_only = this.cdict && this.cdict.context && this.cdict.context.model && this.cdict.context.model.mode == 'view' ? true : (this.raw && this.raw.is_record_view_only == true);
        this.working_value = this.getValue();
        this.is_blank = isObjEmpty(this.getValue()) || (!this.is_complex && (typeof this.working_value === 'undefined' || this.working_value === null || ((typeof this.working_value.length !== 'undefined') && this.working_value.length == 0)));
        this.is_populated = this.is_skipped || this.is_biu || (!this.is_blank);
    }

    getSetting(name: string = null) {
        let result = null;
        if (!name) {
            name = this.getValue();
        }
        // If name is passed use that for setting , otherwise use the value in the binding
        if (name.startsWith('{')) {
            result = JSON.parse(name);
        } else if (this.cdict && this.cdict.context) {
            result = this.cdict.context.get_setting(name)
            if (result.hasOwnProperty('document')) {
                result = result.document;
            }
        }
        return result;
    }

    getConfigurationItem(name: string = null, defaultValue: any = null) {
        let result = null;
        let value: any = name;
        if (!value) {
            value = this.getValue();
        }
        //console.log("Test logging with" + JSON.stringify(name));
        // used for testing
        if (value.hasOwnProperty("configuration_value")) {
            result = value.configuration_value;

        } else if (this.cdict && this.cdict.context) {
            result = this.cdict.context.getConfigurationItem(value, defaultValue);
        }

        // // If name is passed use that for configuration , otherwise use the value in the binding
        // if (name.startsWith('{')) { // for testing we will allow {'#value':"whatever"}
        //     result =  JSON.parse(name);
        //     result = result[INT_TEXT_TAG];
        //
        // }
        // else if (this.cdict && this.cdict.context) {
        //     result = this.cdict.context.getConfigurationItem(name, defaultValue);
        // }
        return result;
    }

    getValue() {
        return (this.value && this.value.hasOwnProperty(INT_TEXT_TAG)) ? this.value[INT_TEXT_TAG] : this.value;
        //return v;
    };


    getBiuValue() {
        return this.biu_value;
        //return v;
    };


    asFloat() {
        //var v = this.getValue();
        if (this.is_blank) {// !v && v !=0 ) {
            return null;
        } else {
            return parseFloat(this.working_value);
        }
    };

    asMoment() {
        const v = this.working_value;
        if (this.is_blank) {
            return null;
        } else {
            //var use_datetime = v && (v.length == 8 || (v.length == 8 || v.slice(8, 15) == "000000"));
            return (v instanceof Date) ? moment(v) : moment(v, ["YYYYMMDD", "YYYYMMDDHHMMSS", moment.ISO_8601]);
        }
    };

    setValue(value: any, use_raw: boolean = false, allow_invalid: boolean = false) {
        if (this.is_field) {
            const orig_value = this.raw.lookup_value;
            const field = this.raw_value;
            if (!use_raw) {
                if ((value !== null) && (typeof value !== 'undefined') && !value.hasOwnProperty(INT_TEXT_TAG) && !value.hasOwnProperty(INT_BIU_TAG)) {
                    const v: any = {};
                    v[INT_TEXT_TAG] = value;
                    value = v;
                }
            }
            //const isObject = (value !== null && typeof value === 'object'  && value.constructor === Object);
            const new_value_str = value ? JSON.stringify(value) : null;
            const orig_value_str = orig_value ? JSON.stringify(orig_value) : null;

            if (orig_value_str != new_value_str) { // skip if the same
                const new_value = new_value_str ? JSON.parse(new_value_str) : new_value_str; // force a copy of the value

                if (new_value === null) {
                    this.cdict.changes[field] = { 'operation': 'remove' };
                } else {
                    const new_c: any = {
                        'value': new_value,
                        'operation': orig_value !== null ? 'update' : 'add',

                    };
                    if (allow_invalid) {
                        new_c['allow_invalid'] = allow_invalid
                    }
                    this.cdict.changes[field] = new_c;
                }
                this.value = new_value;
                this.lookup_value = new_value;
                this.raw.lookup_value = new_value; // really shouldn't be changing the raw but ...
                this.resetParts();
            } else {
                //console.log("Skipping " + orig_value_str + " vs " + new_value_str);
            }
        }
    };

    getValueRaw(defaultValue: any = null) {
        return (this.value !== null) ? this.value : defaultValue;
    };

    setValueRaw(value: any, allow_invalid: boolean = false) {
        this.setValue(value, true, allow_invalid);
    };

    setError(message: string) {
        this.setMessage(message);
        this.setResultValue(false);
    }

    setMessage(message: any) {
        this.cdict.message = message;
    };

    setAlert(alert: any, alertMessage: any) {
        this.cdict.alert = alert;
        this.cdict.message = alertMessage;
    };

    getResultValue() {
        return this.cdict.value;
    };

    setResultValue(value: any, appendPathIfCheck: boolean = true) {
        // adding the path here
        if ((this.rule_kind == "RUL_Check" || this.rule_kind == "RUL_Repeater_Check") && value && appendPathIfCheck) {
            //We might include all paths in the future
            this.appendPath(this.raw_value)
        }
        this.cdict.value = value;
    };


    setCheckResult(value: any) {
        if (value) {
            //We might include all paths in the future
            this.appendPath(this.raw_value)
        }
        this.cdict.value = value;
    };

    appendPath(newPath: string, clearExisting: boolean = true) {
        if (!this.cdict.paths || clearExisting) {
            this.cdict.paths = [];
        }
        this.cdict.paths.push(newPath);
    };


    setBiu(biu_value: number | string) {
        const value: any = {};
        value[INT_BIU_TAG] = biu_value.toString();
        return this.setValue(value);
    };

    setBIU(biu_value: number | string) {
        console.log("setBIU is deprecated, please use setBiu instead");
        return this.setBiu(biu_value);
    };

    iterateValue(cb: IIterateValueCallback): number {
        // cb expects a position and a value, returns True to continue
        // function returns total iterations run
        if (this.is_complex) {
            let count = 0;
            for (let key in this.value) {
                count++;
                if (this.value.hasOwnProperty(key)) {
                    if (!cb(key, this.value[key])) {
                        break;
                    }
                }
            }
            return count;
        }
        return 0;
    };

    listLength() {
        return this.iterateValue(function (k, v) {
            return true
        });
    };

    partValueToBinding(rel_path: any) {
        let new_b: IRawBinding = {
            //name: v.name,
            raw_value: this.raw_value + '.' + rel_path,
            type: 'Field',
            lookup_value: local_deep_value(this.lookup_value, rel_path),
            is_skipped: this.is_skipped
        };
        return new RuleBinding(new_b, this.cdict);
    };

    createNewBinding(raw_value: string, bindingType: string = "Field"): RuleBinding {
        const new_b: IRawBinding = {
            //name: v.name,
            raw_value: raw_value,
            type: bindingType,
            lookup_value: bindingType === "Field" ? local_deep_value(this.cdict.record, raw_value) : raw_value,
            is_skipped: this.is_skipped
        };
        return new RuleBinding(new_b, this.cdict);
    }

    getRuleExpressions(): object[] | null {
        const exprs = this.cdict.rule.RUL_GroupExpression;
        return !!exprs ? exprs : null;
    }


}

import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { FfeAlertDialogData } from 'src/services';

@Component({
    selector: 'ffe-alert-dialog',
    templateUrl: 'ffe-alert-dialog.component.html',
    styleUrls: ['ffe-alert-dialog.component.scss']
})
export class FfeAlertDialogComponent {
    constructor(
        public dialogRef: DialogRef<any>,
        @Inject(DIALOG_DATA) public dialogData: FfeAlertDialogData) {
    }
}
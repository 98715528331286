import { Component } from '@angular/core';
import { ConfigurationService } from 'src/services';

@Component({
    selector: 'zs-home-page',
    templateUrl: 'home-page.component.html',
    styleUrls: ['home-page.component.scss']
})
export class HomePageComponent {

    constructor(public configurationService: ConfigurationService) {
    }
}
